import React from 'react'
import Finance from '../../assets/Images/finance.jpg'
import Entertainment from '../../assets/Images/entertainment2.jpg'
import Manufacturing from '../../assets/Images/manufacturing.jpg'
import ProductComponent from '../ProductComponent'
import Manufacturing2 from '../../assets/Images/manufacturing2.jpg'
import Entertainment2 from '../../assets/Images/entertainment2.jpg'




const ProductSection = () => {
  return (
    <div>
        <div className="mb-12 text-center">
    <h3 className="text-3xl font-bold uppercase text-slate-800 mb-4">
      Explore More with Essential
    </h3>
    <p className="text-lg text-slate-600">
      Promotions, deals, and special offers just for you
    </p>
  </div>
  <div className="flex flex-wrap items-center gap-6 justify-center">
    <ProductComponent
      productImage={Manufacturing2}
      productTitle="Everify"
    />
    <ProductComponent
      productImage={Entertainment}
      productTitle="e-Student"
    />
    <ProductComponent
      productImage={Manufacturing}
      productTitle="e-Jobs"
    />
    <ProductComponent
      productImage={Entertainment2}
      productTitle="e-Schools"
    />
    <ProductComponent
      productImage={Finance}
      productTitle="Oosh Business"
    />
  </div>
    </div>
  )
}

export default ProductSection