import { useLocation, useNavigate } from "react-router-dom";
import { useStore } from "./useContext"

const useLogout = () =>{
    const store = useStore()
    const setUserInfo = store?.setUserInfo;
    const navigate = useNavigate();
    const location = useLocation();
    const fullpath = location.pathname + location.search;
    const userRole = location.pathname?.split('/')?.[1]
    const loginBase = userRole === 'admin' ? '/admin-login' : '/login'

    const logout = async (shouldreturn)=>{
        try {
            setUserInfo({})
            navigate(shouldreturn ? loginBase + '?from=' + fullpath : loginBase)
        } catch (error) {
            console.log(error)
        }
    };
    return logout;
}

export default useLogout