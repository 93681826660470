import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    allUsers : [],
    returningUsers: [],
    activeUsers: [],
    newUsers: [],
}

const visitorSlice = createSlice({
    name: "visitorList",
    initialState,
    reducers: {
        setVisitorList : (state, action)=>{
            state.visitorList = action.payload
        }
    }
})

export const { setVisitorList } = visitorSlice.actions

export default visitorSlice.reducer