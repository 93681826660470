// import React, { useState, useCallback } from 'react'
// import { CgMore } from 'react-icons/cg';
// import { Link } from 'react-router-dom';
// import MessageModal from '../Modals/Mesage';

// const OfferTable = ({ currentList, handleNavigation, data }) => {
//   const [viewMoreStates, setViewMoreStates] = useState({});
//   const [modalIsOpen, setModalIsOpen] = useState(false);
//   const [currentApplicant, setCurrentApplicant] = useState('');

//   const handleView = useCallback((index) => {
//     setViewMoreStates(prev => ({
//       ...prev,
//       [index]: !prev[index]
//     }));
//   }, []);

//   const handleModal = useCallback(() => {
//     setModalIsOpen(prev => !prev);
//   }, []);

//   const selectApplicant = useCallback((applicantName) => {
//     setCurrentApplicant(applicantName);
//   }, []);

//   return (
//     <div className='mt-6 relative text-sm'>
//       <table className='mx-auto overflow-x-scroll'>
//         <thead>
//           <tr className="px-2 py-2 border-b-2 border-green-300">
//             <th className='px-4 py-4'>S/N</th>
//             <th className="px-4 py-4 text-xs font-medium text-gray-500 uppercase">
//               Name
//             </th>
//             <th className="px-4 py-4 text-xs font-medium text-gray-500 uppercase">
//               Description
//             </th>
//             <th className="px-4 py-4 text-xs font-medium text-gray-500 uppercase">
//               Date Applied
//             </th>
//             <th className="px-4 py-4 text-xs font-medium text-gray-500 uppercase">
//               Time Applied
//             </th>
//             <th className="px-4 py-4 text-xs font-medium text-gray-500 uppercase">
//               Review
//             </th>
//             <th className="px-4 py-4 text-sm font-medium text-gray-500 uppercase">
//               Remark
//             </th>
//             <th className="px-4 py-4 text-sm font-medium text-gray-500 uppercase">
//               More
//             </th>
//           </tr>
//         </thead>
//         <tbody>
//           {console.log({first: currentList})}
//           {currentList?.map((eachData, index) => (
//             <tr key={index} className="cursor-pointer hover:bg-green-100 hover:text-slate-50 mt-4">
//               <td className="text-xs font-medium text-gray-500 px-6 py-4 tracking-wider">{index + 1}</td>
//               <td className="text-xs font-medium text-gray-500 px-6 py-4 tracking-wider">{`${eachData.applicant[0].firstName} ${eachData.applicant[0].lastName}`}</td>
//               <td className="text-xs font-medium text-gray-500 px-6 py-4 tracking-wider">{eachData.job.jobTitle}</td>
//               <td className="text-xs font-medium text-gray-500 px-6 py-4 tracking-wider">{eachData.createdAt.split('T')[0]}</td>
//               <td className="text-xs font-medium text-gray-500 px-6 py-4 tracking-wider">{eachData.createdAt.split('T')[1].split(".")[0]}</td>
//               <td className="text-xs font-medium text-gray-500 px-6 py-4 tracking-wider">{eachData.availability}</td>
//               <td className={`text-xs font-medium text-gray-500 px-6 py-4 tracking-wider`}>{eachData.status}</td>
//               <td onClick={() => handleView(index)} className='text-xs font-medium text-gray-500 px-6 py-4 tracking-wider relative hover:text-green-700 hover:bg-green-200 cursor-pointer'>
//                 <CgMore className='mx-auto' />
//                 <div className={`${viewMoreStates[index] ? "block" : "hidden"} absolute -right-48 z-10 -bottom-24 bg-green-950 p-4 text-white w-48 rounded-sm`}>
//                   <button onClick={() => { handleModal(); selectApplicant(eachData.applicantName); }} className='py-1 rounded-sm hover:bg-white w-full text-start px-2 hover:text-green-950'>Send a Message</button>
//                   <button className='py-1 rounded-sm hover:bg-white w-full text-start px-2 hover:text-green-950'><Link to={`tel:${eachData.applicant[0].phoneNumber}`}>Call Applicant</Link></button>
//                 </div>
//               </td>
//             </tr>
//           ))}
//         </tbody>
//       </table>
//       {modalIsOpen && <MessageModal toggle={handleModal} ApplicantName={currentApplicant} />}
//     </div>
//   )
// }

// export default React.memo(OfferTable)


// ------- version -------
import React, { useState, useCallback } from 'react';
import { CgMore } from 'react-icons/cg';
import { Link } from 'react-router-dom';
import MessageModal from '../Modals/Mesage';

const OfferTable = ({ currentList, handleNavigation, data }) => {
  const [viewMoreStates, setViewMoreStates] = useState({});
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [currentApplicant, setCurrentApplicant] = useState('');

  const handleView = useCallback((index) => {
    setViewMoreStates((prev) => ({
      ...prev,
      [index]: !prev[index],
    }));
  }, []);

  const handleModal = useCallback(() => {
    setModalIsOpen((prev) => !prev);
  }, []);

  const selectApplicant = useCallback((applicantName) => {
    setCurrentApplicant(applicantName);
  }, []);

  return (
    <div className="mt-6 relative text-sm">
      <table className="w-full mx-auto border-collapse">
        <thead>
          <tr className="border-b-2 border-green-300">
            <th className="px-6 py-4 text-left text-xs font-semibold text-gray-600 uppercase tracking-wide">S/N</th>
            <th className="px-6 py-4 text-left text-xs font-semibold text-gray-600 uppercase tracking-wide">Name</th>
            <th className="px-6 py-4 text-left text-xs font-semibold text-gray-600 uppercase tracking-wide">Description</th>
            <th className="px-6 py-4 text-left text-xs font-semibold text-gray-600 uppercase tracking-wide">Date Applied</th>
            <th className="px-6 py-4 text-left text-xs font-semibold text-gray-600 uppercase tracking-wide">Time Applied</th>
            <th className="px-6 py-4 text-left text-xs font-semibold text-gray-600 uppercase tracking-wide">Review</th>
            <th className="px-6 py-4 text-left text-xs font-semibold text-gray-600 uppercase tracking-wide">Remark</th>
            <th className="px-6 py-4 text-left text-xs font-semibold text-gray-600 uppercase tracking-wide">More</th>
          </tr>
        </thead>
        <tbody>
          {currentList?.map((eachData, index) => {
            const applicantName = `${eachData.applicant[0]?.firstName || ''} ${eachData.applicant[0]?.lastName || ''}`;
            return (
              <tr
                key={eachData._id || index} // Use unique ID if available
                className="border-b border-gray-200 hover:bg-green-50 transition-colors duration-200"
              >
                <td className="px-6 py-3 text-gray-800 text-xs font-medium uppercase">{index + 1}</td>
                <td className="px-6 py-3 text-gray-800 text-xs font-medium uppercase">{applicantName}</td>
                <td className="px-6 py-3 text-gray-800 text-xs font-medium uppercase">{eachData.job?.jobTitle || 'N/A'}</td>
                <td className="px-6 py-3 text-gray-800 text-xs font-medium uppercase">
                  {eachData.createdAt?.split('T')[0] || 'N/A'}
                </td>
                <td className="px-6 py-3 text-gray-800 text-xs font-medium uppercase">
                  {eachData.createdAt?.split('T')[1]?.split('.')[0] || 'N/A'}
                </td>
                <td className="px-6 py-3 text-gray-800 text-xs font-medium uppercase">
                  {eachData.availability || 'N/A'}
                </td>
                <td className="px-6 py-3 text-green-700 text-xs font-medium uppercase">{eachData.status || 'N/A'}</td>
                <td className="px-6 py-3 relative">
                  <button
                    onClick={() => handleView(index)}
                    className="text-gray-600 hover:text-green-700 focus:outline-none"
                  >
                    <CgMore className="mx-auto w-5 h-5" />
                  </button>
                  {viewMoreStates[index] && (
                    <div className="absolute right-0 z-10 mt-2 bg-gray-800 text-white w-48 rounded-md shadow-lg">
                      <ul className="py-2">
                        <li>
                          <button
                            onClick={() => {
                              handleModal();
                              selectApplicant(applicantName);
                            }}
                            className="block w-full text-left px-4 py-2 hover:bg-gray-700 text-sm"
                          >
                            Send a Message
                          </button>
                        </li>
                        <li>
                          <Link
                            to={`tel:${eachData.applicant[0]?.phoneNumber || ''}`}
                            className="block px-4 py-2 hover:bg-gray-700 text-sm"
                          >
                            Call Applicant
                          </Link>
                        </li>
                      </ul>
                    </div>
                  )}
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
      {modalIsOpen && <MessageModal toggle={handleModal} ApplicantName={currentApplicant} />}
    </div>
  );
};

export default React.memo(OfferTable);