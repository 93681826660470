import React from 'react';
import { Link } from 'react-router-dom';
import NotFoundImg from '../../assets/Images/notFound.jpeg'

const NotFound = () => {
  return (
    <div className="min-h-screen flex flex-col md:flex-row items-center justify-center bg-gray-100 px-4 py-8">
      <div className="w-full md:w-1/2 flex justify-center mb-6 md:mb-0">
        <img 
          src={NotFoundImg} 
          alt="Page Not Found" 
          className="max-w-full max-h-96 object-contain rounded-lg shadow-lg"
        />
      </div>
      <div className="w-full md:w-1/2 max-w-md text-center space-y-6">
        <div>
          <h1 className="text-4xl font-bold text-gray-800 mb-4">Oops!</h1>
          <p className="text-lg text-gray-600 mb-6">
            Seems the page you are looking for isn't available
          </p>
          
          <Link 
            to="/" 
            className="inline-block bg-green-500 hover:bg-green-600 text-white font-semibold py-3 px-6 rounded-lg transition duration-300 ease-in-out transform hover:-translate-y-1 hover:scale-105 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50"
          >
            Return Home
          </Link>
        </div>
        
        <div className="mt-8 text-sm text-gray-500">
          Error 404: Page Not Found
        </div>
      </div>
    </div>
  );
};

export default NotFound;
// --------- version 2 ----------
// import React from 'react';
// import { Link } from 'react-router-dom';
// import { TbAlertTriangle } from 'react-icons/tb';

// const NotFound = () => {
//   return (
//     <div className="min-h-screen flex items-center justify-center bg-gray-100 px-4">
//       <div className="max-w-md w-full space-y-8 text-center">
//         <div className="flex justify-center mb-6">
//           <TbAlertTriangle 
//             className="text-red-500" 
//             size={120} 
//             strokeWidth={1.5} 
//           />
//         </div>
//         <div>
//           <h1 className="text-4xl font-bold text-gray-800 mb-4">Oops!</h1>
//           <p className="text-lg text-gray-600 mb-6">
//             The page you're looking for seems to have wandered off into the digital wilderness.
//           </p>
          
//           <Link 
//             to="/" 
//             className="inline-block bg-green-500 hover:bg-green-600 text-white font-semibold py-3 px-6 rounded-lg transition duration-300 ease-in-out transform hover:-translate-y-1 hover:scale-105 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50"
//           >
//             Return Home
//           </Link>
//         </div>
        
//         <div className="mt-8 text-sm text-gray-500">
//           Error 404: Page Not Found
//         </div>
//       </div>
//     </div>
//   );
// };

// export default NotFound;