import React, { useRef, useState } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { MdCancel, MdInfoOutline } from "react-icons/md";
import { FaCamera } from "react-icons/fa6";
import axios from "axios";
import { toast } from 'sonner';
import { updateUser } from "../../redux/users/userSlice";
import { useFetchData } from "../../hooks/useFtechData";
import { APPLICATION_API, CERTIFICATE_URL, EDUCATION_URL, GUARANTOR_URL, JOBSEEKER_URL, WORK_URL } from "../../resources/resource";
import { convertToBase64 } from "../../Utils/fileConverter";
import Header from "../../component/Header";
import FooterComponent from "../../component/Footer";
import LoadSpinner from "../../component/Modals/LoadSpinner";
import DefaultProfile from "../../assets/Images/employee.png";
// import GoogleImage from "../../assets/Images/locate.png";
import WorkExperienceList from './Work';
import EducationList from './Education';
import ContactList from './ContactPerson';
import ApplicationTable from './Application';
import EmploymentRecord from './Employment';
import Certificate from './Certificate';
import { useMakeRequest } from '../../hooks/useMakeRequest';
import { uploadToCloudinary } from '../../Utils/cloudinaryUpload';
import { Spinner } from 'flowbite-react';

const ProfilePages = () => {
  const dispatch = useDispatch();
  const loggedInUser = useSelector((state)=>state.user)
  const fileInputRef = useRef();
  const makeRequest = useMakeRequest()

  const {data: userDetails, refetch: refetchUser} = useFetchData(JOBSEEKER_URL + `/getAjobSeeker/${loggedInUser?.currentUser?._id}`)

  const [changeListener, setChangeListener] = useState(false);
  const [loadingUpdate, setLoadingUpdate] = useState(false);
  const [loadingImageUpload, setLoadingImageUpload] = useState(false)
  const [previewImage, setPreviewImage] = useState(null);
  const [imageChange, setImageChange] = useState(false)
  const [imageFile, setImageFile] = useState(null)
  const [profileInfo, setProfileInfo] = useState({
    jobSeekerId: loggedInUser?.currentUser?._id
  });

  const handleProfileChange = (e) => {
    setProfileInfo({
      ...profileInfo,
      [e.target.name]: e.target.value,
      jobSeekerId: loggedInUser.currentUser._id,
    });
    setChangeListener(true);
  };

  const handleAvatar = async (e) => {
    const file = e.target.files[0];
    setImageFile(file)
    setPreviewImage(URL.createObjectURL(file));
    setImageChange(true)
  };

  const handleUpdateImage = async ()=>{
    if(!imageFile) return toast.error('indly select an image to continue')
      setLoadingImageUpload(true)
      try {
        const imageURL = await uploadToCloudinary(imageFile)
        if(!imageURL) return toast.error('Error uploading file')
        const payload = {avatar: imageURL}
        makeRequest(
          JOBSEEKER_URL + `/updateJobSeeker/${loggedInUser?.currentUser?._id}`,
          'PUT',
          payload,
          (data)=>{refetchUser()},
          (error)=>{console.log(error || 'Error updating image')},
          ()=>{
            setImageChange(false)
            setLoadingImageUpload(false)
          }
        )
      } catch (error) {
        toast.error('Unknown error try again later')
      }


  }

  const handleUpdate = async () => {
    setLoadingUpdate(true);
    makeRequest(
      JOBSEEKER_URL + `/updateJobSeeker/${loggedInUser?.currentUser?._id}`,
      'PUT',
      profileInfo,
      (data)=>{refetchUser()},
      (error)=>{console.log(error)},
      ()=>{
        setLoadingUpdate(false)
        setChangeListener(false)
      }
    )
  };

  return (
    <div className="min-h-screen bg-gray-50 pt-16 sm:pt-20">
      <Header darkMode={true} />
      
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-8">
        {/* Import Notice */}
        <div className="mb-8 bg-blue-50 border border-blue-200 rounded-lg p-4">
          <div className="flex items-start gap-3">
            <MdInfoOutline className="text-green-500 text-xl flex-shrink-0 mt-0.5" />
            <div className="flex-1">
              <p className="text-sm text-green-700 mb-4">
                For a better experience and to avoid errors, we recommend importing your account details 
                if you already have an account on our other platforms.
              </p>
              <div className="flex flex-wrap gap-3">
                <button className="px-4 py-2 bg-green-600 text-white rounded-md hover:bg-blue-700 transition-colors">
                  Import from e-school
                </button>
                <button className="px-4 py-2 bg-green-600 text-white rounded-md hover:bg-blue-700 transition-colors">
                  Import from E-Verify
                </button>
              </div>
            </div>
            <button className="text-gray-400 hover:text-gray-500">
              <MdCancel className="text-xl" />
            </button>
          </div>
        </div>

        {/* Profile Section */}
        <div className="bg-white rounded-lg shadow-sm p-6 mb-8">
          <div className="grid grid-cols-1 lg:grid-cols-3 gap-8">
            {/* Profile Picture Section */}
            <div className="space-y-6">
              <div className="relative flex items-center">
                <img
                  src={previewImage || userDetails?.avatar || DefaultProfile}
                  alt="Profile"
                  className="w-32 h-32 rounded-full object-cover border-4 border-gray-100 shadow-sm"
                />
                <button 
                  onClick={() => fileInputRef.current.click()}
                  className="absolute bottom-0 left-0 p-2 bg-white rounded-full shadow-lg hover:bg-gray-50 transition-colors"
                >
                  <FaCamera className="text-gray-600" />
                </button>
                <input
                  ref={fileInputRef}
                  type="file"
                  name="avatar"
                  onChange={handleAvatar}
                  className="hidden"
                />
                {imageChange ? <button onClick={handleUpdateImage} className='bg-green-400 ml-2 hover:bg-green-500 text-white p-[3px] rounded-sm'>{loadingImageUpload ? <Spinner/> : 'Upload'}</button> : null}
              </div>
              <div className="p-4 bg-gray-50 rounded-lg border border-gray-200">
                <h3 className="font-medium text-gray-900 mb-2">Primary Address</h3>
                <p className="text-gray-600">{loggedInUser.currentUser.address || 'No address provided'}</p>
              </div>
            </div>

            {/* Personal Information */}
            <div className="space-y-4">
              <FormField
                label="First Name"
                name="firstName"
                type="text"
                value={profileInfo.firstName}
                placeholder={loggedInUser.currentUser.firstName}
                onChange={handleProfileChange}
              />
              <FormField
                label="Email"
                name="email"
                type="email"
                value={profileInfo.email}
                placeholder={loggedInUser.currentUser.email}
                onChange={handleProfileChange}
              />
              <FormField
                label="Date of Birth"
                name="dateOfBirth"
                type="date"
                value={profileInfo.dateOfBirth}
                onChange={handleProfileChange}
              />
              <SelectField
                label="Gender"
                name="gender"
                value={profileInfo.gender}
                onChange={handleProfileChange}
                options={['Male', 'Female']}
              />
            </div>

            {/* Additional Information */}
            <div className="space-y-4">
              <FormField
                label="Last Name"
                name="lastName"
                type="text"
                value={profileInfo.lastName}
                placeholder={loggedInUser.currentUser.lastName}
                onChange={handleProfileChange}
              />
              <FormField
                label="Phone Number"
                name="phoneNumber"
                type="tel"
                value={profileInfo.phoneNumber}
                placeholder={loggedInUser.currentUser.phoneNumber}
                onChange={handleProfileChange}
              />
              <SelectField
                label="Race"
                name="race"
                value={profileInfo.race}
                onChange={handleProfileChange}
                options={['Black', 'White']}
              />
              <SelectField
                label="Disability"
                name="disability"
                value={profileInfo.disability}
                onChange={handleProfileChange}
                options={['No', 'Yes']}
              />
            </div>
          </div>

          {changeListener && (
            <div className="mt-6 text-center">
              <button
                onClick={handleUpdate}
                disabled={loadingUpdate}
                className="px-6 py-2 bg-blue-600 text-white rounded-md hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 transition-colors disabled:opacity-50"
              >
                {loadingUpdate ? <LoadSpinner /> : "Save Changes"}
              </button>
            </div>
          )}
        </div>

        {/* ---- Education ---- */}
        <div className='bg-white rounded-lg shadow-sm p-6 mb-8'>
          <EducationList refetch={refetchUser} userEducation={userDetails?.education}/>
        </div>

        <div className='bg-white rounded-lg shadow-sm p-6 mb-8'>
              <WorkExperienceList userExperience={userDetails?.workExperience} refetch={refetchUser}/>
        </div>

        {/* ---- Guarantor ---- */}
        <div className='bg-white rounded-lg shadow-sm p-6 mb-8'>
            <ContactList userGuarantors={userDetails?.guarantors} refetch={refetchUser}/>
        </div>

        <div className='bg-white rounded-lg shadow-sm p-6 mb-8'>
          <Certificate userCertificate={userDetails?.certificates
          } refetch={refetchUser}/>
        </div>

        {/* applications */}
        <div className='bg-white rounded-lg shadow-sm p-6 mb-8'>
            <ApplicationTable userApplication={userDetails?.appliedJobs}/>
        </div>

        {/* employment */}
        <div className='bg-white rounded-lg shadow-sm p-6 mb-8'>
            <EmploymentRecord jobRecord={userDetails?.appliedJobs}/>
        </div>

        
      </div>


      <FooterComponent />
    </div>
  );
};

// Helper Components
const FormField = ({ label, name, type, value, placeholder, onChange }) => (
  <div className="space-y-1">
    <label htmlFor={name} className="block text-sm font-medium text-gray-700">
      {label}
    </label>
    <input
      type={type}
      name={name}
      id={name}
      value={value || ''}
      onChange={onChange}
      placeholder={placeholder}
      className="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500 transition-colors"
    />
  </div>
);

const SelectField = ({ label, name, value, onChange, options }) => (
  <div className="space-y-1">
    <label htmlFor={name} className="block text-sm font-medium text-gray-700">
      {label}
    </label>
    <select
      name={name}
      id={name}
      value={value || ''}
      onChange={onChange}
      className="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500 transition-colors"
    >
      <option value="">Select {label}</option>
      {options.map((option) => (
        <option key={option} value={option}>
          {option}
        </option>
      ))}
    </select>
  </div>
);

export default ProfilePages;