// import React, { useEffect } from 'react';

// const Dialog = ({ isOpen, onClose, children }) => {
//   useEffect(() => {
//     const handleEscape = (e) => {
//       if (e.key === 'Escape') {
//         onClose();
//       }
//     };

//     if (isOpen) {
//       document.addEventListener('keydown', handleEscape);
//       document.body.style.overflow = 'hidden';
//     }

//     return () => {
//       document.removeEventListener('keydown', handleEscape);
//       document.body.style.overflow = 'unset';
//     };
//   }, [isOpen, onClose]);

//   if (!isOpen) return null;

//   return (
//     <div className="fixed inset-0 z-50 overflow-y-auto">
//       <div className="fixed inset-0 bg-black bg-opacity-50 transition-opacity" onClick={onClose} />
//       <div className="flex min-h-full items-center justify-center p-4">
//         <div className="relative bg-white rounded-lg shadow-xl max-w-lg w-full p-6">
//           {children}
//         </div>
//       </div>
//     </div>
//   );
// };

// const DialogTitle = ({ children, className = '' }) => {
//   return <h2 className={`text-xl font-semibold mb-4 ${className}`}>{children}</h2>;
// };

// export { Dialog, DialogTitle };

import React, { useEffect } from 'react';

const Dialog = ({ isOpen, onClose, children, width = 'xl', outSideClose=true }) => {
  // Map of size classes
  const sizeClasses = {
    sm: 'max-w-sm', // 24rem
    md: 'max-w-md', // 28rem
    lg: 'max-w-lg', // 32rem
    xl: 'max-w-xl', // 36rem
    '2xl': 'max-w-2xl', // 42rem
    '3xl': 'max-w-3xl', // 48rem
    '4xl': 'max-w-4xl', // 56rem
    '5xl': 'max-w-5xl', // 64rem
    '6xl': 'max-w-6xl', // 72rem
    '7xl': 'max-w-7xl', // 80rem
    full: 'max-w-full'
  };

  useEffect(() => {
    const handleEscape = (e) => {
      if (e.key === 'Escape') {
        onClose();
      }
    };

    if (isOpen) {
      document.addEventListener('keydown', handleEscape);
      document.body.style.overflow = 'hidden';
    }

    return () => {
      document.removeEventListener('keydown', handleEscape);
      document.body.style.overflow = 'unset';
    };
  }, [isOpen, onClose]);

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 z-50 overflow-y-auto">
      <div className="fixed inset-0 bg-black bg-opacity-50 transition-opacity" onClick={outSideClose ? onClose : null} />
      <div className="flex min-h-full items-center justify-center p-4">
        <div className={`relative bg-white rounded-lg shadow-xl ${sizeClasses[width]} w-full p-6`}>
          {children}
        </div>
      </div>
    </div>
  );
};

const DialogTitle = ({ children, className = '' }) => {
  return <h2 className={`text-xl font-semibold mb-4 ${className}`}>{children}</h2>;
};

export { Dialog, DialogTitle };