// import React, { useState } from 'react'
// import { CgMore } from 'react-icons/cg';
// import { Link, Navigate } from 'react-router-dom';
// import MessageModal from '../Modals/Mesage';
// import { FaEnvelope, FaPhone, FaUser } from 'react-icons/fa';

// const HiredTable = ({currentList, handleNavigation, data})=> {

//   const [viewMoreStates, setViewMoreStates] = useState(Array(data.length).fill(false));
//   const [toggleModal, setToggleModal] = useState(false)
//   const [modalIsOpen, setModalIsOpen] = useState(false)

//   const handleView = (index) => {
//     setViewMoreStates((prevStates) => {
//       const newStates = [...prevStates];
//       newStates[index] = !newStates[index];
//       return newStates;
//     });
//   };

//   const handleModal = ()=>{
//     setToggleModal(!toggleModal);
//     toggleModal ? setModalIsOpen(true):setModalIsOpen(false)
//   }

//   return (
//     <div className='mt-6 relative text-sm'>
//         <table className='mx-auto overflow-x-scroll'>
//               <thead>
//                   <tr>
//                   <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">S/N</th>
//                   <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Name</th>
//                   <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Description</th>
//                   <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Date Applied</th>
//                   <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Time Applied</th>
//                   <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Date Hired</th>
//                   <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Status</th>
//                   <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">More</th>
//           </tr>
//               </thead>
//               <tbody>
//                 {currentList.map((eachData, index)=>{
//                     return(
//                         <tr key={index} className="cursor-pointer hover:bg-green-100 hover:text-slate-50 mt-4">
//                             <td className="border-2 border-green-50 text-xs font-medium text-gray-500 px-6 py-4 tracking-wider">{index + 1}</td>
//                             <td className="border-2 border-green-50 text-xs font-medium text-gray-500 px-6 py-4 tracking-wider">{`${eachData.applicant[0].firstName} ${eachData.applicant[0].lastName}`}</td>
//                             <td className="border-2 border-green-50 text-xs font-medium text-gray-500 px-6 py-4 tracking-wider">{eachData.job.jobTitle}</td>
//                             <td className="border-2 border-green-50 text-xs font-medium text-gray-500 px-6 py-4 tracking-wider">{eachData.createdAt.split('T')[0]}</td>
//                             <td className="border-2 border-green-50 text-xs font-medium text-gray-500 px-6 py-4 tracking-wider">{eachData.createdAt.split('T')[1].split(".")[0]}</td>
//                             <td className="border-2 border-green-50 text-xs font-medium text-gray-500 px-6 py-4 tracking-wider">{eachData.updatedAt}</td>
//                             <td className={`border-2 border-green-50 text-xs font-medium text-gray-500 px-6 py-4 tracking-wider`}>{eachData.status}</td>
//                             <td onClick={() => handleView(index)} className='relative hover:text-green-700 hover:bg-green-200 cursor-pointer'>
//                               <CgMore className='mx-auto' />
//                               <div className={`${viewMoreStates[index] ? "block" : "hidden"} absolute -right-48 z-10 -bottom-24 bg-green-950 p-4 text-white w-48 rounded-sm`}>
//                                 <button className='py-1 rounded-sm hover:bg-white w-full text-start px-2 hover:text-green-950'><Link to={`/${eachData.jobId}/employeeData/${eachData.applicantId}`}>Report Applicant</Link></button>
//                                 <button onClick={handleModal} className='py-1 rounded-sm hover:bg-white w-full text-start px-2 hover:text-green-950'>Send a Message</button>
//                                 <button className='py-1 rounded-sm hover:bg-white w-full text-start px-2 hover:text-green-950'><Link to={`tel:9123280394`}>Call Applicant</Link></button>
//                                 <button className='py-1 rounded-sm hover:bg-white w-full text-start px-2 hover:text-green-950'><Link to={`/${eachData.applicantId}/applicantProfile/${eachData.jobId}`}>View Profile</Link></button>
//                               </div>
//                           </td>
//                         </tr>
//                     )
//                 })}
//               </tbody>
//             </table>
//             {toggleModal && <MessageModal toggle={handleModal}/>}
//     </div>
//   )
// }

// export default HiredTable

// -------- version 2 -----------
import React, { useState } from 'react';
import { CgMore } from 'react-icons/cg';
import { Link } from 'react-router-dom';
import { FaEnvelope, FaPhone, FaUser } from 'react-icons/fa';
import MessageModal from '../Modals/Mesage';

const HiredTable = ({ currentList, handleNavigation, data }) => {
  const [viewMoreStates, setViewMoreStates] = useState(Array(data.length).fill(false));
  const [modalIsOpen, setModalIsOpen] = useState(false);

  const handleView = (index) => {
    setViewMoreStates((prevStates) => {
      const newStates = [...prevStates];
      newStates[index] = !newStates[index];
      return newStates;
    });
  };

  const handleModal = () => {
    setModalIsOpen((prev) => !prev);
  };

  return (
    <div className="mt-6 relative text-sm">
      <table className="w-full mx-auto border-collapse">
        <thead>
          <tr className="border-b-2 border-green-300">
            <th className="px-6 py-4 text-left text-xs font-semibold text-gray-600 uppercase tracking-wide">S/N</th>
            <th className="px-6 py-4 text-left text-xs font-semibold text-gray-600 uppercase tracking-wide">Name</th>
            <th className="px-6 py-4 text-left text-xs font-semibold text-gray-600 uppercase tracking-wide">Description</th>
            <th className="px-6 py-4 text-left text-xs font-semibold text-gray-600 uppercase tracking-wide">Date Applied</th>
            <th className="px-6 py-4 text-left text-xs font-semibold text-gray-600 uppercase tracking-wide">Time Applied</th>
            <th className="px-6 py-4 text-left text-xs font-semibold text-gray-600 uppercase tracking-wide">Date Hired</th>
            <th className="px-6 py-4 text-left text-xs font-semibold text-gray-600 uppercase tracking-wide">Status</th>
            <th className="px-6 py-4 text-left text-xs font-semibold text-gray-600 uppercase tracking-wide">More</th>
          </tr>
        </thead>
        <tbody>
          {currentList.map((eachData, index) => (
            <tr
              key={eachData._id || index} // Use unique ID if available
              className="border-b border-gray-200 hover:bg-green-50 transition-colors duration-200"
            >
              <td className="px-6 py-3 text-gray-800 text-xs font-medium uppercase">{index + 1}</td>
              <td className="px-6 py-3 text-gray-800 text-xs font-medium uppercase">
                {`${eachData.applicant[0]?.firstName || ''} ${eachData.applicant[0]?.lastName || ''}`}
              </td>
              <td className="px-6 py-3 text-gray-800 text-xs font-medium uppercase">{eachData.job?.jobTitle || 'N/A'}</td>
              <td className="px-6 py-3 text-gray-800 text-xs font-medium uppercase">
                {eachData.createdAt?.split('T')[0] || 'N/A'}
              </td>
              <td className="px-6 py-3 text-gray-800 text-xs font-medium uppercase">
                {eachData.createdAt?.split('T')[1]?.split('.')[0] || 'N/A'}
              </td>
              <td className="px-6 py-3 text-gray-800 text-xs font-medium uppercase">
                {eachData.updatedAt || 'N/A'}
              </td>
              <td className="px-6 py-3 text-green-700 text-xs font-medium uppercase">{eachData.status || 'N/A'}</td>
              <td className="px-6 py-3 relative">
                <button
                  onClick={() => handleView(index)}
                  className="text-gray-600 hover:text-green-700 focus:outline-none"
                >
                  <CgMore className="mx-auto w-5 h-5" />
                </button>
                {viewMoreStates[index] && (
                  <div className="absolute right-0 z-10 mt-2 bg-gray-800 text-white w-56 rounded-md shadow-lg">
                    <ul className="py-2">
                      <li>
                        <Link
                          to={`/${eachData.jobId}/employeeData/${eachData.applicantId}`}
                          className="flex items-center px-4 py-2 hover:bg-gray-700 text-sm"
                        >
                          <FaUser className="mr-2" /> Report Applicant
                        </Link>
                      </li>
                      <li>
                        <button
                          onClick={handleModal}
                          className="flex items-center w-full text-left px-4 py-2 hover:bg-gray-700 text-sm"
                        >
                          <FaEnvelope className="mr-2" /> Send a Message
                        </button>
                      </li>
                      <li>
                        <Link
                          to={`tel:9123280394`}
                          className="flex items-center px-4 py-2 hover:bg-gray-700 text-sm"
                        >
                          <FaPhone className="mr-2" /> Call Applicant
                        </Link>
                      </li>
                      <li>
                        <Link
                          to={`/${eachData.applicantId}/applicantProfile/${eachData.jobId}`}
                          className="flex items-center px-4 py-2 hover:bg-gray-700 text-sm"
                        >
                          <FaUser className="mr-2" /> View Profile
                        </Link>
                      </li>
                    </ul>
                  </div>
                )}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      {modalIsOpen && <MessageModal toggle={handleModal} />}
    </div>
  );
};

export default HiredTable;