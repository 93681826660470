import React from 'react'
import JobCategory from './Job.category';
import { jobCategoryPlans } from '../assets/plan';



const JobListing = ({categorySelection, setCategorySelection, setJobProfile, PaymentHandler, jobProfile, loggedinUser, setPaymentReference, setJobType, close}) => {

    // const handleCategorySelection = (reference) => {
        
    //   };

  return (
    <section className="">
          <div className="flex items-start justify-center flex-wrap my-4 gap-4">
            {
                jobCategoryPlans.map((eachPlan, index)=>{
                    return(
                    <div className='flex items-center justify-center gap-5 h-fit'>
                    <JobCategory
                        plan_name={eachPlan.planName}
                        plan_price={eachPlan.credits}
                        plan_benefits={eachPlan.benefits}
                        // success_handler = {handleCategorySelection}
                        key={index}
                        categorySelection={categorySelection}
                        setCategorySelection={setCategorySelection}
                        setPaymentReference={setPaymentReference}
                        setJobType={setJobType}
                        close={close}
                        />
                    </div>
                    )
                })
            }
          </div>
        </section>

  )
}

export default JobListing