// -----------Imported React components-------------
import React, { useState, useEffect } from "react";
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from "react-hook-form";


// -----------Imported icons-------------------
import { MdCancel } from "react-icons/md";


// ---------Imported Components----------------
import Header from "../../component/Header";
import FooterComponent from "../../component/Footer";


// ---------Imported Data------------------
import { lgaData } from "../../assets/localGov";
import {
  currencies,
  experienceLengths,
  jobFunctions,
  jobIndustries,
  jobLevels,
  jobSkills,
  jobTag,
  minimumRequirements,
  nigeriaStates,
  workTypes,
} from "../../assets/jobData";


// -----------------Imported dependencies---------------
// import axios from "axios";
import { useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "sonner";
// import { addNewEmployerJob } from "../../redux/employerJob/employerJobSlice";
import PaymentHandler from "../../component/PaymentHandler";
import { Button, Label, Select, Textarea, TextInput } from "flowbite-react";
import JobListing from "../../component/JobListing.subscription";
import { jobPostSchema } from "../../validation/auth";
import { Dialog } from "../Profile/DialogBox";
import { useMakeRequest } from "../../hooks/useMakeRequest";
import { JOB_URL } from "../../resources/resource";



function PostJob() {
  const [jobProfile, setJobProfile] = useState({});
  const [jobType, setJobType] = useState("Free Plan");
  const [interviewQuestion, setInterviewQuestion] = useState("");
  const [addSkills, setAddSkills] = useState([]);
  const [addTags, setAddTags] = useState([]);
  const loggedinUser = useSelector((state) => state.user);
  const [errorMessage, setErrorMessage] = useState("");
  const [currentDescription, setCurrentDescription] = useState("");
  const [currentTag, setCurrentTag] = useState("")
  const [currentSkill, setCurrentSkill] = useState("")
  const [loading, setLoading] = useState(false);
  const [paymentReference, setPaymentReference] = useState({})
  const [categorySelection, setCategorySelection] = useState(null)
  const [showSubscrioption, setShowSubscription] = useState(true)
  const makeRequest = useMakeRequest()
  const navigate = useNavigate()

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    getValues,
    watch
  } = useForm({
    resolver: yupResolver(jobPostSchema),
    defaultValues: {
      jobSkills: [],
      jobTag: [],
      jobDescription: [],
      additionalInterviewQuestion: [],
      jobType: 'Free Plan',
      employer: loggedinUser.currentUser._id,
      employerName: loggedinUser.currentUser.companyName,
      paymentReference: {}
    }
  });

  const selectedState = watch('state')
  const jobDescriptions = watch('jobDescription') || [];
  const jobTags = watch("jobTag")
  const jobSkill = watch("jobSkills")
  const interviewQuestions = watch("additionalInterviewQuestion")


// -----------------Payment selection handler-----------------


//  --------Payment setups-------------
  const checkPayment = ()=>{
    if(!jobType === 'Free Plan'){
      return paymentReference === 'N/A' ? false : true
    }
    return true
  }

  
// -----------------Skill handlers--------------------
  const handleAddSkills = () => {
    const skillToAdd = currentSkill
    if(skillToAdd === "")return
    const currentSkills = getValues('jobSkills') || [];
    if (!currentSkills.includes(skillToAdd)) {
      setValue('jobSkills',[...currentSkills, skillToAdd]);
    }
  };

  const handleRemoveSkills = (skillToRemove) => {
    const currentSkills = getValues("jobSkills") || []
    const updatedSkillList = currentSkills.filter((eachSkill) => eachSkill !== skillToRemove);
    setValue("jobSkills", updatedSkillList);
  };


// ----------------Tag Handlers--------------------
  const handleAddTag = () => {
    const tagToAdd = currentTag
    if(tagToAdd === "")return
    const currentTags = getValues("jobTag")
    if(!currentTags.includes(tagToAdd)){
      setValue("jobTag", [...currentTags, tagToAdd])
    }
  };

  const handleRemoveTag = (tagToremove) => {
    const currentTag = getValues("jobTag") || []
    const updatedTag = currentTag.filter((eachTag)=> eachTag !== tagToremove)
    setValue("jobTag", updatedTag)
  };


  const handleAddJobDescription = (e) => {
    e.preventDefault();
    if (currentDescription.trim() === "") return;
  
    // Get current descriptions and add new one
    const currentDescriptions = getValues('jobDescription') || [];
    setValue('jobDescription', [...currentDescriptions, currentDescription.trim()]);
    
    // Clear the input
    setCurrentDescription("");
  };
  
  // Update the remove description handler
  const handleRemoveJobDescription = (descriptionToRemove) => {
    const currentDescriptions = getValues('jobDescription');
    const updatedDescriptions = currentDescriptions.filter(
      desc => desc !== descriptionToRemove
    );
    setValue('jobDescription', updatedDescriptions);
  };


// ---------------Job additional question handlers----------------------
const handleAddQuestion = () => {
  const questionToAdd = interviewQuestion
  if (questionToAdd.trim() === "") return;
  const currentQuestions = getValues('additionalInterviewQuestion') || [];
  setValue('additionalInterviewQuestion', [...currentQuestions, questionToAdd.trim()]);
  // Clear the input
  setInterviewQuestion("");
};

// Update the remove description handler
const handleRemoveQuestion = (questionToRemove) => {
  const currentQuestions = getValues('additionalInterviewQuestion');
  const updatedQuestions = currentQuestions.filter(
    desc => desc !== questionToRemove
  );
  setValue("additionalInterviewQuestion", updatedQuestions);
};


  // -------------------Submit job handler----------------
  const handleCreate = async (data) => {
    setLoading(true)
//--------Check type and payment made--------------
    if (!checkPayment()) return toast.error('Payment required')
    const payload = {...data, paymentReference}
    makeRequest(
      JOB_URL + '/createJob',
      'POST',
      payload,
      (data)=>{console.log(data)},
      (error)=>{console.log(error)},
      ()=>{
        setLoading(false)
        navigate(`/employer/${loggedinUser.currentUser._id}`)
      }
    )
  };



  return (
    <div className="sm:px-24 px-6 bg-gray-100 pt-16 sm:pt-20">
      <Header darkMode={true} />
      <div className="text-sm">
        <h3 className="my-2 font-semibold text-lg capitalize">
          Please select your job type
        </h3>
        <span className="text-slate-600">
          Please select what type of job you would like to post.{" "}
        </span>

{/* ---------------------Job form section--------------------------- */}
        <form onSubmit={handleSubmit(handleCreate)} className="mt-24 bg-white p-4">
          <h3 className="font-semibold text-sm  uppercase my-4">
            Job Description and breakdown
          </h3>
          
          <p className="font-medium text-md my-4 text-slate-400">
            Please fill the form below to vividly explain the job opening and to
            attract the right candidate
          </p>

          <div className="border-2 w-full border-slate-300 p-4 rounded-lg">
            
            {/* ---------job title------------- */}
            <div className="w-full">
              <Label>Job Title</Label>
              <TextInput
                className="w-full mt-2 p-0.5 rounded-sm border-none"
                type="text"
                placeholder="Position"
                name="jobTitle"
                {...register("jobTitle")}
              />
              {errors.jobTitle ? 
                <p className="w-full text-red-600 font-semibold text-start">{errors?.jobTitle?.message}</p>
                :
                ""
              }
            </div>

            {/* ----------------job Function------------------------*/}
            
            <div className="my-4 flex items-center justify-center gap-4">
              <div className="w-full my-2">
                <Label className="block my-2" htmlFor="jobFunction">
                  Job Function
                </Label>
                <Select
                  className="p-1 w-full"
                  name="jobFunction"
                  {...register("jobFunction")}
                >
                  <option selected={true}>Select</option>
                  {jobFunctions.map((eachFunction) => {
                    return <option value={eachFunction}>{eachFunction}</option>;
                  })}
                </Select>
                {errors.jobFunction ? 
                <p className="w-full text-red-600 font-semibold text-start">{errors?.jobFunction?.message}</p>
                :
                ""
              }
              </div>

              <div className="w-full my-4">
                <Label className="block my-2" htmlFor="industry">
                  Industry
                </Label>
                <Select
                  className="p-1 w-full"
                  name="industry"
                  {...register("industry")}
                >
                  <option selected={true}>Select</option>
                  {jobIndustries.map((eachIndustry) => {
                    return <option value={eachIndustry}>{eachIndustry}</option>;
                  })}
                </Select>
                {errors.industry ? 
                <p className="w-full text-red-600 font-semibold text-start">{errors?.industry?.message}</p>
                :
                ""
              }
              </div>
              
              <div className="w-full my-4">
                <Label className="block my-2" htmlFor="workType">
                  Work type
                </Label>
                <Select
                  className="p-1 w-full"
                  name="workType"
                  {...register("workType")}
                >
                  <option selected={true}>Select</option>
                  {workTypes.map((eachWorkType) => {
                    return <option value={eachWorkType}>{eachWorkType}</option>;
                  })}
                </Select>
                {errors.workType ? 
                <p className="w-full text-red-600 font-semibold text-start">{errors?.workType?.message}</p>
                :
                ""
              }
              </div>
            </div>

            {/* ------------------------job experirience length------------------------*/}
            <div className="my-4 flex items-center justify-center gap-4">
              <div className="w-full my-2">
                <Label className="block my-2" htmlFor="experienceLength">
                  Experience Length
                </Label>
                <Select
                  className="p-1 w-full"
                  {...register("experienceLength")}
                  name="experienceLength"
                >
                  <option selected={true}>Select</option>
                  {experienceLengths.map((eachExperienceLength) => {
                    return (
                      <option value={eachExperienceLength}>
                        {eachExperienceLength}
                      </option>
                    );
                  })}
                </Select>
                {errors.experienceLength ? 
                <p className="w-full text-red-600 font-semibold text-start">{errors?.experienceLength?.message}</p>
                :
                ""
              }
              </div>
              <div className="w-full my-2">
                <Label className="block my-2" htmlFor="currency">
                  Salary Currency
                </Label>
                <Select
                  className="p-1 w-full"
                  {...register("currency")}
                  name="currency"
                >
                  <option selected={true}>Select</option>
                  {currencies.map((eachCurrency) => {
                    return <option value={eachCurrency}>{eachCurrency}</option>;
                  })}
                </Select>
                {errors.currency ? 
                <p className="w-full text-red-600 font-semibold text-start">{errors?.currency?.message}</p>
                :
                ""
              }
              </div>
              <div className="w-full my-2">
                <Label className="block my-2" htmlFor="salary">
                  Salary Range
                </Label>
                <TextInput
                  className="w-full mt-2 p-0.5 rounded-sm"
                  type="text"
                  placeholder="Salary range"
                  name="salary"
                  {...register("salary")}
                />
                {errors.salary ? 
                <p className="w-full text-red-600 font-semibold text-start">{errors?.salary?.message}</p>
                :
                ""
              }
              </div>
            </div>

            {/* ---------------------Minimum Qualification and State--------------------- */}
            <div className="my-4 flex items-center justify-center gap-4">
              <div className="w-full my-2">
                <Label className="block my-2" htmlFor="minimumQualification">
                  Minimum qualification
                </Label>
                <Select
                  className="p-1 w-full"
                  {...register("minimumQualification")}
                  name="minimumQualification"
                >
                  <option selected={true}>Select</option>
                  {minimumRequirements.map((eachReq) => {
                    return <option value={eachReq}>{eachReq}</option>;
                  })}
                </Select>
                {errors.minimumQualification ? 
                <p className="w-full text-red-600 font-semibold text-start">{errors?.minimumQualification?.message}</p>
                :
                ""
              }
              </div>


              {/* states */}
              <div className="w-full my-2">
                <Label className="block my-2" htmlFor="state">
                  State
                </Label>
                <Select
                  className="p-1 w-full"
                  name="state"
                  {...register("state")}
                >
                  <option selected={true}>Select</option>
                  {nigeriaStates.map((eachState) => {
                    return <option value={eachState}>{eachState}</option>;
                  })}
                </Select>
                {errors.state ? 
                <p className="w-full text-red-600 font-semibold text-start">{errors?.state?.message}</p>
                :
                ""
              }
              </div>


              {/* local government */}
              <div className="w-full my-2">
                <Label className="block my-2" htmlFor="localGovernment">
                  Local Government
                </Label>
                <Select
                  className="p-1 w-full"
                  {...register("localGovernment")}
                  name="localGovernment"
                >
                  <option selected={true}>Select</option>
                  {lgaData[selectedState]?.map((eachState) => {
                    return <option value={eachState}>{eachState}</option>;
                  })}
                </Select>
                {errors.localGovernment ? 
                <p className="w-full text-red-600 font-semibold text-start">{errors?.localGovernment?.message}</p>
                :
                ""
              }
              </div>
            </div>

            {/* -------------------------Job Description------------------------- */}

            <div className="my-2">
                <Label className="block my-2" htmlFor="jobDescription">
                  Job Description
                </Label>
                <Textarea
                  className="w-full mt-2 p-0.5 rounded-sm"
                  placeholder="Job Description"
                  value={currentDescription}
                  onChange={(e) => setCurrentDescription(e.target.value)}
                />
                {errors.jobDescription && (
                  <p className="text-red-600 text-sm mt-1">
                    {errors.jobDescription.message}
                  </p>
                )}
                <Button 
                  onClick={handleAddJobDescription}
                  type="button" // Important: prevent form submission
                  className="bg-gray-400 text-gray-50 rounded-lg mt-2"
                >
                  Add description
                </Button>
                
                <div className="my-2 w-full flex flex-wrap items-center justify-start gap-2">
                  {jobDescriptions.map((description, index) => (
                    <span 
                      key={index}
                      className="bg-gray-400 text-gray-100 rounded-lg px-2 py-1 w-fit relative"
                    >
                      {description}
                      <MdCancel
                        onClick={() => handleRemoveJobDescription(description)}
                        className="text-red-300 absolute -top-2 right-0 cursor-pointer hover:text-red-600"
                      />
                    </span>
                  ))}
                </div>
                
                {/* Hidden input to hold the actual form value */}
                <input
                  type="hidden"
                  {...register('jobDescription')}
                />
              </div>

            {/* ------------ job summary ---------- */}
            <div className="my-2">
              <Label className="block my-2" htmlFor="jobSummary">
                Job Summary
              </Label>
              <Textarea
                className="w-full mt-2 p-0.5 rounded-sm"
                name="jobSummary"
                placeholder="Job Summary"
                {...register("jobSummary")}
              />
            </div>
            {errors.jobSummary ? 
                <p className="w-full text-red-600 font-semibold text-start">{errors?.jobSummary?.message}</p>
                :
                ""
              }
          </div>

          {/* --------------------Job durations-------------------- */}
          <div className="my-4">
            <h3 className="text-lg font-semibold my-4">
              Job duration & Number of openings
            </h3>
            <p className="font-medium text-md my-4 text-slate-400">
              Please provide the job durations
            </p>
            <div className="w-full p-4 rounded-lg">
              <div>
                <Label className="block my-2" htmlFor="numberOfOpenings">
                  Number of Opening
                </Label>
                <TextInput
                  className="w-full mt-2 p-0.5 rounded-sm"
                  type="text"
                  name="numberOfOpenings"
                  placeholder="Number of opening"
                {...register("numberOfOpenings")}
                />
                 {errors.numberOfOpenings ? 
                <p className="w-full text-red-600 font-semibold text-start">{errors?.numberOfOpenings?.message}</p>
                :
                ""
              }
              </div>
              <div>
                <Label className="block my-2" htmlFor="vacancyDuration">
                  Vacancy Duration/Valid till
                </Label>
                <TextInput
                  className="w-full mt-2 p-0.5 rounded-sm"
                  type="date"
                  name="vacancyDuration"
                  placeholder="Duration in days"
                {...register("vacancyDuration")}
                />
                 {errors.vacancyDuration ? 
                <p className="w-full text-red-600 font-semibold text-start">{errors?.vacancyDuration?.message}</p>
                :
                ""
              }
              </div>
            </div>
          </div>

          {/* -----------------------Skills and Tags----------------------- */}
          <div className="my-4">
            <h3 className="text-lg font-semibold my-4">Skills and Tags</h3>
            <p className="font-medium text-md my-4 text-slate-400">
              Add relevant skills and tags to attract the best candidates.
            </p>
            <div className="w-full p-4 rounded-lg">
              {/* Add Skills */}
              <div className="w-full my-2">
                <Label className="block my-2" htmlFor="jobSkills">
                  Skills
                </Label>
                <Select
                  className="p-1 w-full"
                  onChange={(e)=>setCurrentSkill(e.target.value)}
                  name="jobSkills"
                >
                  <option selected={true}>Select</option>
                  {jobSkills.map((skill) => {
                    return (
                      <option key={skill} value={skill}>
                        {skill}
                      </option>
                    );
                  })}
                </Select>
                <Button 
                  onClick={handleAddSkills}
                  type="button" // Important: prevent form submission
                  className="bg-gray-400 text-gray-50 rounded-lg mt-2"
                >
                  Add skill
                </Button>
                <div className="flex flex-wrap mt-2">
                  {jobSkill.map((skill) => (
                    <span
                      key={skill}
                      className="bg-gray-200 text-gray-600 rounded-full px-3 py-1 mr-2 mb-2"
                      onClick={() => handleRemoveSkills(skill)}
                    >
                      {skill} &times;
                    </span>
                  ))}
                </div>
                {errors.jobSkills ? 
                <p className="w-full text-red-600 font-semibold text-start">{errors?.jobSkills?.message}</p>
                :
                ""
              }
              </div>


              {/* --------- Add Tags ----------- */}
              <div className="w-full my-2">
                <Label className="block my-2" htmlFor="jobTag">
                  Tags
                </Label>
                <Select
                  className="p-1 w-full"
                  name="jobTag"
                  onChange={(e)=>setCurrentTag(e.target.value)}
                >
                  <option selected={true}>Select</option>
                  {jobTag.map((tag) => {
                    return (
                      <option key={tag} value={tag}>
                        {tag}
                      </option>
                    );
                  })}
                </Select>
                <Button 
                  onClick={handleAddTag}
                  type="button" // Important: prevent form submission
                  className="bg-gray-400 text-gray-50 rounded-lg mt-2"
                >
                  Add tag
                </Button>
                <div className="flex flex-wrap mt-2">
                  {jobTags.map((tag) => (
                    <span
                      key={tag}
                      className="bg-gray-200 text-gray-600 rounded-full px-3 py-1 mr-2 mb-2"
                      onClick={() => handleRemoveTag(tag)}
                    >
                      {tag} &times;
                    </span>
                  ))}
                </div>
                {errors.jobTag ? 
                <p className="w-full text-red-600 font-semibold text-start">{errors?.jobTag?.message}</p>
                :
                ""
              }
              </div>
            </div>
          </div>

          {/* ---------------------------Interview Questions--------------------------- */}
          <div className="my-4">
            <h3 className="text-lg font-semibold my-4">Interview Questions</h3>
            <p className="font-medium text-md my-4 text-slate-400">
              Add interview questions to help screen candidates.
            </p>
            <div className="border-2 w-full p-4 rounded-lg">
              <div className="w-full my-2">
                <Label className="block my-2" htmlFor="interviewQuestion">
                  Interview Questions
                </Label>
                <TextInput
                  className="w-full mt-2 p-0.5 rounded-sm"
                  type="text"
                  placeholder="Enter interview question"
                  value={interviewQuestion}
                  name="interviewQuestion"
                  onChange={(e)=>setInterviewQuestion(e.target.value)}
                />
                <Button
                  className="mt-2 bg-gray-500 text-white rounded-md"
                  onClick={handleAddQuestion}
                >
                  Add Question
                </Button>
                <div className="flex flex-wrap mt-2">
                  {interviewQuestions.map((question, index) => (
                    <div
                      key={index}
                      onClick={()=>{handleRemoveQuestion(question)}}
                      className="bg-gray-200 text-gray-600 rounded-full px-3 py-1 mr-2 mb-2"
                    >
                      {question} &times;
                    </div>
                  ))}
                </div>
                {errors.interviewQuestions ? 
                <p className="w-full text-red-600 font-semibold text-start">{errors?.interviewQuestions?.message}</p>
                :
                ""
              }
              </div>
            </div>
          </div>

          {/* ---------------------------Cover Letter and Additional Documents--------------------------- */}
          <div className="my-4">
            <h3 className="text-lg font-semibold my-4">
              Cover Letter and Additional Documents
            </h3>
            <p className="font-medium text-md my-4 text-slate-400">
              Do you require a cover letter or additional documents from
              applicants?
            </p>
            <div className="w-full p-4 rounded-lg">
              <div className="w-full my-2">
                <Label className="block my-2" htmlFor="requireCoverLetter">
                  Cover Letter Required?
                </Label>
                <Select
                  className="p-1 w-full"
                  {...register("requireCoverLetter")}
                  name="requireCoverLetter"
                >
                  <option value={true}>Yes</option>
                  <option value={false}>No</option>
                </Select>
                {errors.requireCoverLetter ? 
                <p className="w-full text-red-600 font-semibold text-start">{errors?.requireCoverLetter?.message}</p>
                :
                ""
              }
              </div>
              <div className="w-full my-2">
                <Label className="block my-2" htmlFor="requiredocumentUpload">
                  Additional Documents Required?
                </Label>
                <Select
                  className="p-1 w-full"
                  {...register("requiredocumentUpload")}
                  name="requiredocumentUpload"
                >
                  <option value={true}>Yes</option>
                  <option value={false}>No</option>
                </Select>
                {errors.requiredocumentUpload ? 
                <p className="w-full text-red-600 font-semibold text-start">{errors?.requiredocumentUpload?.message}</p>
                :
                ""
              }
              </div>
            </div>
          </div>

          {/* ----------------Submission and errors--------------- */}
          <div>
          <div className="flex items-center justify-center gap-5 mb-10">
            <Link to={`/employer/${loggedinUser.currentUser._id}`}
              className="mt-8 bg-white-500 border-2 border-gray-300 text-gray-500 py-2 px-4 rounded-md"
              onClick={(e) => {
              }}
            >
              Return
            </Link>
            <Button
              className="mt-8 bg-green-500 text-white rounded-md"
              type="submit"
            >
              {loading ? "...loading" : "Submit"}
            </Button>
          </div>

          </div>
        </form>
      </div>
      <Dialog
        isOpen={showSubscrioption}
        onClose={()=>setShowSubscription(false)}
        width="4xl"
        outSideClose={false}
      >
      <div className="w-full">
        <JobListing 
            categorySelection={categorySelection}
            setCategorySelection={setCategorySelection}
            setJobProfile={setJobProfile}
            PaymentHandler={PaymentHandler}
            setPaymentReference={setPaymentReference}
            jobProfile={jobProfile}
            loggedinUser={loggedinUser}
            setJobType={setJobType}
            close={()=>{setShowSubscription(false)}}
            />
          </div>
      </Dialog>
      <FooterComponent />
    </div>
  );
}

export default PostJob;
