// // --------------Version 2----------------

// import React, { useState } from "react";
// import Header from "../component/Header";
// import BlacklistUser from "../component/BlacklistUser";
// import FooterComponent from "../component/Footer";
// import BlacklistComponent from '../component/BlacklistComponent copy'
// import { FaIndustry } from "react-icons/fa";
// import { BiUserCircle } from "react-icons/bi";
// import Background from '../assets/Images/blacklisted.jpg';
// import { blacklistedCompanies, blacklistedUsers } from "../assets/data";

// function Blacklist() {
//   const [formData, setFormData] = useState({});
//   const [companyList, setCompanyList] = useState(blacklistedCompanies);
//   const [userList, setUsersList] = useState(blacklistedUsers);
//   const [selectionError, setSelectionError] = useState(false);


//   const handleSubmit = (e) => {
//     e.preventDefault();
//     const searchTable = formData.searchOption;
//     if (!searchTable) {
//       return setSelectionError(true);
//     }
//     const nameToSearch = formData.blacklistName.toLowerCase();
//     if (searchTable === 'companies') {
//       setCompanyList(blacklistedCompanies.filter(company => company.companyName.toLowerCase().includes(nameToSearch)));
//     } else {
//       setUsersList(blacklistedUsers.filter(user => user.Fullname.toLowerCase().includes(nameToSearch)));
//     }
//   };

//   const handleSearchType = (e) => {
//     setFormData({ ...formData, [e.target.name]: e.target.value });
//   };

//   const handleChange = (e) => {
//     setFormData({ ...formData, [e.target.name]: e.target.value });
//     setCompanyList(blacklistedCompanies);
//     setUsersList(blacklistedUsers);
//     setSelectionError(false);
//   };

//   return (
//     <div className="text-sm pt-16 sm:pt-20">
//       <Header />
//       <div className="mx-auto my-10 p-4 max-w-screen-xl">
//         <div 
//           style={{ backgroundImage: `url(${Background})` }} 
//           className="flex flex-col items-center justify-center gap-10 p-8 rounded-md bg-cover bg-center relative text-white"
//         >
//           <div className="absolute inset-0 bg-black opacity-50 rounded-md"></div>
//           <div className="relative z-10 text-center">
//             <h1 className="text-3xl font-semibold mb-4">Blacklist</h1>
//             <p className="max-w-2xl mx-auto">
//               At eJobs we blacklist suspicious companies/users. When a company/user is added to the blacklist, it means that our safety and verification agents have marked them as not safe, trustworthy, or charged with fraudulent activities. There are several reasons why you can be flagged on our blacklist.
//             </p>
//           </div>
//           <form onSubmit={handleSubmit} className="relative z-10 w-full max-w-2xl">
//             <div className="flex justify-center gap-4 mb-6">
//               <label className="flex flex-col items-center cursor-pointer">
//                 <input type="radio" name="searchOption" value="companies" onChange={handleSearchType} className="hidden peer" />
//                 <FaIndustry className="text-4xl mb-2 peer-checked:text-green-500" />
//                 <p className="mb-2 peer-checked:text-green-500">Company</p>
//               </label>
//               <label className="flex flex-col items-center cursor-pointer">
//                 <input type="radio" name="searchOption" value="users" onChange={handleSearchType} className="hidden peer" />
//                 <BiUserCircle className="text-4xl mb-2 peer-checked:text-green-500" />
//                 <p className="mb-2 peer-checked:text-green-500">Users</p>
//               </label>
//             </div>
//             <div className="flex items-center border rounded-md overflow-hidden">
//               <input
//                 className="flex-1 p-2 text-black"
//                 type="text"
//                 name="blacklistName"
//                 placeholder="Enter name to search"
//                 onChange={handleChange}
//               />
//               <button type="submit" className="bg-green-500 text-white p-2">Search</button>
//             </div>
//             {selectionError && <span className="block mt-2 text-red-500">Error: Please select a type</span>}
//           </form>
//         </div>

//         <div className="mt-10">
//           <div className="flex flex-wrap gap-10">
//             <div className="w-full md:w-[45%]">
//               <h2 className="text-lg font-semibold mb-4">Blacklisted Companies</h2>
//               {companyList.map((company, index) => (
//                 <BlacklistComponent
//                   key={index}
//                   companyName={company.companyName}
//                   companyAddress={company.companyAddress}
//                   complaint={company.reports}
//                 />
//               ))}
//             </div>
//             <div className="w-full md:w-[45%]">
//               <h2 className="text-lg font-semibold mb-4">Blacklisted Users</h2>
//               {userList.map((user, index) => (
//                 <BlacklistUser
//                   key={index}
//                   complaint={user.reports}
//                   username={user.Fullname}
//                   email={user.email}
//                 />
//               ))}
//             </div>
//           </div>
//         </div>
//       </div>
//       <FooterComponent />
//     </div>
//   );
// }

// export default Blacklist;

// ---- version 2 -----
// import React, { useState } from "react";
// import Header from "../component/Header";
// import BlacklistUser from "../component/BlacklistUser";
// import FooterComponent from "../component/Footer";
// import BlacklistComponent from '../component/BlacklistComponent copy';
// import Background from '../assets/Images/blacklisted.jpg';
// import { blacklistedCompanies, blacklistedUsers } from "../assets/data";

// function Blacklist() {
//   const [formData, setFormData] = useState({});
//   const [companyList, setCompanyList] = useState(blacklistedCompanies);
//   const [userList, setUsersList] = useState(blacklistedUsers);
//   const [selectionError, setSelectionError] = useState(false);

//   const handleSubmit = (e) => {
//     e.preventDefault();
//     const searchTable = formData.searchOption;
//     if (!searchTable) {
//       return setSelectionError(true);
//     }
//     const nameToSearch = formData.blacklistName?.toLowerCase() || "";
//     if (searchTable === 'companies') {
//       setCompanyList(blacklistedCompanies.filter(company => 
//         company.companyName.toLowerCase().includes(nameToSearch)
//       ));
//     } else {
//       setUsersList(blacklistedUsers.filter(user => 
//         user.Fullname.toLowerCase().includes(nameToSearch)
//       ));
//     }
//   };

//   const handleChange = (e) => {
//     setFormData({ ...formData, [e.target.name]: e.target.value });
//     setCompanyList(blacklistedCompanies);
//     setUsersList(blacklistedUsers);
//     setSelectionError(false);
//   };

//   return (
//     <div className="min-h-screen bg-gray-50">
//       <Header />
      
//       <div className="pt-16 sm:pt-20">
//         <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-12">
//           {/* Hero Section */}
//           <div 
//             className="relative rounded-2xl overflow-hidden mb-12"
//             style={{ 
//               backgroundImage: `url(${Background})`,
//               backgroundSize: 'cover',
//               backgroundPosition: 'center'
//             }}
//           >
//             <div className="absolute inset-0 bg-black/60 backdrop-blur-sm"></div>
            
//             <div className="relative z-10 px-6 py-12 sm:px-12 text-white">
//               <div className="max-w-3xl mx-auto text-center mb-8">
//                 <h1 className="text-3xl sm:text-4xl font-bold mb-4">
//                   Blacklist Registry
//                 </h1>
//                 <p className="text-lg text-gray-200">
//                   At eJobs we blacklist suspicious companies/users. When a company/user is added 
//                   to the blacklist, it means that our safety and verification agents have marked 
//                   them as not safe, trustworthy, or charged with fraudulent activities.
//                 </p>
//               </div>

//               <form onSubmit={handleSubmit} className="max-w-2xl mx-auto">
//                 <div className="flex flex-col sm:flex-row gap-4">
//                   <div className="flex-1">
//                     <select
//                       name="searchOption"
//                       onChange={handleChange}
//                       className="w-full px-4 py-3 rounded-lg bg-white/10 backdrop-blur-md 
//                                border border-white/20 text-white placeholder-white/70
//                                focus:outline-none focus:ring-2 focus:ring-white/50"
//                       defaultValue=""
//                     >
//                       <option value="" disabled>Select type to search</option>
//                       <option value="companies">Search Companies</option>
//                       <option value="users">Search Users</option>
//                     </select>
//                   </div>
                  
//                   <div className="flex-[2] flex">
//                     <input
//                       type="text"
//                       name="blacklistName"
//                       placeholder="Enter name to search..."
//                       onChange={handleChange}
//                       className="flex-1 px-4 py-3 rounded-l-lg bg-white/10 backdrop-blur-md 
//                                border border-white/20 text-white placeholder-white/70
//                                focus:outline-none focus:ring-2 focus:ring-white/50"
//                     />
//                     <button 
//                       type="submit"
//                       className="px-6 py-3 bg-green-500 hover:bg-green-600 
//                                text-white font-medium rounded-r-lg transition-colors
//                                focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-offset-2"
//                     >
//                       Search
//                     </button>
//                   </div>
//                 </div>
//                 {selectionError && (
//                   <p className="mt-2 text-red-400 text-sm">
//                     Please select a type to search
//                   </p>
//                 )}
//               </form>
//             </div>
//           </div>

//           {/* Results Section */}
//           <div className="grid md:grid-cols-2 gap-8">
//             {/* Blacklisted Companies */}
//             <div className="bg-white rounded-xl shadow-sm p-6">
//               <h2 className="text-xl font-semibold text-gray-900 mb-6 flex items-center">
//                 <span className="w-1.5 h-1.5 bg-red-500 rounded-full mr-2"></span>
//                 Blacklisted Companies
//                 <span className="ml-2 text-sm font-normal text-gray-500">
//                   ({companyList.length})
//                 </span>
//               </h2>
              
//               <div className="space-y-4">
//                 {companyList.length === 0 ? (
//                   <p className="text-gray-500 text-center py-8">
//                     No blacklisted companies found
//                   </p>
//                 ) : (
//                   companyList.map((company, index) => (
//                     <BlacklistComponent
//                       key={index}
//                       companyName={company.companyName}
//                       companyAddress={company.companyAddress}
//                       complaint={company.reports}
//                     />
//                   ))
//                 )}
//               </div>
//             </div>

//             {/* Blacklisted Users */}
//             <div className="bg-white rounded-xl shadow-sm p-6">
//               <h2 className="text-xl font-semibold text-gray-900 mb-6 flex items-center">
//                 <span className="w-1.5 h-1.5 bg-red-500 rounded-full mr-2"></span>
//                 Blacklisted Users
//                 <span className="ml-2 text-sm font-normal text-gray-500">
//                   ({userList.length})
//                 </span>
//               </h2>
              
//               <div className="space-y-4">
//                 {userList.length === 0 ? (
//                   <p className="text-gray-500 text-center py-8">
//                     No blacklisted users found
//                   </p>
//                 ) : (
//                   userList.map((user, index) => (
//                     <BlacklistUser
//                       key={index}
//                       complaint={user.reports}
//                       username={user.Fullname}
//                       email={user.email}
//                     />
//                   ))
//                 )}
//               </div>
//             </div>
//           </div>
//         </div>
//       </div>
      
//       <FooterComponent />
//     </div>
//   );
// }

// export default Blacklist;

// ---- version 3 -----
import React, { useState } from "react";
import Header from "../component/Header";
import BlacklistUser from "../component/BlacklistUser";
import FooterComponent from "../component/Footer";
import BlacklistComponent from '../component/BlacklistComponent copy';
import { FaIndustry, FaUser } from "react-icons/fa";
import Background from '../assets/Images/blacklisted.jpg';
import { blacklistedCompanies, blacklistedUsers } from "../assets/data";

function Blacklist() {
  const [formData, setFormData] = useState({});
  const [companyList, setCompanyList] = useState(blacklistedCompanies);
  const [userList, setUsersList] = useState(blacklistedUsers);
  const [selectionError, setSelectionError] = useState(false);

  const handleSubmit = (e) => {
    e.preventDefault();
    const searchTable = formData.searchOption;
    if (!searchTable) {
      return setSelectionError(true);
    }
    const nameToSearch = formData.blacklistName.toLowerCase();
    if (searchTable === 'companies') {
      setCompanyList(blacklistedCompanies.filter(company => company.companyName.toLowerCase().includes(nameToSearch)));
    } else {
      setUsersList(blacklistedUsers.filter(user => user.Fullname.toLowerCase().includes(nameToSearch)));
    }
  };

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
    setCompanyList(blacklistedCompanies);
    setUsersList(blacklistedUsers);
    setSelectionError(false);
  };

  return (
    <div className="text-sm pt-16 sm:pt-20">
      <Header />
      <div className="mx-auto my-10 p-4 max-w-screen-xl">
        {/* Hero Section */}
        <div 
          style={{ backgroundImage: `url(${Background})` }} 
          className="flex flex-col items-center justify-center gap-6 p-8 rounded-lg bg-cover bg-center relative text-white shadow-md"
        >
          <div className="absolute inset-0 bg-black opacity-50 rounded-lg"></div>
          <div className="relative z-10 text-center">
            <h1 className="text-3xl font-semibold mb-4">Blacklist</h1>
            <p className="max-w-2xl mx-auto text-gray-200">
              At eJobs, we blacklist suspicious companies/users. When a company/user is added to the blacklist, it means that our safety and verification agents have marked them as not safe, trustworthy, or charged with fraudulent activities. There are several reasons why you can be flagged on our blacklist.
            </p>
          </div>
          {/* Search Form */}
          <form onSubmit={handleSubmit} className="relative z-10 w-full max-w-2xl">
            <div className="flex flex-col gap-4 mb-6">
              <div className="relative">
                <select
                  name="searchOption"
                  onChange={handleChange}
                  className="w-full p-3 pl-10 text-gray-700 bg-white border border-gray-300 rounded-lg appearance-none focus:outline-none focus:border-blue-500"
                >
                  <option value="">Select type</option>
                  <option value="companies">Companies</option>
                  <option value="users">Users</option>
                </select>
                <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                  <FaIndustry className="text-gray-500" />
                </div>
              </div>
              <div className="flex items-center border border-gray-300 rounded-lg overflow-hidden bg-white">
                <input
                  className="flex-1 p-3 text-gray-700 focus:outline-none"
                  type="text"
                  name="blacklistName"
                  placeholder="Enter name to search"
                  onChange={handleChange}
                />
                <button type="submit" className="bg-blue-500 text-white p-3 hover:bg-blue-600 transition-colors">
                  Search
                </button>
              </div>
            </div>
            {selectionError && <span className="block mt-2 text-red-500">Error: Please select a type</span>}
          </form>
        </div>

        {/* Blacklisted Companies and Users */}
        <div className="mt-10">
          <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
            <div>
              <h2 className="text-xl font-semibold mb-4 text-gray-800">Blacklisted Companies</h2>
              {companyList.map((company, index) => (
                <BlacklistComponent
                  key={index}
                  companyName={company.companyName}
                  companyAddress={company.companyAddress}
                  complaint={company.reports}
                />
              ))}
            </div>
            <div>
              <h2 className="text-xl font-semibold mb-4 text-gray-800">Blacklisted Users</h2>
              {userList.map((user, index) => (
                <BlacklistUser
                  key={index}
                  complaint={user.reports}
                  username={user.Fullname}
                  email={user.email}
                />
              ))}
            </div>
          </div>
        </div>
      </div>
      <FooterComponent />
    </div>
  );
}

export default Blacklist;