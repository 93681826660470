import React from "react";
import CvInfo from "./CvInfo";
import CvEducation from "./CvEducation";
import CvWorkExperience from "./CvWorkExperience";
import CvCertificate from "./CvCertificate";
import CvGuarantor from "./CvGuarantor";
import { useSelector } from "react-redux";
import PDFGenerator from "./pdf/PDFGenerator";
import { FaUser, FaGraduationCap, FaBriefcase, FaCertificate, FaUserShield } from 'react-icons/fa';

function CvTemplate1({ education, experience, certificate, guarantor }) {
  const loggedInUser = useSelector((state) => state.user);
  
  return (
    <PDFGenerator>
      <div className="bg-gradient-to-b from-green-50 to-green-100 min-h-[297mm] w-[210mm] mx-auto p-8 shadow-lg">
        {/* Header Section */}
        <div className="border-b border-green-200 pb-4">
          <div className="flex justify-between items-center">
            <div>
              <h1 className="text-3xl font-bold text-green-800">
                {`${loggedInUser?.currentUser?.firstName} ${loggedInUser.currentUser.lastName}`}
              </h1>
              <p className="text-green-600 mt-2">Professional CV</p>
            </div>
            <div className="text-right">
              <button className="px-4 py-2 rounded-lg bg-green-600 text-white hover:bg-green-700 transition-colors">
                e-verify log
              </button>
              <p className="text-sm text-green-600 mt-2">Verified Professional Profile</p>
            </div>
          </div>
        </div>

        {/* Personal Information Section */}
        <div className="mt-8">
          <div className="flex items-center gap-2 mb-4">
            <FaUser className="text-green-600" />
            <h2 className="text-xl font-semibold text-gray-800">Personal Information</h2>
          </div>
          <div className="bg-white rounded-lg p-4 shadow-sm">
            <div className="grid grid-cols-2 gap-4">
              <CvInfo title="Date of Birth" data={loggedInUser?.currentUser?.dateOfBirth} />
              <CvInfo title="Email" data={loggedInUser?.currentUser?.email} />
              <CvInfo title="Address" data={loggedInUser?.currentUser?.address} />
            </div>
          </div>
        </div>

        {/* Education Section */}
        <div className="mt-8">
          <div className="flex items-center gap-2 mb-4">
            <FaGraduationCap className="text-green-600" />
            <h2 className="text-xl font-semibold text-gray-800">Education Background</h2>
          </div>
          <div className="space-y-4">
            {education && education?.map((eachEducation, index) => (
              <div key={index} className="bg-white rounded-lg p-4 shadow-sm">
                <CvEducation
                  school={eachEducation?.school}
                  course={eachEducation?.course}
                  year={`${eachEducation?.startYear} - ${eachEducation?.finishYear}`}
                />
              </div>
            ))}
          </div>
        </div>

        {/* Work Experience Section */}
        <div className="mt-8">
          <div className="flex items-center gap-2 mb-4">
            <FaBriefcase className="text-green-600" />
            <h2 className="text-xl font-semibold text-gray-800">Work Experience</h2>
          </div>
          <div className="space-y-4">
            {experience && experience?.map((eachExperience, index) => (
              <div key={index} className="bg-white rounded-lg p-4 shadow-sm">
                <CvWorkExperience
                  title={eachExperience?.positionHeld}
                  companyName={eachExperience?.companyName}
                  year={`${eachExperience?.startYear} - ${eachExperience?.finishYear}`}
                  jobDescription={eachExperience?.jobDescription}
                />
              </div>
            ))}
          </div>
        </div>

        {/* Certificates Section */}
        <div className="mt-8">
          <div className="flex items-center gap-2 mb-4">
            <FaCertificate className="text-green-600" />
            <h2 className="text-xl font-semibold text-gray-800">Certificates</h2>
          </div>
          <div className="grid grid-cols-2 gap-4">
            {certificate && certificate?.map((eachCertificate, index) => (
              <div key={index} className="bg-white rounded-lg p-4 shadow-sm">
                <CvCertificate
                  title={eachCertificate?.certificateName}
                  year={eachCertificate?.issueDate}
                  serialNumber={eachCertificate?.serialNumber}
                  issueDate={eachCertificate?.issueDate}
                />
              </div>
            ))}
          </div>
        </div>

        {/* Guarantor Section */}
        <div className="mt-8">
          <div className="flex items-center gap-2 mb-4">
            <FaUserShield className="text-green-600" />
            <h2 className="text-xl font-semibold text-gray-800">Guarantor</h2>
          </div>
          <div className="grid grid-cols-2 gap-4">
            {guarantor && guarantor?.map((eachGuarantor, index) => (
              <div key={index} className="bg-white rounded-lg p-4 shadow-sm">
                <CvGuarantor
                  guarantorName={eachGuarantor?.name}
                  phoneNumber={eachGuarantor?.phone}
                />
              </div>
            ))}
          </div>
        </div>
      </div>
    </PDFGenerator>
  );
}

export default CvTemplate1;