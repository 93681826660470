import React from 'react'

const HiredListTable = ({hiredList}) => {
  return (
    <div className="bg-white shadow-sm rounded-lg p-6">
            <h2 className="text-lg font-semibold text-gray-900 mb-6">Hired Applicants</h2>
            <table className="min-w-full divide-y divide-gray-200">
              <thead className="bg-gray-50">
                <tr>
                  {["S/N", "Employee Name", "Position", "Date Employed", "Action"].map((header) => (
                    <th
                      key={header}
                      className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                    >
                      {header}
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody className="bg-white divide-y divide-gray-200">
                {hiredList?.map((employee, index) => (
                  <tr key={index}>
                    <td className="px-6 py-4 text-sm text-gray-500">{index + 1}</td>
                    <td className="px-6 py-4 text-sm text-gray-900 font-medium">
                      {employee.applicantName}
                    </td>
                    <td className="px-6 py-4 text-sm text-gray-500">{employee.position}</td>
                    <td className="px-6 py-4 text-sm text-gray-500">
                      {employee.dateOfEmployment}
                    </td>
                    <td className="px-6 py-4 text-sm">
                      <button className="px-4 py-2 bg-blue-600 text-white text-sm font-medium rounded-md hover:bg-blue-700 transition-colors">
                        Pay
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
  )
}

export default HiredListTable