import axios from 'axios'

const BASE_URL = process.env.REACT_APP_API_URL

export const axiosAuth = axios.create({
    baseURL: BASE_URL,
    headers: {
        'Content-Type': 'application/json'
    },
    timeout: 10000,
    // Add retry logic
    retry: 3,
    retryDelay: (retryCount) => {
        return retryCount * 1000; // time interval between retries
    }
});