import React, { useState } from 'react';
import { Card, CardHeader, CardTitle, CardContent } from './Card';
import { Dialog, DialogTitle } from './DialogBox';
import { FaPhone, FaPlus, FaUserCircle } from 'react-icons/fa';
import { CgMail } from 'react-icons/cg';
import { useMakeRequest } from '../../hooks/useMakeRequest';
import { GUARANTOR_URL } from '../../resources/resource';
import { useSelector } from 'react-redux';
import { Spinner } from 'flowbite-react';

const ContactList = ({userGuarantors, refetch}) => {
  
  const [isOpen, setIsOpen] = useState(false);
  const [loading, setLoading] = useState(false)
  const [deleteLoading, setDeleteLoading] = useState(false)
  const user = useSelector(state=>state.user?.currentUser)
  const makeRequest = useMakeRequest()
  const [formData, setFormData] = useState({
    name: "",
    address: "",
    email: "",
    phone: "",
  });

  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading(true)
    const onSuccess = () =>{
      refetch()
    }

    const onFailure = (error) =>{
      console.log(error)
    }

    const onFinal = () =>{
      setIsOpen(false)
      setLoading(false)
    }

    const payload = {...formData, user: user?._id}

    makeRequest(
      GUARANTOR_URL + '/createGuarantor',
      'POST',
      payload,
      onSuccess,
      onFailure,
      onFinal
    )
    setFormData({
      name: "",
      email: "",
      phone: "",
      address: ""
    });
    setIsOpen(false);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData(prev => ({
      ...prev,
      [name]: value
    }));
  };

  const handleDelete = (guarantorId)=>{
    setDeleteLoading(true)
    const onSuccess = (data)=>{
      refetch()
    }
    const onFailure = (error)=>{
      console.log(error)
    }
    const onFinal = ()=>{
      console.log()
      setDeleteLoading(false)
    }
    makeRequest(
      GUARANTOR_URL + `/deleteUserGuarantor/${guarantorId}`,
      'DELETE',
      {},
      onSuccess,
      onFailure,
      onFinal
    )
  }

  return (
    <div className="p-6 max-w-4xl mx-auto">
      <div className="flex justify-between items-center mb-6">
        <h2 className="text-2xl font-semibold">Guarantor</h2>
        <button
          onClick={() => setIsOpen(true)}
          className="flex items-center gap-2 px-4 py-2 bg-green-600 text-white rounded-md hover:bg-green-700 transition-colors"
        >
          <FaPlus className="w-4 h-4" />
          Add Guarantor
        </button>
      </div>

      <div className="space-y-4">
        {userGuarantors?.map((contact) => (
          <Card key={contact.id}>
            <CardHeader>
              <div className="flex justify-between items-start">
                <div>
                  <CardTitle>{contact?.name}</CardTitle>
                  {/* <div className="flex items-center gap-2 text-gray-600 mt-1">
                    <FaUserCircle className="w-4 h-4" />
                    {contact?.position} at {contact?.company}
                  </div> */}
                </div>
                {/* <div className="text-gray-600">
                  {contact?.relationship}
                </div> */}
              </div>
            </CardHeader>
            <CardContent>
              <div className="space-y-2 text-gray-600">
                <div className="flex items-center gap-2">
                  <CgMail className="w-4 h-4" />
                  {contact?.email}
                </div>
                <div className="flex items-center justify-between">
                  <div className='flex items-center gap-2'>
                    <FaPhone
                     className="w-4 h-4" />
                    {contact?.phone}
                   </div>
                  <button onClick={()=>{handleDelete(contact?._id)}} className='px-4 py-2 mt-2 rounded-md bg-red-300 text-white hover:bg-red-600'>
                    {deleteLoading ? <Spinner/> : 'Delete'}
                  </button>
                </div>
              </div>
            </CardContent>
          </Card>
        ))}
      </div>

      <Dialog isOpen={isOpen} onClose={() => setIsOpen(false)}>
        <DialogTitle>Add Guarantor</DialogTitle>
        <form onSubmit={handleSubmit} className="space-y-4">
          <div>
            <label className="block text-sm font-medium mb-1">Full Name</label>
            <input
              type="text"
              name="name"
              value={formData.name}
              onChange={handleInputChange}
              className="w-full p-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
              required
            />
          </div>
          <div>
            <label className="block text-sm font-medium mb-1">Email</label>
            <input
              type="email"
              name="email"
              value={formData.email}
              onChange={handleInputChange}
              className="w-full p-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
              required
            />
          </div>
          <div>
            <label className="block text-sm font-medium mb-1">Phone</label>
            <input
              type="tel"
              name="phone"
              value={formData.phone}
              onChange={handleInputChange}
              className="w-full p-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
              required
            />
          </div>
          <div>
            <label className="block text-sm font-medium mb-1">Address</label>
            <input
              type="text"
              name="address"
              value={formData.address}
              onChange={handleInputChange}
              className="w-full p-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
              required
            />
          </div>
          <div className="flex justify-end gap-4 mt-6">
            <button
              type="button"
              onClick={() => setIsOpen(false)}
              className="px-4 py-2 border rounded-md hover:bg-gray-100 transition-colors"
            >
              Cancel
            </button>
            <button
              type="submit"
              className="px-4 py-2 bg-green-600 text-white rounded-md hover:bg-green-700 transition-colors"
            >
              Add Guarantor
            </button>
          </div>
        </form>
      </Dialog>
    </div>
  );
};

export default ContactList;