// import React from "react";
// import CvInfo from "./CvInfo";
// import CvEducation from "./CvEducation";
// import CvWorkExperience from "./CvWorkExperience";
// import CvCertificate from "./CvCertificate";
// import CvGuarantor from "./CvGuarantor";
// import { useSelector } from "react-redux";
// import PDFGenerator from "./pdf/PDFGenerator";

// function CvTemplate2({ education, experience, certificate, guarantor }) {
//   const loggedInUser = useSelector(state=>state.user) 
//   return (
//     <PDFGenerator>

//     <div className="bg-black text-white p-8 w-5/6">
//       <div>
//         {/* -----------header section----------- */}
//         <div className="flex items-center justify-between">
//           <div>
//             <span className="text-xl font-semibold">e-Job CV</span>
//             <button className="px-2 rounded-xl bg-slate-600 ml-8">
//               e-verify log
//             </button>
//             <p className="mt-2">Verify and certify the best to the world</p>
//           </div>
//           <div>
//             <p className="font-semibold">Date Generated</p>
//             <h4>11-02-2024</h4>
//           </div>
//         </div>
//       </div>
//       {/* -----------Personal info section----------- */}
//       <div className="mt-16 ml-6">
//         <h3 className="text-xl">{`${loggedInUser.currentUser.firstName} ${loggedInUser.currentUser.lastName}`}</h3>
//         <CvInfo title="Date of Birth" data={loggedInUser.currentUser.dateOfBirth} />
//         <CvInfo title="Email" data={loggedInUser.currentUser.email} />
//         <CvInfo
//           title="Address"
//           data={loggedInUser.currentUser.address}
//         />
//       </div>
//       <div className="py-2 pl-6 pr-2 w-full bg-white text-black mt-8 rounded-tl-3xl">
//         <div className="">
//           <h3 className="text-slate-700 text-xl">Education Background</h3>
//           {education.map((eachEducation) => {
//             return (
//               <CvEducation
//                 school={eachEducation.school}
//                 course={eachEducation.course}
//                 year={`${eachEducation.startYear} ${eachEducation.finishYear}`}
//               />
//             );
//           })}
//         </div>
//         <div className="mt-8">
//           <h3 className="text-slate-700 mb-4 text-xl">Work Experience</h3>
//           {experience.map((eachExperience) => {
//             return (
//               <CvWorkExperience
//                 title={eachExperience.positionHeld}
//                 companyName={eachExperience.companyName}
//                 year={`${eachExperience.startYear} - ${eachExperience.finishYear}`}
//                 jobDescription={eachExperience.jobDescription}
//               />
//             );
//           })}
//         </div>
//         <div className="mt-8">
//           <h3 className="text-slate-700 mb-4 text-xl">Certificate</h3>
//           {certificate.map((eachCertificate) => {
//             return (
//               <CvCertificate
//                 title={eachCertificate.certificateName}
//                 year={eachCertificate.issueDate}
//               />
//             );
//           })}
//         </div>
//         <div className="mt-8">
//           <h3 className="text-slate-700 mb-4 text-xl">Guarantor</h3>
//           {guarantor.map((eachGuarantor) => {
//             return (
//               <CvGuarantor
//               guarantorName={eachGuarantor.guarantorName}
//               // guarantorCompany={eachGuarantor.}
//               phoneNumber={eachGuarantor.guarantorPhone}
//               />
//             );
//           })}
//         </div>
//       </div>
//     </div>
//     </PDFGenerator>
//   );
// }

// export default CvTemplate2;


// ------------ version 2 -------------
// import React from "react";
// import CvInfo from "./CvInfo";
// import CvEducation from "./CvEducation";
// import CvWorkExperience from "./CvWorkExperience";
// import CvCertificate from "./CvCertificate";
// import CvGuarantor from "./CvGuarantor";
// import { useSelector } from "react-redux";
// import PDFGenerator from "./pdf/PDFGenerator";

// function CvTemplate2({ education, experience, certificate, guarantor }) {
//   const loggedInUser = useSelector((state) => state.user);
//   return (
//     <PDFGenerator>
//       <div className="bg-gray-900 text-white p-8 w-5/6 mx-auto shadow-lg">
//         {/* Header Section */}
//         <div className="flex items-center justify-between mb-8">
//           <h1 className="text-3xl font-bold">e-Job CV</h1>
//           <button className="px-4 py-2 rounded-xl bg-gray-700">e-verify log</button>
//         </div>
//         <p className="text-center mb-8">Verify and certify the best to the world</p>

//         {/* Personal Info Section */}
//         <section className="mb-8">
//           <h2 className="text-2xl font-semibold border-b-2 border-gray-700 pb-2 mb-4">Personal Information</h2>
//           <CvInfo title="Name" data={`${loggedInUser.currentUser.firstName} ${loggedInUser.currentUser.lastName}`} />
//           <CvInfo title="Date of Birth" data={loggedInUser.currentUser.dateOfBirth} />
//           <CvInfo title="Email" data={loggedInUser.currentUser.email} />
//           <CvInfo title="Address" data={loggedInUser.currentUser.address} />
//         </section>

//         {/* Education Section */}
//         <section className="mb-8 bg-gray-800 p-6 rounded-lg">
//           <h2 className="text-2xl font-semibold border-b-2 border-gray-700 pb-2 mb-4">Education Background</h2>
//           {education.map((eachEducation, index) => (
//             <CvEducation key={index} school={eachEducation.school} course={eachEducation.course} year={`${eachEducation.startYear} - ${eachEducation.finishYear}`} />
//           ))}
//         </section>

//         {/* Work Experience Section */}
//         <section className="mb-8 bg-gray-800 p-6 rounded-lg">
//           <h2 className="text-2xl font-semibold border-b-2 border-gray-700 pb-2 mb-4">Work Experience</h2>
//           {experience.map((eachExperience, index) => (
//             <CvWorkExperience key={index} title={eachExperience.positionHeld} companyName={eachExperience.companyName} year={`${eachExperience.startYear} - ${eachExperience.finishYear}`} jobDescription={eachExperience.jobDescription} />
//           ))}
//         </section>

//         {/* Certificate Section */}
//         <section className="mb-8 bg-gray-800 p-6 rounded-lg">
//           <h2 className="text-2xl font-semibold border-b-2 border-gray-700 pb-2 mb-4">Certificates</h2>
//           {certificate.map((eachCertificate, index) => (
//             <CvCertificate key={index} title={eachCertificate.certificateName} year={eachCertificate.issueDate} />
//           ))}
//         </section>

//         {/* Guarantor Section */}
//         <section className="mb-8 bg-gray-800 p-6 rounded-lg">
//           <h2 className="text-2xl font-semibold border-b-2 border-gray-700 pb-2 mb-4">Guarantor</h2>
//           {guarantor.map((eachGuarantor, index) => (
//             <CvGuarantor key={index} guarantorName={eachGuarantor.guarantorName} phoneNumber={eachGuarantor.guarantorPhone} />
//           ))}
//         </section>
//       </div>
//     </PDFGenerator>
//   );
// }

// export default CvTemplate2;


// ------------ version 3 --------------
import React from "react";
import CvInfo from "./CvInfo";
import CvEducation from "./CvEducation";
import CvWorkExperience from "./CvWorkExperience";
import CvCertificate from "./CvCertificate";
import CvGuarantor from "./CvGuarantor";
import { useSelector } from "react-redux";
import PDFGenerator from "./pdf/PDFGenerator";
import { FaCalendar, FaCheck } from 'react-icons/fa';

function CvTemplate2({ education, experience, certificate, guarantor }) {
  const loggedInUser = useSelector(state => state.user);
  const currentDate = new Date().toLocaleDateString('en-GB');

  return (
    <PDFGenerator>
      <div className="bg-gradient-to-br from-gray-900 to-black text-white min-h-[297mm] w-[210mm] mx-auto">
        {/* Header Section */}
        <div className="bg-gradient-to-r from-gray-800 to-gray-900 p-8">
          <div className="flex items-center justify-between">
            <div>
              <div className="flex items-center gap-4">
                <h1 className="text-2xl font-bold">e-Job CV</h1>
                <button className="px-4 py-2 rounded-lg bg-gray-700 hover:bg-gray-600 transition-colors flex items-center gap-2">
                  <FaCheck className="text-green-400" />
                  e-verify log
                </button>
              </div>
              <p className="mt-2 text-gray-400">Verify and certify the best to the world</p>
            </div>
            <div className="text-right">
              <p className="text-gray-400 font-medium">Date Generated</p>
              <div className="flex items-center gap-2 text-gray-300">
                <FaCalendar className="text-gray-500" />
                <span>{currentDate}</span>
              </div>
            </div>
          </div>
        </div>

        {/* Personal Info Section */}
        <div className="px-8 py-12 border-l-4 border-gray-700 ml-8">
          <h2 className="text-3xl font-bold mb-6 text-gray-100">
            {`${loggedInUser.currentUser.firstName} ${loggedInUser.currentUser.lastName}`}
          </h2>
          <div className="grid grid-cols-2 gap-6 text-gray-300">
            <CvInfo title="Date of Birth" data={loggedInUser.currentUser.dateOfBirth} />
            <CvInfo title="Email" data={loggedInUser.currentUser.email} />
            <CvInfo title="Address" data={loggedInUser.currentUser.address} />
          </div>
        </div>

        {/* Main Content Section */}
        <div className="bg-white text-gray-800 rounded-tl-[4rem] px-8 py-12">
          {/* Education Section */}
          <div className="mb-12">
            <h3 className="text-xl font-semibold text-gray-800 mb-6 border-b-2 border-gray-200 pb-2">
              Education Background
            </h3>
            <div className="space-y-6">
              {education?.map((eachEducation, index) => (
                <div key={index} className="bg-gray-50 p-6 rounded-lg">
                  <CvEducation
                    school={eachEducation?.school}
                    course={eachEducation?.course}
                    year={`${eachEducation?.startYear} ${eachEducation?.finishYear}`}
                  />
                </div>
              ))}
            </div>
          </div>

          {/* Work Experience Section */}
          <div className="mb-12">
            <h3 className="text-xl font-semibold text-gray-800 mb-6 border-b-2 border-gray-200 pb-2">
              Work Experience
            </h3>
            <div className="space-y-6">
              {experience?.map((eachExperience, index) => (
                <div key={index} className="bg-gray-50 p-6 rounded-lg">
                  <CvWorkExperience
                    title={eachExperience?.positionHeld}
                    companyName={eachExperience?.companyName}
                    year={`${eachExperience?.startYear} - ${eachExperience?.finishYear}`}
                    jobDescription={eachExperience?.jobDescription}
                  />
                </div>
              ))}
            </div>
          </div>

          {/* Certificates Section */}
          <div className="mb-12">
            <h3 className="text-xl font-semibold text-gray-800 mb-6 border-b-2 border-gray-200 pb-2">
              Certificates
            </h3>
            <div className="grid grid-cols-2 gap-6">
              {certificate?.map((eachCertificate, index) => (
                <div key={index} className="bg-gray-50 p-6 rounded-lg">
                  <CvCertificate
                    title={eachCertificate?.certificateName}
                    year={eachCertificate?.issueDate}
                    serialNumber={eachCertificate?.serialNumber}
                  />
                </div>
              ))}
            </div>
          </div>

          {/* Guarantor Section */}
          <div>
            <h3 className="text-xl font-semibold text-gray-800 mb-6 border-b-2 border-gray-200 pb-2">
              Guarantor
            </h3>
            <div className="grid grid-cols-2 gap-6">
              {guarantor.map((eachGuarantor, index) => (
                <div key={index} className="bg-gray-50 p-6 rounded-lg">
                  <CvGuarantor
                    guarantorName={eachGuarantor.name}
                    phoneNumber={eachGuarantor.phone}
                  />
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </PDFGenerator>
  );
}

export default CvTemplate2;