import JobChoice from "./JobChoice";
import { jobCategories } from "../assets/data";
import { useState, useRef } from "react";
import { toast } from "sonner";
import LoadSpinner from "./Modals/LoadSpinner";
import { Button } from "flowbite-react";
import VerifyEmail from '../component/Modals/VerifyEmail'
import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
// import { setRegistrationData } from "../Utils/localStorage";
import { useSelector } from "react-redux";
// import { addData, setData } from "../redux/register/registerSlice";
import { useMakeRequest } from "../hooks/useMakeRequest";
import { EDIRECT_API, SIGNUP_API } from "../resources/resource";

// Updated Yup Schema
const stepFourSchema = yup.object().shape({
  firstChoice: yup.string().required('First job choice is required'),
  secondChoice: yup.string().required('Second job choice is required'),
  thirdChoice: yup.string().required('Third job choice is required'),
  jobCategories: yup.array()
    .of(yup.string())
    .min(1, 'Please select at least one job category')
    .max(3, 'You can select up to 3 job categories')
});

const StepFour = ({ next, prev, formData, setFormData }) => {
  const [loading, setLoading] = useState(false);
  // const data = useSelector((state)=>state.registerData)
  const makeRequest = useMakeRequest()
  const emailRef = useRef(null);
  const registrationData = useSelector(state=>state.registerData)
  


  const { 
    control, 
    register, 
    handleSubmit, 
    setValue, 
    watch, 
    formState: { errors } 
  } = useForm({
    resolver: yupResolver(stepFourSchema),
    defaultValues: {
      firstChoice: '',
      secondChoice: '',
      thirdChoice: '',
      jobCategories: []
    }
  });

  const watchJobCategories = watch('jobCategories');

  const handleCategorySelection = (category) => {
    const currentCategories = watchJobCategories || [];
    const newCategories = currentCategories.includes(category)
      ? currentCategories.filter(item => item !== category)
      : [...currentCategories, category];

    setValue('jobCategories', newCategories, { 
      shouldValidate: true 
    });
  };

  const onSubmit = async (data) => {
    setLoading(true);
    try {
      const submissionData = {
        ...registrationData,
        ...data,
        interestedJob: [data.firstChoice, data.secondChoice, data.thirdChoice].filter(Boolean)
      };

      const onSuccess = (data)=>{

          emailRef.current.click();
      }

      const onFailure = (error)=>{
        toast.error(error?.response?.data?.message || 'unknown erorr please try again')
      }

      makeRequest(
        SIGNUP_API + '/signup',
        'POST',
        submissionData,
        onSuccess,
        onFailure,
        ()=>{setLoading(false)}
      )

      const {email, password, firstName:name, password:password_confirmation, ...otherData} = submissionData

      const edirectData = {
        email,
        password,
        name,
        password_confirmation,
        role: "user"
      }
      
      await fetch(EDIRECT_API, {
        method: "POST",
        headers: {
          "Content-Type": "application/json"
        },
        body: JSON.stringify({...edirectData})
      })
      .then(data=>{
        console.log('data -->', data)
        data?.ok ? toast.success('Success') : toast.error('Error, try again')
      })      

      // const response = await axios.post(baseURL, submissionData);
      // if (response.request.status === 201) {
      //   toast.success("Signup successful");
      //   emailRef.current.click();
      // } else {
      //   toast.error("Error creating user, try again");
      //   console.log('Error --->', response.data.message)
      // }
    } catch (error) {
      toast.error("Signup error");
      // setError(error.response?.data?.message || "An error occurred");
    }finally{
      setLoading(false)
    }
  };

  return (
    <div className="p-5 flex items-center justify-center font-mon">
      <form onSubmit={handleSubmit(onSubmit)} className="relative sm:mt-0 mt-10 sm:w-2/3 w-full bg-white p-8 rounded-lg shadow-lg text-center">
        <h3 className="text-2xl my-4 text-green-800">What kind of job are you searching for?</h3>
        <p className="sm:text-sm text-xs px-2 my-6 font-semibold text-gray-600">
          Why are we asking this of you? So we can narrow down job openings
          specifically for you. If your preferred job doesn't come in
          time, you will have other options to fall back on.
        </p>

        <div className="flex flex-wrap items-center justify-evenly my-6">
          <JobChoice
            setUserChoice={(value) => setValue('firstChoice', value)}
            register={register}
            label="First Choice"
            placeholder="General Manager"
            name='firstChoice'
            error={errors.firstChoice}
          />
          <JobChoice
            setUserChoice={(value) => setValue('secondChoice', value)}
            register={register}
            label="Second Choice"
            placeholder="Assistant Manager"
            name='secondChoice'
            error={errors.secondChoice}
          />
          <JobChoice
            setUserChoice={(value) => setValue('thirdChoice', value)}
            register={register}
            label="Third Choice"
            placeholder="Team Lead"
            name='thirdChoice'
            error={errors.thirdChoice}
          />
        </div>

        <h3 className="sm:text-left text-center font-semibold my-4 text-green-800">
          Which kind of job are you interested in?
        </h3>

        <div className="flex items-center justify-center sm:w-full w-4/5 mx-auto flex-wrap gap-4">
          {jobCategories.map((category) => (
            <div key={category} className="flex items-center">
              <input
                type="checkbox"
                id={category}
                checked={watchJobCategories?.includes(category)}
                onChange={() => handleCategorySelection(category)}
                className="hidden"
              />
              <label 
                htmlFor={category}
                className={`text-sm py-1 px-6 cursor-pointer rounded-full border-2 transition-colors duration-200 
                  ${watchJobCategories?.includes(category) 
                    ? 'bg-green-500 text-white border-green-500' 
                    : 'text-green-500 bg-white border-green-500 hover:bg-green-500 hover:text-white'
                  }
                `}
              >
                {category}
              </label>
            </div>
          ))}
        </div>

        {errors.jobCategories && (
          <span className="block p-2 text-red-600 bg-red-100 mt-3 rounded">
            {errors.jobCategories.message}
          </span>
        )}

        <Button
          type="submit"
          className="text-white hover:bg-green-600 bg-green-500 my-5 w-full py-1 rounded-md"
          disabled={loading}
        >
          {loading ? <LoadSpinner /> : "Submit"}
        </Button>
      </form>

      <VerifyEmail 
        userEmail={registrationData?.email} 
        userType={registrationData?.userType} 
        ref={emailRef}
      />
    </div>
  );
};

export default StepFour;
