// ----------------Version 2----------------
import React, { useState } from 'react';
import { BsFire } from 'react-icons/bs';
import { Link } from 'react-router-dom';
import { Card } from '../pages/Profile/Card';
import { FaChevronDown, FaChevronUp } from 'react-icons/fa';

const MessageInfo = ({ data, userType }) => {
  const [messageOpen, setMessageOpen] = useState(false);

  const ownerMessage = `You have accepted ${data?.recipient?.firstName} ${data?.recipient?.lastName}. Please click the link below to schedule an interview. This candidate has shown promising skills and qualifications that match our requirements.`;

  const rejected = `You have rejected the application of ${data?.recipient?.firstName} ${data?.recipient?.lastName}. This decision was made after careful consideration of their qualifications and experience.`;
  
  const applicantMessage = `Congratulations! Your application to ${data?.sender?.companyName} has progressed to the next stage. We were impressed with your skills and experience.`;
  
  const applicantRejection = `We regret to inform you that your application to ${data?.sender?.companyName} has been rejected. This decision was based on a thorough evaluation of your qualifications and our current requirements.`;
  
  const ownerOffer = `You have made an offer to ${data?.recipient?.firstName} ${data?.recipient?.lastName}, which is currently pending their response.`;
  
  const recipientOffer = `You have received an offer from ${data?.sender?.companyName}. Please review the offer details carefully and respond at your earliest convenience.`;

  const messageForEmployer =
    data?.message === 'Congratulations' ? ownerMessage :
    data?.message === 'Offer letter' ? ownerOffer : rejected;

  const messageForSeekers =
    data?.message === 'Congratulations' ? applicantMessage :
    data?.message === 'Offer letter' ? recipientOffer :
    applicantRejection;

  const getStatusColor = (message) => {
    switch (message) {
      case 'Congratulations':
        return 'bg-green-100 text-green-800';
      case 'Offer letter':
        return 'bg-blue-100 text-blue-800';
      default:
        return 'bg-red-100 text-red-800';
    }
  };

  return (
    <div className="w-full max-w-2xl">
      <Card className={`${!data.seen && 'border-l-4 border-l-blue-500'}`}>
        <div className="p-4">
          <div className="flex items-center justify-between">
            <div className="flex items-center gap-3">
              <div className={`p-2 rounded-full ${getStatusColor(data.message)}`}>
                <BsFire className="w-4 h-4" />
              </div>
              <div>
                <h3 className="font-semibold text-gray-900">{data.sender.companyName}</h3>
                <p className="text-sm text-gray-500">From: {data.senderName}</p>
              </div>
            </div>
            <button
              onClick={() => setMessageOpen(!messageOpen)}
              className="flex items-center gap-2 px-3 py-1.5 text-sm rounded-full bg-gray-100 hover:bg-gray-200 transition-colors"
            >
              {messageOpen ? (
                <>
                  Close <FaChevronUp className="w-4 h-4" />
                </>
              ) : (
                <>
                  View <FaChevronDown className="w-4 h-4" />
                </>
              )}
            </button>
          </div>

          {messageOpen && (
            <div className="mt-4 space-y-4">
              <div className="bg-gray-50 rounded-lg p-4 text-gray-600">
                {userType === 'jobEmployer' ? messageForEmployer : messageForSeekers}
              </div>

              {userType === 'jobEmployer' && data.message === 'Congratulations' && (
                <Link
                  to={`/${data.application.job}/jobApplicants`}
                  className="inline-flex items-center px-4 py-2 text-sm font-medium text-white bg-blue-600 rounded-lg hover:bg-blue-700 transition-colors"
                >
                  Schedule Interview
                </Link>
              )}

              {userType === 'jobSeeker' && data.message === 'Offer letter' && (
                <Link
                  to={`/offer/${data.application.jobOffers[data.application.jobOffers?.length - 1]}/${data.application._id}`}
                  className="inline-flex items-center px-4 py-2 text-sm font-medium text-white bg-blue-600 rounded-lg hover:bg-blue-700 transition-colors"
                >
                  View Offer
                </Link>
              )}
            </div>
          )}
        </div>
      </Card>
    </div>
  );
}

export default MessageInfo;
