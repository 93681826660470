import CvTemplate1 from "../component/CvTemplate1";
import CvTemplate2 from "../component/CvTemplate2";
import CvTemplate3 from "../component/CvTemplate3";
import { usePDF } from "react-to-pdf";
import { JOBSEEKER_URL } from "../resources/resource";
import { useFetchData } from "../hooks/useFtechData";

import { Link } from "react-router-dom";
import FooterComponent from "../component/Footer";
import Header from "../component/Header";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";


function TemplateCV() {
  const [shareOption, setShare] = useState(false);
  const [templateNumber, setTemplateNumber] = useState(1);
  const loggedInUser = useSelector((state) => state.user);
  const { toPDF, templateRef: pdfTargetRef } = usePDF({filename: `Template ${templateNumber}`});

  const { data: userDetails, refetch: refetchUser } = useFetchData(
    JOBSEEKER_URL + `/getAjobSeeker/${loggedInUser?.currentUser?._id}`
  );

  // Create a function to render the current template
  const renderTemplate = (templateNum) => {
    const commonProps = {
      download: toPDF,
      education: userDetails?.education || [],
      experience: userDetails?.workExperience || [],
      certificate: userDetails?.certificates || [], // Note: Changed to match the data structure
      guarantor: userDetails?.guarantors || [], // Note: Changed to match the data structure
    };

    switch (templateNum) {
      case 1:
        return <CvTemplate1 {...commonProps} />;
      case 2:
        return <CvTemplate2 {...commonProps} />;
      case 3:
        return <CvTemplate3 {...commonProps} />;
      default:
        return <CvTemplate1 {...commonProps} />;
    }
  };

  // Set initial template after data is loaded
  useEffect(() => {
    if (userDetails) {
      setTemplate(renderTemplate(templateNumber));
    }
  }, [userDetails, templateNumber]);

  const [template, setTemplate] = useState(() => renderTemplate(1));

  const handleTemplate = (num) => {
    setTemplateNumber(num);
    setTemplate(renderTemplate(num));
  };

  const handleShare = () => {
    setShare(!shareOption);
  };

  const handleBody = () => {
    shareOption === true && setShare(false);
  };

  // Show loading state while data is being fetched
  if (!userDetails) {
    return (
      <div className="text-sm pt-16 sm:pt-20 flex items-center justify-center min-h-screen">
        <p>Loading your CV data...</p>
      </div>
    );
  }

  return (
    <div onClick={handleBody} className="text-sm pt-16 sm:pt-20">
      <div className="sm:px-24 px-4">
        <Header />
        <div className="mt-6 flex items-center justify-between">
          <div>
            <h3 className="font-semibold inline-block mr-8">Download CV</h3>
            <p className="inline-block">
              You can choose from the 3 template which best goes with your taste
              and download it
            </p>
          </div>
        </div>
        <div className="flex items-start justify-start gap-12 mt-16">
          <div className="w-1/6 h-auto">
            {[1, 2, 3].map((num) => (
              <button
                key={num}
                onClick={() => handleTemplate(num)}
                className={`hover:bg-green-500 block py-1 px-4 text-slate-50 rounded-md mb-4 ${
                  templateNumber === num ? "bg-green-500" : "bg-slate-800"
                }`}
              >
                Template {num}
              </button>
            ))}
            <Link 
              className='bg-red-500 px-4 py-2 text-white rounded-sm' 
              to={`/profile/${loggedInUser.currentUser._id}`}
            >
              Profile
            </Link>
          </div>
          {/* template region */}
          <div className="w-5/6" ref={pdfTargetRef}>
            {template}
          </div>
        </div>
      </div>
      <FooterComponent />
    </div>
  );
}

export default TemplateCV