import React from 'react'
import Background from "../assets/Images/hiring.jpg"
import Header from '../component/Header'

const Company = () => {
  return (
    <div>
        <div
        className="h-72 relative text-sm after:absolute after:bg-black after:z-10 after:top-0 after:left-0 after:w-full after:h-full after:opacity-55"
        // className="h-72 relative sm:px-5 px-4 after:absolute after:bg-black after:z-10 after:top-0 after:left-0 after:w-full after:h-full after:opacity-55"
        style={{
          background: `url(${Background})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
        }}
        >
        <Header lightMode={true}/>
        <h2 className="z-20 text-4xl absolute top-1/2 left-1/2 text-white font-semibold">
          Contact us
        </h2>
        </div>
        <div className="w-full mx-auto mb-20">
        <div className="my-10 mx-auto gap-10 flex sm:flex-row flex-col items-center justify-center">
          <div className="sm:w-1/2 w-full">
            <h2 className="text-lg font-semibold mb-3">We priotize you</h2>
            <p className="text-sm">
            We are here to assist you with all your job needs. Whether you’re looking to hire professionals or to get your dream job, don’t hesitate to reach out to us. Visit our website, give us a call, or send us an email.<br/> 
            <span className='font-semibold'>Tel:</span> 07059992298<br/>
            <span className='font-semibold'>Email:</span> essentialnigeria519@gmail.com<br/>
            <span className='font-semibold'>Website URL:</span> www.ejobs.com.ng<br/>
            </p>
            <p className='mt-3'>Thank you for choosing ejobs.</p>
          </div>
        </div>
        </div>
    </div>
  )
}

export default Company