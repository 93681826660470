import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
// import { toast } from "react-toastify";
import { CgProfile } from "react-icons/cg";
import { BiMenu } from "react-icons/bi";
import { IoClose } from "react-icons/io5";
import { MdKeyboardArrowDown } from "react-icons/md";
import Logo from "../assets/Images/hotel-logo.png";
import { signoutUser } from "../redux/users/userSlice";
import { toast } from 'sonner';

const MENU_ITEMS = [
  { label: "Home", link: "/" },
  { label: "Contacts", link: "/company" },
  { label: "Jobs", link: "/jobs" },
  { label: "Blacklist", link: "/blacklist" },
  { label: "Company", link: "/privacy" },
  { label: "Blogs", link: "/blog" },
  { label: "eJA", link: "/eja" }
];

const Header = ({ lightMode = false }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { currentUser } = useSelector((state) => state.user);
  
  const [isScrolled, setIsScrolled] = useState(false);
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const [isProfileDropdownOpen, setIsProfileDropdownOpen] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      setIsScrolled(window.scrollY > 10);
    };
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  const handleSignOut = () => {
    dispatch(signoutUser());
    navigate('/signin');
    setIsMobileMenuOpen(false);
    setIsProfileDropdownOpen(false);
  };

  const handleBenefits = () => {
    if (!currentUser) {
      toast.info('Sign in to continue')
      return navigate('/signin');
    }
    if (currentUser.userType === 'jobSeeker') {
      navigate('/benefit');
    } else if (currentUser.userType === 'jobEmployer') {
      toast.info('Not eligible');
    }
    setIsMobileMenuOpen(false);
  };

  return (
    <header 
      className={`
        fixed top-0 left-0 right-0 z-50 transition-all duration-200
        ${isScrolled ? 'bg-white shadow-md' : 'bg-transparent'}
        ${lightMode && !isScrolled ? 'text-white' : 'text-gray-800 '}
      `}
    >
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="flex items-center justify-between h-16 sm:h-20">
          {/* Logo */}
          <Link to="/" className="font-mon font-bold flex items-center justify-center text-2xl text-green-400 hover:text-green-600">
            <img 
              src={Logo} 
              alt="Essential Jobs Nigeria" 
              className="h-8 sm:h-10 w-auto"
            />
            EHotel-Jobs
          </Link>

          {/* Desktop Navigation */}
          <nav className="hidden lg:flex items-center space-x-1">
            {MENU_ITEMS.map((item) => (
              <Link
                key={item.label}
                to={item.link}
                className="px-3 py-2 rounded-md text-sm font-medium hover:bg-gray-100 transition-colors"
              >
                {item.label}
              </Link>
            ))}
            <button
              onClick={handleBenefits}
              className="px-3 py-2 rounded-md text-sm font-medium hover:bg-gray-100 transition-colors"
            >
              Benefits
            </button>
          </nav>

          {/* Desktop User Menu */}
          <div className="hidden lg:flex items-center space-x-4">
            {currentUser ? (
              <div className="relative">
                <button
                  onClick={() => setIsProfileDropdownOpen(!isProfileDropdownOpen)}
                  className="flex items-center space-x-2 px-3 py-2 rounded-md hover:bg-gray-100 transition-colors"
                >
                  <CgProfile className="w-5 h-5" />
                  <span className="text-sm font-medium">Account</span>
                  <MdKeyboardArrowDown className={`w-5 h-5 transition-transform ${isProfileDropdownOpen ? 'rotate-180' : ''}`} />
                </button>

                {/* Profile Dropdown */}
                {isProfileDropdownOpen && (
                  <div className="absolute right-0 mt-2 w-48 bg-white rounded-md shadow-lg py-1 ring-1 ring-black ring-opacity-5">
                    <Link
                      to={currentUser.userType === 'jobSeeker' ? `/profile/${currentUser._id}` : `/employer/${currentUser._id}`}
                      className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
                    >
                      Profile
                    </Link>
                    {currentUser.userType === 'admin' && (
                      <Link
                        to="/dashboard"
                        className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
                      >
                        Dashboard
                      </Link>
                    )}
                    <Link
                      to={`/${currentUser._id}/notification`}
                      className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
                    >
                      Notifications
                    </Link>
                    <button
                      onClick={handleSignOut}
                      className="block w-full text-left px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
                    >
                      Sign Out
                    </button>
                  </div>
                )}
              </div>
            ) : (
              <Link
                to="/signin"
                className="px-4 py-2 rounded-md text-sm font-medium bg-blue-600 text-white hover:bg-blue-700 transition-colors"
              >
                Sign In
              </Link>
            )}
          </div>

          {/* Mobile Menu Button */}
          <button
            onClick={() => setIsMobileMenuOpen(!isMobileMenuOpen)}
            className="lg:hidden p-2 rounded-md hover:bg-gray-100 transition-colors"
          >
            {isMobileMenuOpen ? (
              <IoClose className="w-6 h-6" />
            ) : (
              <BiMenu className="w-6 h-6" />
            )}
          </button>
        </div>
      </div>

      {/* Mobile Menu */}
      {isMobileMenuOpen && (
        <div className="lg:hidden">
          <div className="px-2 pt-2 pb-3 space-y-1 bg-white shadow-lg">
            {MENU_ITEMS.map((item) => (
              <Link
                key={item.label}
                to={item.link}
                onClick={() => setIsMobileMenuOpen(false)}
                className="block px-3 py-2 rounded-md text-base font-medium hover:bg-gray-100 transition-colors"
              >
                {item.label}
              </Link>
            ))}
            <button
              onClick={handleBenefits}
              className="block w-full text-left px-3 py-2 rounded-md text-base font-medium hover:bg-gray-100 transition-colors"
            >
              Benefits
            </button>

            {currentUser ? (
              <>
                <div className="border-t border-gray-200 pt-4 pb-3">
                  <div className="px-3 space-y-1">
                    <Link
                      to={currentUser.userType === 'jobSeeker' ? `/profile/${currentUser._id}` : `/employer/${currentUser._id}`}
                      onClick={() => setIsMobileMenuOpen(false)}
                      className="block px-3 py-2 rounded-md text-base font-medium hover:bg-gray-100"
                    >
                      Profile
                    </Link>
                    {currentUser.userType === 'admin' && (
                      <Link
                        to="/dashboard"
                        onClick={() => setIsMobileMenuOpen(false)}
                        className="block px-3 py-2 rounded-md text-base font-medium hover:bg-gray-100"
                      >
                        Dashboard
                      </Link>
                    )}
                    <Link
                      to={`/${currentUser._id}/notification`}
                      onClick={() => setIsMobileMenuOpen(false)}
                      className="block px-3 py-2 rounded-md text-base font-medium hover:bg-gray-100"
                    >
                      Notifications
                    </Link>
                    <button
                      onClick={handleSignOut}
                      className="block w-full text-left px-3 py-2 rounded-md text-base font-medium hover:bg-gray-100"
                    >
                      Sign Out
                    </button>
                  </div>
                </div>
              </>
            ) : (
              <Link
                to="/signin"
                onClick={() => setIsMobileMenuOpen(false)}
                className="block w-full text-center px-3 py-2 rounded-md text-base font-medium bg-blue-600 text-white hover:bg-blue-700 transition-colors"
              >
                Sign In
              </Link>
            )}
          </div>
        </div>
      )}
    </header>
  );
};

export default Header;