import { BsTwitter } from "react-icons/bs";
import { FaFacebookF, FaGoogle, FaInstagram } from "react-icons/fa";
import Resource from "../Resource";
import { PiGreaterThanBold } from "react-icons/pi";
import { MdFavorite } from "react-icons/md";

export const SocialLinks = () => {
    const socialLinks = [
      { Icon: FaFacebookF, href: "#", label: "Facebook" },
      { Icon: BsTwitter, href: "#", label: "Twitter" },
      { Icon: FaInstagram, href: "#", label: "Instagram" },
      { Icon: FaGoogle, href: "#", label: "Google" }
    ];
  
    return (
      <div className="flex gap-4">
        {socialLinks.map(({ Icon, href, label }) => (
          <a
            key={label}
            href={href}
            aria-label={label}
            className="text-gray-300 hover:text-blue-400 transition-colors duration-300"
          >
            <Icon className="w-6 h-6" />
          </a>
        ))}
      </div>
    );
  };


export const FooterLinkSection = ({ title, links }) => (
    <div className="flex flex-col">
      <h2 className="text-lg font-semibold mb-4">{title}</h2>
      {links.map((link) => (
        <Resource key={link} icon={<PiGreaterThanBold />} title={link} />
      ))}
    </div>
  );


export const Copyright = () => (
    <div className="text-center mt-8 md:mt-0">
      <h3 className="text-sm flex items-center justify-center gap-1">
        &copy; Designed with <MdFavorite className="text-pink-500" /> by Essential Group {new Date().getFullYear()}.
      </h3>
    </div>
  );