// import React from 'react'

// const Employment = () => {
//   return (
//         <div className="bg-white shadow rounded-lg p-6 mb-8 mx-10 my-10">
//           <div className="w-full flex items-center justify-between mb-2 text-lg font-semibold">
//             <div className="flex items-center justify-center gap-5">
//               <span className="">Employment Record</span>
//             </div>
//           </div>

//           {/* ------------Active Hired Applicant------------- */}
//           <div className="mt-5 overflow-auto">
//             <table className="min-w-full divide-y divide-gray-200">
//               <thead>
//                 <tr>
//                   <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">S/N</th>
//                   <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Company Name</th>
//                   <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Company Address</th>
//                   <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Position</th>
//                   <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Date Applied</th>
//                   <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Application Status</th>
//                   <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Action</th>
//                 </tr>
//               </thead>
//               <tbody>

//               {userApplication && userApplication.map((applicationData, index)=>{
//                 return(
//                 applicationData.status === 'Hired' && <tr key={index} className="cursor-pointer hover:bg-gray-200 hover:text-white">
//                   <td className="px-6 py-4 text-sm text-gray-500">{index + 1}</td>
//                   <td className="px-6 py-4 text-sm text-gray-500">{applicationData.companyId?.companyName}</td>
//                   <td className="px-6 py-4 text-sm text-gray-500">{applicationData.companyAddress}</td>
//                   <td className="px-6 py-4 text-sm text-gray-500">{applicationData.position}</td>
//                   <td className="px-6 py-4 text-sm text-gray-500">{applicationData.createdAt}</td>
//                   <td className="px-6 py-4 text-sm text-gray-500">{applicationData.status}</td>
//                   <td className="px-6 py-4 text-sm text-gray-500"><buton className='px-4 py-2 bg-green-500 rounded-sm text-white' onClick={()=>{
//                     setReportForm({...reportForm, employer: applicationData.companyId})
//                     handleReport();
//                   }}>Report</buton></td>
//                 </tr>
//                 )
//               })}
//               </tbody>
//             </table>
//           </div>
//           {startReport && <ReportModal send={handleSendReport} change={handleReportForm} toggle={handleReport}/>}
//         </div>
//   )
// }

// export default Employment

// ------ version 2 -------

import React, { useState } from 'react';
import {
  Card,
  CardHeader,
  CardTitle,
  CardContent,
} from "./Card";
import { Dialog, DialogTitle } from './DialogBox';
import { MdBorderHorizontal } from 'react-icons/md';
import { TbBuildingFactory2 } from 'react-icons/tb';
import { FaCalendar, FaMapPin } from 'react-icons/fa';

const EmploymentRecord = ({ employmentHistory = [] }) => {
  const [selectedJob, setSelectedJob] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleCloseModal = () => {
    setIsModalOpen(false);
    setSelectedJob(null);
  };

  const getEmploymentTypeColor = (type) => {
    switch (type.toLowerCase()) {
      case 'full-time':
        return 'bg-green-100 text-green-800';
      case 'part-time':
        return 'bg-blue-100 text-blue-800';
      case 'contract':
        return 'bg-purple-100 text-purple-800';
      case 'internship':
        return 'bg-green-100 text-green-800';
      default:
        return 'bg-gray-100 text-gray-800';
    }
  };

  const formatDate = (date) => {
    return new Date(date).toLocaleDateString('en-US', {
      year: 'numeric',
      month: 'short'
    });
  };

  const calculateDuration = (startDate, endDate) => {
    const start = new Date(startDate);
    const end = endDate ? new Date(endDate) : new Date();
    const months = (end.getFullYear() - start.getFullYear()) * 12 + 
                  (end.getMonth() - start.getMonth());
    
    const years = Math.floor(months / 12);
    const remainingMonths = months % 12;
    
    if (years === 0) {
      return `${remainingMonths} month${remainingMonths !== 1 ? 's' : ''}`;
    } else if (remainingMonths === 0) {
      return `${years} year${years !== 1 ? 's' : ''}`;
    } else {
      return `${years} year${years !== 1 ? 's' : ''}, ${remainingMonths} month${remainingMonths !== 1 ? 's' : ''}`;
    }
  };

  return (
    <Card className="w-full">
      <CardHeader>
        <CardTitle className="text-xl font-semibold">Employment Record</CardTitle>
      </CardHeader>
      <CardContent>
        <div className="relative overflow-x-auto rounded-lg border border-gray-200">
          <table className="w-full text-sm text-left">
            <thead className="text-xs uppercase bg-gray-50">
              <tr>
                <th className="px-6 py-3 font-medium text-gray-500">Company</th>
                <th className="px-6 py-3 font-medium text-gray-500">Position</th>
                <th className="px-6 py-3 font-medium text-gray-500">Duration</th>
                <th className="px-6 py-3 font-medium text-gray-500">Type</th>
                <th className="px-6 py-3 font-medium text-gray-500 w-[100px]">Actions</th>
              </tr>
            </thead>
            <tbody className="divide-y divide-gray-200">
              {employmentHistory.map((job, index) => (
                <tr key={index} className="bg-white hover:bg-gray-50 transition-colors">
                  <td className="px-6 py-4">
                    <div>
                      <div className="font-semibold">{job.companyName}</div>
                      <div className="text-sm text-gray-500">{job.location}</div>
                    </div>
                  </td>
                  <td className="px-6 py-4">{job.position}</td>
                  <td className="px-6 py-4">
                    <div className="text-sm">
                      {formatDate(job.startDate)} - {job.endDate ? formatDate(job.endDate) : 'Present'}
                      <div className="text-gray-500">
                        {calculateDuration(job.startDate, job.endDate)}
                      </div>
                    </div>
                  </td>
                  <td className="px-6 py-4">
                    <span className={`inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium ${getEmploymentTypeColor(job.employmentType)}`}>
                      {job.employmentType}
                    </span>
                  </td>
                  <td className="px-6 py-4">
                    <button
                      onClick={() => {
                        setSelectedJob(job);
                        setIsModalOpen(true);
                      }}
                      className="p-2 hover:bg-gray-100 rounded-full transition-colors"
                    >
                      <MdBorderHorizontal className="w-4 h-4" />
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>

        <Dialog isOpen={isModalOpen} onClose={handleCloseModal}>
          {selectedJob && (
            <>
              <DialogTitle>Employment Details</DialogTitle>
              <div className="space-y-6">
                <div>
                  <h3 className="font-semibold text-lg">{selectedJob.position}</h3>
                  <div className="flex items-center gap-2 text-gray-500 mt-1">
                    <TbBuildingFactory2 className="w-4 h-4" />
                    <span>{selectedJob.companyName}</span>
                  </div>
                  <div className="flex items-center gap-2 text-gray-500 mt-1">
                    <FaMapPin className="w-4 h-4" />
                    <span>{selectedJob.location}</span>
                  </div>
                  <div className="flex items-center gap-2 text-gray-500 mt-1">
                    <FaCalendar className="w-4 h-4" />
                    <span>
                      {formatDate(selectedJob.startDate)} - {selectedJob.endDate ? formatDate(selectedJob.endDate) : 'Present'}
                      {' '}· {calculateDuration(selectedJob.startDate, selectedJob.endDate)}
                    </span>
                  </div>
                </div>

                <div>
                  <h4 className="font-medium mb-2">Responsibilities & Achievements</h4>
                  <ul className="list-disc list-inside space-y-1 text-gray-600">
                    {selectedJob.responsibilities?.map((responsibility, index) => (
                      <li key={index}>{responsibility}</li>
                    ))}
                  </ul>
                </div>

                {selectedJob.skills && (
                  <div>
                    <h4 className="font-medium mb-2">Skills Used</h4>
                    <div className="flex flex-wrap gap-2">
                      {selectedJob.skills.map((skill, index) => (
                        <span 
                          key={index}
                          className="bg-gray-100 text-gray-700 px-2 py-1 rounded-md text-sm"
                        >
                          {skill}
                        </span>
                      ))}
                    </div>
                  </div>
                )}
              </div>
            </>
          )}
        </Dialog>
      </CardContent>
    </Card>
  );
};

export default EmploymentRecord;