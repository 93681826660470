import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { toast } from "react-toastify";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { 
  FaBuilding, 
  FaMapMarkerAlt, 
  FaPhoneAlt, 
  FaGlobeAmericas,
  FaFileAlt 
} from 'react-icons/fa';
import { 
  Button, 
  Label, 
  Select, 
  TextInput, 
  Card 
} from 'flowbite-react';

import Background from "../assets/Images/nightCompany.jpg";
import { organizationEmployeeSchema } from "../validation/auth";
import { lgaData, nigeriaStates } from "../assets/localGov";
import LoadSpinner from "./Modals/LoadSpinner";
import { useDispatch, useSelector } from "react-redux";
import { addData } from "../redux/register/registerSlice";
import VerifyEmail from "./Modals/VerifyEmail";

function OrganizationEmployee({ next, formData }) {
  const [lgaList, setLgaList] = useState([]);
  const navigate = useNavigate();
  const baseUrl = `${process.env.REACT_APP_API_URL}auth/signup`;
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch()
  const registrationData = useSelector(state=>state.registerData)
  const emailRef = useRef(null);


  const {
    register, 
    handleSubmit, 
    formState: { errors }, 
    watch 
  } = useForm({
    resolver: yupResolver(organizationEmployeeSchema)
  });

  const selectedState = watch('state');

  useEffect(() => {
    if (selectedState) {
      setLgaList(lgaData[selectedState]);
    }
  }, [selectedState]);

  const onSubmit = async (data) => {
    setLoading(true);
    
    const payload = {
      ...registrationData,
      ...data
    }

    try {
      const response = await axios.post(baseUrl, payload);
      if (response.request.status === 200) {
        toast.success('Successfully signed up');
        emailRef.current.click();
      } else {
        toast.error('Error signing up');
        console.log('Error --->', response.data.message)
      }
    } catch (error) {
      console.error('Signup error:', error);
      toast.error('Error signing up');
    } finally {
      setLoading(false);
    }
  };

  const handlePrevious = () => {
    next("employerTwo");
  };

  return (
    <div className="min-h-screen bg-gray-50 flex items-center justify-center p-4">
      <Card className="w-full max-w-6xl">
        <div className="grid md:grid-cols-2 gap-8">
          {/* Left Side - Branding Section */}
          <div 
            className="hidden md:block bg-cover bg-center rounded-lg relative"
            style={{ 
              backgroundImage: `linear-gradient(rgba(0,0,0,0.6), rgba(0,0,0,0.6)), url(${Background})`,
              minHeight: '600px'
            }}
          >
            <div className="absolute inset-0 flex flex-col justify-center items-center text-white p-8 text-center">
              <h1 className="text-4xl font-bold mb-4 tracking-tight">
                Welcome to Your Network
              </h1>
              <p className="text-lg opacity-80">
                Create your professional profile and connect with opportunities
              </p>
            </div>
          </div>

          {/* Right Side - Registration Form */}
          <div>
            <div className="flex items-center mb-6">
              <Button 
                onClick={handlePrevious} 
                color="light"
                className="mr-4"
              >
                Back
              </Button>
              <h2 className="text-2xl font-semibold text-gray-800">
                Company Registration
              </h2>
            </div>

            <form onSubmit={handleSubmit(onSubmit)} className="space-y-4">
              <div className="grid md:grid-cols-2 gap-4">
                {/* Company Name */}
                <div>
                  <Label htmlFor="companyName" value="Company Name" />
                  <div className="relative">
                    <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                      <FaBuilding className="text-gray-400" />
                    </div>
                    <TextInput
                      {...register('companyName')}
                      addon={<FaBuilding />}
                      placeholder="Enter company name"
                      color={errors.companyName ? 'failure' : 'default'}
                      helperText={
                        errors.companyName && 
                        <span className="text-red-600">
                          {errors.companyName.message}
                        </span>
                      }
                    />
                  </div>
                </div>

                {/* Address */}
                <div>
                  <Label htmlFor="address" value="Company Address" />
                  <TextInput
                    {...register('address')}
                    addon={<FaMapMarkerAlt />}
                    placeholder="Enter company address"
                    color={errors.address ? 'failure' : 'default'}
                    helperText={
                      errors.address && 
                      <span className="text-red-600">
                        {errors.address.message}
                      </span>
                    }
                  />
                </div>

                {/* State */}
                <div>
                  <Label htmlFor="state" value="State" />
                  <Select
                    {...register('state')}
                    color={errors.state ? 'failure' : 'default'}
                  >
                    <option value="">Select State</option>
                    {nigeriaStates.map((state) => (
                      <option key={state} value={state}>{state}</option>
                    ))}
                  </Select>
                  {errors.state && (
                    <p className="text-xs text-red-600 mt-1">
                      {errors.state.message}
                    </p>
                  )}
                </div>

                {/* Local Government */}
                <div>
                  <Label htmlFor="localGovernment" value="Local Government" />
                  <Select
                    {...register('localGovernment')}
                    color={errors.localGovernment ? 'failure' : 'default'}
                  >
                    <option value="">Select Local Government</option>
                    {lgaList?.map((lga) => (
                      <option key={lga} value={lga}>{lga}</option>
                    ))}
                  </Select>
                  {errors.localGovernment && (
                    <p className="text-xs text-red-600 mt-1">
                      {errors.localGovernment.message}
                    </p>
                  )}
                </div>

                {/* Country */}
                <div>
                  <Label htmlFor="country" value="Country" />
                  <TextInput
                    {...register('country')}
                    addon={<FaGlobeAmericas />}
                    placeholder="Enter country"
                    color={errors.country ? 'failure' : 'default'}
                    helperText={
                      errors.country && 
                      <span className="text-red-600">
                        {errors.country.message}
                      </span>
                    }
                  />
                </div>

                {/* Phone Number */}
                <div>
                  <Label htmlFor="phoneNumber" value="Phone Number" />
                  <TextInput
                    {...register('phoneNumber')}
                    addon={<FaPhoneAlt />}
                    placeholder="Enter phone number"
                    color={errors.phoneNumber ? 'failure' : 'default'}
                    helperText={
                      errors.phoneNumber && 
                      <span className="text-red-600">
                        {errors.phoneNumber.message}
                      </span>
                    }
                  />
                </div>

                {/* Company Size */}
                <div>
                  <Label htmlFor="size" value="Company Size" />
                  <TextInput
                    {...register('size')}
                    addon={<FaFileAlt />}
                    placeholder="Number of employees"
                    color={errors.size ? 'failure' : 'default'}
                    helperText={
                      errors.size && 
                      <span className="text-red-600">
                        {errors.size.message}
                      </span>
                    }
                  />
                </div>
              </div>

              <div className="mt-6 space-y-4">
                <Button 
                  type="submit" 
                  fullSized 
                  disabled={loading}
                  className="bg-green-500"
                >
                  {loading ? <LoadSpinner /> : "Complete Registration"}
                </Button>
                
                <div className="flex items-center justify-center">
                  <span className="text-sm text-gray-600">or</span>
                </div>
                
                <Button 
                  type="button" 
                  // color="light" 
                  fullSized
                  className="bg-red-500 text-slate-100"
                >
                  Connect using E-Verify
                </Button>
              </div>
            </form>
            <VerifyEmail 
            userEmail={registrationData.email} 
            userType={registrationData.userType} 
            ref={emailRef}
           />
          </div>
        </div>
      </Card>
    </div>
  );
}

export default OrganizationEmployee;