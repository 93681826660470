import { useEffect, useState } from "react"
import { useSearchParams } from "react-router-dom"
import StepOne from "../component/StepOne"
import StepTwo from "../component/StepTwo"
import StepThree from "../component/StepThree"
import StepFour from "../component/StepFour"
import EmployerStepTwo from "../component/EmployerStepTwo"
import IndividualEmployee from "../component/IndividualEmployee"
import OrganizationEmployee from "../component/OrganizationEmployee"
import Header from "../component/Header"
import FooterComponent from "../component/Footer"
import { Button } from "flowbite-react"

function SignupPages() {
    const [searchParams, setSearchParams] = useSearchParams()
    const [currentStep, setCurrentStep] = useState(searchParams.get('currentStep') || 'stepOne')
    const [formData, setFormData] = useState({})

    useEffect(() => {
        if (currentStep && searchParams.get('currentStep') !== currentStep) {
            setSearchParams({ currentStep })
        }
    }, [currentStep, searchParams, setSearchParams])

    
    const commonProps = {
        setSearchParams,
        setFormData,
        formData,
        next: setCurrentStep,
        prev: setCurrentStep
    }

    const signupSteps = (step) => {
        const componentMap = {
            'stepOne': StepOne,
            'stepTwo': StepTwo,
            'stepThree': StepThree,
            'stepFour': StepFour,
            'employerTwo': EmployerStepTwo,
            'individual': IndividualEmployee,
            'organization': OrganizationEmployee
        }

        const Component = componentMap[step] || StepOne
        return <Component {...commonProps} />
    }

    return (
        <div className="pt-16 sm:pt-20">
            <Header />
            {signupSteps(currentStep)}
            <FooterComponent />
        </div>
    )
}

export default SignupPages