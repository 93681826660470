import React from 'react'
import { BiX } from 'react-icons/bi';

const ProofCard = ({ title, description, imageSrc, onDelete }) => (
    <div className="bg-white rounded-lg overflow-hidden shadow-sm hover:shadow-md transition-all duration-200 group">
      <div className="relative">
        <img 
          src={imageSrc} 
          alt={title} 
          className="w-full h-64 object-cover transform group-hover:scale-105 transition-transform duration-300"
        />
        <button
          onClick={onDelete}
          className="absolute top-2 right-2 p-1.5 bg-white rounded-full shadow-md opacity-0 group-hover:opacity-100 transition-opacity duration-200 hover:bg-red-50"
        >
          <BiX className="w-4 h-4 text-red-500" />
        </button>
      </div>
      <div className="p-4">
        <h3 className="text-lg font-medium text-gray-900">{title}</h3>
        <p className="mt-1 text-sm text-gray-500">{description}</p>
      </div>
    </div>
  );

export default ProofCard