import { BiSearch } from "react-icons/bi";
import { BsSafe } from "react-icons/bs";
import { GrAlert, GrConnect, GrUserExpert } from "react-icons/gr";
import { TbGrowth } from "react-icons/tb";


export const aboutData = [
    {
        title:"Search Millions of Jobs",
        content:"We simplify the job search process by bringing together over 2M unique, live jobs and job applicants in one easy-to-use platform.",
        logo: BiSearch
    },
    {
        title:"Connect with Top Employers",
        content:"Get noticed by top companies with our streamlined application process, designed to match your skills with the right opportunities",
        logo: GrConnect 
    },
    {
        title:"Career Growth Support",
        content:"Take advantage of our resources and tools to enhance your career development and achieve your professional goals.",
        logo: TbGrowth
    },
    {
        title:"Personalized Job Alerts",
        content:"Stay ahead of the competition with personalized job alerts that bring the latest opportunities straight to your inbox.",
        logo: GrAlert
    },
    {
        title:"Expert Advice and Guidance",
        content:"Benefit from expert career advice and guidance to navigate the job market and secure your dream job.",
        logo: GrUserExpert
    },
    {
        title:"Safe and Secure",
        content:"We prioritize your security, ensuring a safe and confidential job search experience on our platform.",
        logo: BsSafe
    },
]

export const jobFunctions = [
    {
        title: 'admin',
        description: 'administrative assistance'
    },
    {
        title: 'compliance officer',
        description: 'compliance officer'
    },
    {
        title: 'business development',
        description: 'business development'
    },
    {
        title: 'health',
        description: 'health service management'
    },
    {
        title: 'management',
        description: 'management consultant'
    },
    {
        title: 'purchasing',
        description: 'purchasing'
    },
    {
        title: 'trade',
        description: 'trade union officils'
    },
    {
        title: 'software',
        description: 'software enginner'
    },
    {
        title: 'receptionist',
        description: 'receptionist'
    },
    {
        title: 'secretary',
        description: 'secretary'
    },
    {
        title: 'accounting',
        description: 'auditing and accounting'
    },
]

export const jobLocations = [
    "lagos",
    "anambra",
    "bauchi",
    "bayelsa",
    "benue",
    "borno",
    "cross river",
    "delta",
    "ebonyi",
    "edo",
    "ekiti",
    "enugu",
    "fct",
    "gombe",
    "imo",
    "oyo"
]

export const getTopJobs = (data)=>{
    return data?.slice(1, 4)
}

export const getJobsByLocations = (jobs) => {
    return jobLocations.reduce((acc, state) => {
      const lowerCaseState = state?.toLowerCase();
      acc[state] = jobs?.filter(job => job?.state?.toLowerCase() === lowerCaseState);
      return acc;
    }, {});
  };
  
  

export const getJobByFunction = (data)=>{
    return jobFunctions.reduce((acc, jobFunction)=>{
        const lowerCaseFunction = jobFunction?.title?.toLowerCase() 
        acc[lowerCaseFunction] = data?.filter(job => job?.jobTitle?.toLowerCase()?.includes(lowerCaseFunction))
        return acc
    }, {})
}

export const getJobStatistic = (data)=>{
    
}

export const employmentStat = (data)=>{
    // unemployed, employed, self employed, artisan, student, professionals, job seeker, overseaSkilled worker
    const employed = data.applications?.filter((application)=>application.status === 'Accepted' || 0)
    const unemployed = data?.jobSeeker?.filter((eachUser)=>eachUser.hiredhostory?.length <= 0 || 0)
    return {employed, unemployed}
}
