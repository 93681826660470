
// // --------version2 ---------
// import React, { useState } from 'react';
// import { MdCancel, MdMessage } from 'react-icons/md';

// function MessageModal({ toggle, ApplicantName }) {
//   const [messageType, setMessageType] = useState('text');
  
//   const handleMessageType = () => {
//     setMessageType((prevType) => (prevType === 'text' ? 'email' : 'text'));
//   };

//   const handleSend = () => {
//     alert('Sending message');
//     toggle();
//   };

//   return (
//     <div className="w-full text-gray-600 h-screen flex items-center justify-center bg-black bg-opacity-50 fixed top-0 left-0 z-50">
//       <div className="relative p-6 sm:w-1/3 w-full max-w-md h-auto flex flex-col items-center justify-center bg-white rounded-lg shadow-xl">
//         <MdCancel
//           onClick={toggle}
//           className="absolute top-4 right-4 text-gray-400 w-6 h-6 cursor-pointer hover:text-red-500"
//         />
//         <div className="mb-6 flex items-center justify-center gap-4">
//           <div className="p-3 rounded-full bg-green-100">
//             <MdMessage className="text-green-600" />
//           </div>
//           <h2 className="text-gray-800 text-lg font-semibold capitalize">{`${messageType === 'text' ? 'SMS' : 'Email'} Message`}</h2>
//         </div>
//         <div
//           onClick={handleMessageType}
//           className="relative flex items-center justify-between gap-5 px-4 py-2 border-2 border-green-300 rounded-full cursor-pointer"
//         >
//           <span className="text-gray-600 text-sm">SMS</span>
//           <span className="text-gray-600 text-sm">Email</span>
//           <div
//             className={`absolute ${messageType === 'text' ? 'left-0' : 'right-0'} w-6 h-6 rounded-full bg-green-500 transition-all ease-linear`}
//           ></div>
//         </div>
//         <div className="w-full mt-4">
//           <h2 className="text-lg text-gray-700 mb-2">
//             To: <span className="font-medium">{ApplicantName}</span>
//           </h2>
//           <label htmlFor="subject" className="sr-only">
//             Subject
//           </label>
//           <input
//             type="text"
//             id="subject"
//             placeholder="Subject"
//             className="border border-gray-300 px-4 py-2 my-2 w-full rounded-md focus:border-green-500 focus:outline-none"
//           />
//           <label htmlFor="messageUpdate" className="sr-only">
//             Message
//           </label>
//           <textarea
//             placeholder={`${messageType === 'text' ? 'SMS' : 'Email'} message`}
//             className="mt-2 w-full border border-gray-300 h-24 p-2 rounded-md focus:border-green-500 focus:outline-none"
//             name="messageUpdate"
//             id="messageUpdate"
//           ></textarea>
//           <div className="flex items-center gap-4 justify-center mt-6">
//             <button
//               onClick={handleSend}
//               className="bg-green-600 text-white rounded-md px-4 py-2 hover:bg-green-700 focus:outline-none"
//             >
//               Send {messageType === 'text' ? 'SMS' : 'Email'}
//             </button>
//             <button
//               onClick={toggle}
//               className="bg-gray-200 text-gray-600 rounded-md px-4 py-2 hover:bg-gray-300 focus:outline-none"
//             >
//               Cancel
//             </button>
//           </div>
//         </div>
//       </div>
//     </div>
//   );
// }

// export default MessageModal;


// ------- version 2 --------
import React, { useState } from 'react';
import { MdCancel, MdMessage } from 'react-icons/md';

function MessageModal({ toggle, ApplicantName }) {
  const [messageType, setMessageType] = useState('text');
  const [isTypeDropdownOpen, setIsTypeDropdownOpen] = useState(false);

  const handleMessageType = (type) => {
    setMessageType(type);
    setIsTypeDropdownOpen(false);
  };

  const handleSend = () => {
    alert('Sending message');
    toggle();
  };

  return (
    <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
      <div className="relative p-6 w-full max-w-md bg-white rounded-lg shadow-xl">
        {/* Close Button */}
        <button
          onClick={toggle}
          className="absolute top-4 right-4 text-gray-400 hover:text-red-500 transition-colors duration-200"
          title="Close"
        >
          <MdCancel className="w-6 h-6" />
        </button>

        {/* Header */}
        <div className="mb-6 flex items-center justify-center gap-4">
          <div className="p-3 rounded-full bg-green-100">
            <MdMessage className="text-green-600 w-6 h-6" />
          </div>
          <h2 className="text-lg font-semibold text-gray-800 capitalize">
            {messageType === 'text' ? 'SMS' : 'Email'} Message
          </h2>
        </div>

        {/* Message Type Dropdown */}
        <div className="relative mb-4">
          <button
            onClick={() => setIsTypeDropdownOpen(prev => !prev)}
            className="w-full px-4 py-2 bg-gray-100 text-gray-700 rounded-md text-sm font-medium flex items-center justify-between hover:bg-gray-200 transition-colors duration-200"
          >
            <span>{messageType === 'text' ? 'SMS' : 'Email'}</span>
            <svg
              className={`w-4 h-4 transition-transform duration-200 ${isTypeDropdownOpen ? 'rotate-180' : ''}`}
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
            >
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M19 9l-7 7-7-7" />
            </svg>
          </button>
          {isTypeDropdownOpen && (
            <div className="absolute z-10 mt-1 w-full rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5">
              <button
                onClick={() => handleMessageType('text')}
                className="block w-full text-left px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900"
              >
                SMS
              </button>
              <button
                onClick={() => handleMessageType('email')}
                className="block w-full text-left px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900"
              >
                Email
              </button>
            </div>
          )}
        </div>

        {/* Form */}
        <div className="w-full">
          <h2 className="text-lg text-gray-700 mb-2">
            To: <span className="font-medium">{ApplicantName}</span>
          </h2>
          <label htmlFor="subject" className="sr-only">
            Subject
          </label>
          <input
            type="text"
            id="subject"
            placeholder="Subject"
            className="w-full px-4 py-2 mb-4 border border-gray-300 rounded-md focus:ring-2 focus:ring-green-500 focus:border-green-500 outline-none transition-colors duration-200"
          />
          <label htmlFor="messageUpdate" className="sr-only">
            Message
          </label>
          <textarea
            placeholder={`${messageType === 'text' ? 'SMS' : 'Email'} message`}
            className="w-full h-24 p-4 border border-gray-300 rounded-md focus:ring-2 focus:ring-green-500 focus:border-green-500 outline-none transition-colors duration-200"
            name="messageUpdate"
            id="messageUpdate"
          ></textarea>
        </div>

        {/* Buttons */}
        <div className="mt-6 flex items-center justify-end gap-4">
          <button
            onClick={toggle}
            className="px-4 py-2 bg-gray-200 text-gray-700 rounded-md hover:bg-gray-300 transition-colors duration-200"
          >
            Cancel
          </button>
          <button
            onClick={handleSend}
            className="px-4 py-2 bg-green-600 text-white rounded-md hover:bg-green-700 transition-colors duration-200"
          >
            Send {messageType === 'text' ? 'SMS' : 'Email'}
          </button>
        </div>
      </div>
    </div>
  );
}

export default MessageModal;