import React, { useState } from 'react'
import { nigeriaStates } from '../../assets/jobData'
import { employmentStat } from '../../pages/Homepage/data'


const SearchBox = ({
    handleChange,
    workTypes,
    handleFind
}) => {

  const [searchParams, setSearchParams] = useState('job')

  const handleSelection = (e, selection)=>{
    e.preventDefault()
    setSearchParams(selection)
  }

  return (
    <div className='mt-20'>
        <div className="flex flex-col items-center gap-8 mt-24 text-center text-white">
        <h1 className="text-4xl font-bold">
          The Easiest Way To Get Your Hotel Job
        </h1>
        <h3 className="text-xl">
          Search 270 new jobs. <span className="text-lg">0 added in the last 24 hours</span>
        </h3>
        
        <form className="flex flex-col items-center gap-4 w-full sm:w-3/5 p-6 rounded-lg shadow-lg">
          <div className="flex mb-1 gap-1">
            <button onClick={(e)=>{handleSelection(e, "job")}} className={`px-4 py-2 text-white hover:bg-green-600 ${searchParams === 'job' ? 'bg-green-500' : 'bg-white-500 border-[2px]'} transition-colors`}>
              FIND A JOB
            </button>
            <button onClick={(e)=>{handleSelection(e, "candidate")}} className={`px-4 py-2 text-white hover:bg-green-600 ${searchParams === 'candidate' ? 'bg-green-500' : 'bg-white-500 border-[2px]'} transition-colors`}>
              FIND A CANDIDATE
            </button>
          </div>
          
          <input
          className="p-2 mb-4 text-gray-600 w-full border-2 border-gray-300 rounded-md outline-none focus:border-green-500 transition-colors"
          type="text"
          placeholder="e.g., Web Developer"
          onChange={handleChange}
          name="jobTitle"
          id='jobTitle'
        />

        <select
          onChange={handleChange}
          className="p-2 mb-4 w-full text-gray-600 border-2 border-gray-300 rounded-md outline-none focus:border-green-500 transition-colors"
          name="workType"
          id="workType"
        >
          <option value="">Category</option>
          {workTypes && workTypes.map((eachType, index) => (
            <option key={index} value={eachType}>{eachType}</option>
          ))}
        </select>

        <select
          onChange={handleChange}
          className="p-2 text-gray-600 mb-4 w-full border-2 border-gray-300 rounded-md outline-none focus:border-green-500 transition-colors"
          name="state"
          id="state"
        >
          <option value="">State</option>
          {nigeriaStates && nigeriaStates.map((eachState, index) => (
            <option key={index} value={eachState}>{eachState}</option>
          ))}
        </select>


          <button
            onClick={handleFind}
            className="bg-green-500 px-6 py-2 text-white rounded-md hover:bg-green-600 transition-colors"
          >
            Search
          </button>
        </form>
      </div>
    </div>
  )
}

export default SearchBox