import React from 'react'
import { BiX } from 'react-icons/bi';

const PDFPreviewModal = ({ isOpen, onClose, base64Data }) => {
    if (!isOpen) return null;
  
    return (
      <div className="fixed inset-0 bg-black bg-opacity-50 z-50 flex items-center justify-center p-4">
        <div className="bg-white rounded-xl w-full h-[90vh] flex flex-col relative">
          <button
            onClick={onClose}
            className="absolute top-4 right-4 text-gray-500 hover:text-gray-700"
          >
            <BiX className="w-6 h-6" />
          </button>
          <div className="p-4 border-b">
            <h2 className="text-lg font-semibold text-gray-900">Document Preview</h2>
          </div>
          <div className="flex-1 p-4 overflow-auto">
            {base64Data.startsWith('data:application/pdf') ? (
              <iframe
                src={base64Data}
                className="w-full h-full rounded-lg"
                title="PDF Preview"
              />
            ) : (
              <img
                src={base64Data}
                alt="Document preview"
                className="max-w-full h-auto mx-auto"
              />
            )}
          </div>
        </div>
      </div>
    );
  };

export default PDFPreviewModal