import React from 'react'

const ProfileForm = ({ user, onInputChange }) => (
    <div className="mt-6 grid grid-cols-1 md:grid-cols-2 gap-6">
      <div className="space-y-2">
        <label className="block text-sm font-medium text-gray-700">Address</label>
        <input
          type="text"
          name="address"
          onChange={onInputChange}
          placeholder={user.address}
          disabled={true}
          className="w-full px-3 py-2 border border-gray-200 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent"
        />
      </div>
      <div className="space-y-2">
        <label className="block text-sm font-medium text-gray-700">Contact Number</label>
        <input
          type="text"
          name="phoneNumber"
          onChange={onInputChange}
          placeholder={user.phoneNumber}
          disabled={true}
          className="w-full px-3 py-2 border border-gray-200 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent"
        />
      </div>
    </div>
  );
  

export default ProfileForm