import { useSelector } from "react-redux"

const useUserStat = ()=>{
    const allUser = useSelector(state=>state.visitorList.visitorList)
    // console.log('user data',useSelector(state=>state))
    if(allUser){
        const {
            allUsers,
            returningUsers,
            activeUsers,
            newUsers
        } = allUser
        return{
            allUsers,
            returningUsers,
            activeUsers,
            newUsers
        }
    }   else{
        return{
            allUsers: 0,
            returningUsers : 0,
            activeUsers : 0,
            newUsers: 0
        }
    }
    
}

export default useUserStat