// import React, { useState } from "react";
// import { FaLocationPin } from "react-icons/fa6";

// function BlacklistUser({username, email, complaint}) {
//   const [showComplaint, setShowComplaint] = useState(false)
//   const handleShowComplaint = ()=>{
//     setShowComplaint(!showComplaint)
//   }
//   return (
//     <div className={`${showComplaint ? 'overflow-visible bg-green-200 border-none' : 'overflow-hidden border-2 border-slate-400'} p-2 transition-all relative my-4 rounded-md w-full`}>
//       <div onClick={handleShowComplaint} className="text-md font-semibold cursor-pointer hover:text-gray-900">
//         <span>Name:</span>
//         <span>{username}</span>
//       </div>
//       <div className="text-md font-semibold">
//         <span>Email:</span>
//         <span>{email}</span>
//       </div>
//       <aside className={`bg-white absolute -bottom-20 right-5 z-30 shadow-lg p-4 transition-all mt-2 rounded-lg text-gray-600 ${showComplaint ? 'block' : 'hidden' }`}>
//         <li className="list-none">{complaint[0]}</li>
//         <li className="list-none my-2">{complaint[1]}</li>
//         <li className="list-none">{complaint[2]}</li>
//       </aside>
//     </div>
//   );
// }

// export default BlacklistUser;

// ---- version 2 ------
import React, { useState } from "react";
import { FaLocationPin } from "react-icons/fa6";

function BlacklistUser({ username, email, complaint }) {
  const [showComplaint, setShowComplaint] = useState(false);

  const handleShowComplaint = () => {
    setShowComplaint(!showComplaint);
  };

  return (
    <div className={`p-4 mb-4 transition-all rounded-lg ${showComplaint ? 'bg-green-100' : 'bg-white border border-gray-200'}`}>
      <div onClick={handleShowComplaint} className="cursor-pointer">
        <h3 className="text-lg font-semibold text-gray-800">{username}</h3>
        <p className="text-sm text-gray-600">{email}</p>
      </div>
      {showComplaint && (
        <div className="mt-4 p-4 bg-white rounded-lg shadow-md">
          <ul className="text-gray-700">
            {complaint.map((item, index) => (
              <li key={index} className="list-none my-2">{item}</li>
            ))}
          </ul>
        </div>
      )}
    </div>
  );
}

export default BlacklistUser;