// ------------- version 2 -----------------
import { useEffect, useState } from 'react';
import { Button, Label, TextInput, Select } from 'flowbite-react';
import { HiArrowLeft, HiUser, HiPhone, HiLocationMarker, HiGlobeAlt } from 'react-icons/hi';
import Background from '../assets/Images/backThree2.jpeg';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import { lgaData, nigeriaStates } from '../assets/localGov';
import { stepThreeSchema } from '../validation/auth';
import { setRegistrationData } from '../Utils/localStorage';
import { useDispatch, useSelector } from 'react-redux';
import { addData } from '../redux/register/registerSlice';

const StepThree = ({ next, prev, formData, setFormData, setSearchParams }) => {
  const [lgaList, setLgaList] = useState([]);
  const form = useForm({
    resolver: yupResolver(stepThreeSchema),
    defaultValues: formData,
    mode: 'onChange',
  });
  const dispatch = useDispatch()
  const data = useSelector((state)=>state.registerData)
  console.log('data 4 --->', data)
  const selectedState = form.watch('state');

  useEffect(() => {
    if (selectedState) setLgaList(lgaData[selectedState]);
  }, [selectedState]);

  const onSubmit = async (data) => {
    const payload = {...formData, ...data}
    dispatch(addData(data))
    // setRegistrationData('jobSeekerRegister', data)
    setFormData(payload);
    setSearchParams('stepFour');
    next('stepFour');
  };

  return (
    <div className="w-screen min-h-screen flex">
      <div 
        style={{ backgroundImage: `url(${Background})` }}
        className="relative w-1/2 hidden lg:flex items-center justify-center bg-cover bg-center"
      >
        <div className="absolute inset-0 bg-black/50 backdrop-blur-sm"></div>
        <div className="relative p-8 mx-auto bg-black/80 w-2/3 rounded-lg shadow-xl text-white">
          <h1 className="text-3xl font-bold mb-4">Welcome Back</h1>
          <p className="text-lg font-light">Connect with different users and find your perfect match</p>
        </div>
      </div>

      <div className="w-full lg:w-1/2 p-6">
        <Button className='bg-red-500 text-white flex items-center justify-center' color="gray" size="sm" onClick={(e) => { e.preventDefault(); prev('stepTwo'); }}>
          <HiArrowLeft className="mr-2 mt-[2.5px]" />
          <p>Back</p>
        </Button>

        <div className="max-w-2xl mx-auto mt-8">
          <form onSubmit={form.handleSubmit(onSubmit)} className="bg-white p-8 rounded-lg shadow-lg">
            <h3 className="text-2xl font-semibold text-center mb-8">Personal Information</h3>

            <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
              <div className="space-y-4">
                <div>
                  <Label>First Name</Label>
                  <TextInput
                    {...form.register('firstName')}
                    placeholder="Enter first name"
                  />
                  {form.formState.errors?.firstName && 
                    <p className="mt-1 text-sm text-red-600">{form.formState.errors.firstName.message}</p>
                  }
                </div>

                <div>
                  <Label>Last Name</Label>
                  <TextInput
                    {...form.register('lastName')}
                    placeholder="Enter last name"
                  />
                  {form.formState.errors?.lastName && 
                    <p className="mt-1 text-sm text-red-600">{form.formState.errors.lastName.message}</p>
                  }
                </div>

                <div>
                  <Label>Phone Number</Label>
                  <TextInput
                    {...form.register('phoneNumber')}
                    placeholder="Enter phone number"
                  />
                  {form.formState.errors?.phoneNumber && 
                    <p className="mt-1 text-sm text-red-600">{form.formState.errors.phoneNumber.message}</p>
                  }
                </div>

                <div>
                  <Label>Address</Label>
                  <TextInput
                    {...form.register('address')}
                    placeholder="Enter address"
                  />
                  {form.formState.errors?.address && 
                    <p className="mt-1 text-sm text-red-600">{form.formState.errors.address.message}</p>
                  }
                </div>
              </div>

              <div className="space-y-4">
                <div>
                  <Label>Country</Label>
                  <TextInput
                    {...form.register('country')}
                    placeholder="Enter country"
                  />
                  {form.formState.errors?.country && 
                    <p className="mt-1 text-sm text-red-600">{form.formState.errors.country.message}</p>
                  }
                </div>

                <div>
                  <Label>State</Label>
                  <Select {...form.register('state')}>
                    {nigeriaStates.map(state => (
                      <option key={state} value={state}>{state}</option>
                    ))}
                  </Select>
                  {form.formState.errors?.state && 
                    <p className="mt-1 text-sm text-red-600">{form.formState.errors.state.message}</p>
                  }
                </div>

                <div>
                  <Label>Local Government</Label>
                  <Select {...form.register('localGovernment')}>
                    {lgaList?.map(lga => (
                      <option key={lga} value={lga}>{lga}</option>
                    ))}
                  </Select>
                  {form.formState.errors?.localGovernment && 
                    <p className="mt-1 text-sm text-red-600">{form.formState.errors.localGovernment.message}</p>
                  }
                </div>

                <div>
                  <Label>Gender</Label>
                  <Select {...form.register('gender')}>
                    <option value="">Select gender</option>
                    <option value="Male">Male</option>
                    <option value="Female">Female</option>
                  </Select>
                  {form.formState.errors?.gender && 
                    <p className="mt-1 text-sm text-red-600">{form.formState.errors.gender.message}</p>
                  }
                </div>
              </div>
            </div>

            <div className="mt-8 space-y-4">
              <Button 
                type="submit"
                className="w-full bg-green-500"
                disabled={!form.formState.isValid || form.formState.isSubmitting}
              >
                Continue
              </Button>
              <Button 
                outline
                className="w-full bg-green-500"
              >
                Connect using e-verify
              </Button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default StepThree;