import { Link, useParams } from "react-router-dom";
import Header from "../component/Header.jsx";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import GeneralTable from "../component/Tables/GeneralTable.jsx";
import ScheduleTable from "../component/Tables/ScheduleTable.jsx";
import { BiMessageAltAdd } from "react-icons/bi";
import InterviewTable from "../component/Tables/InterviewedTable.jsx";
import HiredTable from "../component/Tables/HiredTable.jsx";
import OfferTable from "../component/Tables/OfferTable.jsx";
import Skeleton from "../component/Skeleton.jsx";
import { useFetchData } from "../hooks/useFtechData.js";
import { APPLICATION_API } from "../resources/resource.js";

function ApplicantList() {
  const { jobId } = useParams();
  const [allApplicant, setAllApplicant] = useState([]);
  const [interviewedApplicant, setInterviewedApplicant] = useState([]);
  const [rejectedApplicant, setRejectedApplicant] = useState([]);
  const [hiredApplicant, setHiredApplicant] = useState([]);
  const [shortlistedApplicant, setShortlistedApplicant] = useState([]);
  const [scheduledApplicant, setScheduledApplicant] = useState([]);
  const [offerApplicant, setOfferApplicant] = useState([]);
  const [currentList, setCurrentList] = useState([]);
  const [activeSelection, setActiveSelection] = useState("Applied");
  const [table, setTable] = useState("General");
  const currentUser = useSelector((state) => state.user);
  const [position, setPosition] = useState("");
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const {data: applicationDetails, loading: loadingApplication, error} = useFetchData(APPLICATION_API + `/getApplicationByJobId/${jobId}`);

  useEffect(() => {
    if(!applicationDetails) return;
    setLoading(true);
    const getApplicantList = async () => {
      try {
        setAllApplicant(applicationDetails);
        setCurrentList(applicationDetails);
        setPosition(applicationDetails[0]?.position || "");
        setInterviewedApplicant(applicationDetails.filter(eachApplicant => eachApplicant.status === "Interviewed"));
        setRejectedApplicant(applicationDetails.filter(eachApplicant => eachApplicant.status === "Rejected"));
        setHiredApplicant(applicationDetails.filter(eachApplicant => eachApplicant.status === "Hired"));
        setShortlistedApplicant(applicationDetails.filter(eachApplicant => eachApplicant.status === "Shortlisted"));
        setScheduledApplicant(applicationDetails.filter(eachApplicant => eachApplicant.status === "Scheduled"));
        setOfferApplicant(applicationDetails.filter(eachApplicant => eachApplicant.status === "Offer"));
      } catch (error) {
        console.log({ err: error });
      } finally {
        setLoading(false);
      }
    };
    getApplicantList();
  }, [jobId, applicationDetails]);

  const handleFilter = (newArray, table) => {
    setTable(table);
    const selectedElement = table.split(' ')[1];
    const statusMap = {
      'Applied': allApplicant,
      'Rejected': rejectedApplicant,
      'Shortlisted': shortlistedApplicant,
      'Scheduled': scheduledApplicant,
      'Hired': hiredApplicant,
      'Offer': offerApplicant,
      'Interviewed': interviewedApplicant
    };
    setCurrentList(statusMap[selectedElement]);
    setActiveSelection(selectedElement);
  };

  const handleNavigation = () => {
    navigate(`/${jobId}/applicantResponse`);
  };

  const handleInterview = (e) => {
    const interviewLength = scheduledApplicant[0].interviews.length;
    const interviewId = scheduledApplicant[0].interviews[interviewLength - 1]._id;
    if (e.target.innerHTML === "Start") {
      navigate(`/interview/start/${interviewId}`);
    }
  };

  return (
    <div className="min-h-screen pt-16 sm:pt-20 bg-gray-100 flex flex-col">
      <Header />
      {loading && <Skeleton />}

      {applicationDetails?.length > 0 && (
        <div className="flex-1 container mx-auto px-4 py-6 sm:px-6 lg:px-8">
          <div className="bg-white rounded-lg shadow-sm border border-gray-200">
            {/* Tabs Navigation */}
            <nav className="border-b border-gray-200 px-4">
              <ul className="flex flex-wrap gap-2 py-4 overflow-x-auto">
                {[
                  { label: 'Applied', count: allApplicant.length, value: 'General Applied' },
                  { label: 'Rejected', count: rejectedApplicant.length, value: 'General Rejected' },
                  { label: 'Shortlisted', count: shortlistedApplicant.length, value: 'General Shortlisted' },
                  { label: 'Scheduled', count: scheduledApplicant.length, value: 'Scheduled Scheduled' },
                  { label: 'Interviewed', count: interviewedApplicant.length, value: 'General Interviewed' },
                  { label: 'Offer', count: offerApplicant.length, value: 'General Offer' },
                  { label: 'Hired', count: hiredApplicant.length, value: 'General Hired' },
                ].map((tab) => (
                  <li key={tab.label}>
                    <button
                      onClick={() => handleFilter([], tab.value)}
                      className={`px-4 py-2 rounded-md text-sm font-medium transition-colors duration-200 flex items-center gap-2 ${
                        activeSelection === tab.label
                          ? 'bg-green-500 text-white'
                          : 'text-gray-700 hover:bg-gray-100'
                      }`}
                    >
                      <span className="bg-gray-200 text-gray-700 rounded-full px-2 py-1 text-xs">
                        {tab.count}
                      </span>
                      <span>{tab.label}</span>
                    </button>
                  </li>
                ))}
              </ul>
            </nav>

            {/* Content Area */}
            <div className="p-6 relative">
              {/* Floating Action Button */}
              <button 
                onClick={handleNavigation}
                className="fixed bottom-10 right-10 bg-green-500 text-white p-3 rounded-full shadow-lg hover:bg-green-600 transition-colors duration-200"
                title="Add Response"
              >
                <BiMessageAltAdd className="w-6 h-6" />
              </button>

              {/* Table */}
              <div className="overflow-x-auto">
                {activeSelection === "Scheduled" ? (
                  <ScheduleTable 
                    data={scheduledApplicant} 
                    title="Interviews" 
                    handleInterview={handleInterview} 
                  />
                ) : activeSelection === "Interviewed" ? (
                  <InterviewTable data={interviewedApplicant} />
                ) : activeSelection === "Hired" ? (
                  <HiredTable currentList={hiredApplicant} data={allApplicant} />
                ) : activeSelection === "Offer" ? (
                  <OfferTable currentList={offerApplicant} />
                ) : (
                  <GeneralTable 
                    currentList={currentList} 
                    handleNavigation={handleNavigation} 
                    data={allApplicant} 
                  />
                )}
              </div>

              {/* Back Button */}
              <Link 
                to={`/employer/${currentUser?.currentUser?._id}`} 
                className="inline-block mt-6 bg-gray-500 text-white px-6 py-2 rounded-md hover:bg-gray-600 transition-colors duration-200"
              >
                Back
              </Link>
            </div>
          </div>
        </div>
      )}

      {(!loading && allApplicant?.length === 0) && (
        <div className="flex-1 flex items-center justify-center">
          <div className="text-center space-y-4">
            <h1 className="text-2xl font-medium text-gray-800">Nothing to show yet</h1>
            <Link 
              to={`/employer/${currentUser?.currentUser._id}`} 
              className="inline-block bg-green-500 text-white px-6 py-2 rounded-md hover:bg-green-600 transition-colors duration-200"
            >
              Back
            </Link>
          </div>
        </div>
      )}
    </div>
  );
}

export default ApplicantList;