import React from 'react'
import { FaLocationPin } from 'react-icons/fa6'
import { Link } from 'react-router-dom'
import { jobLocations } from '../../pages/Homepage/data'

const SearchByLocation = ({data}) => {
  console.log('data -->', data)
  return (
    <div>
        <h1 className="text-4xl font-extrabold text-center uppercase mb-6 text-gray-900">
    Job By Location
  </h1>
  <p className="text-center text-gray-700 mb-10 max-w-2xl mx-auto">
    We provide you with jobs even at the proximity of your location and place of choice and convenience.
  </p>
  <ul className="flex flex-wrap justify-center gap-8">
    {jobLocations.map((item, index) => (
      <li className="px-5 py-3 border-2 w-[250px]" key={index}>
      <div className="p-2 w-fit border-2 border-slate-300 rounded-full text-green-500 text-xl">
        <FaLocationPin/>
      </div>
      <Link
        className="text-lg capitalize text-gray-800 hover:text-indigo-600 font-medium transition-colors"
        to={`/jobs/?state=${item}`}
        >
        {item}
      </Link>
      <div className="text-sm text-gray-500">vacancy {data[item]?.length}</div>
    </li>
    ))}
  </ul>
    </div>
  )
}

export default SearchByLocation