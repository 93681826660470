import JobSeeker from '../assets/Images/recruit.jpg'
import Employer from '../assets/Images/interview.jpg'
import { Button, Card } from 'flowbite-react'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { stepTwoSchema } from '../validation/auth'
import { toast } from 'react-toastify'
import { setRegistrationData } from '../Utils/localStorage'
import { useDispatch, useSelector } from 'react-redux'
import { addData } from '../redux/register/registerSlice'

function StepTwo(props) {
    const {next, formData, setFormData, setSearchParams} = props
    const dispatch = useDispatch()
    const data = useSelector((state)=>state.registerData)
    console.log('data 4 --->', data)


    const { 
        register, 
        handleSubmit, 
        formState: { errors }, 
        watch 
    } = useForm({
        resolver: yupResolver(stepTwoSchema),
        defaultValues: {
            userType: formData.userType || 'jobSeeker'
        }
    })

    const selectedUserType = watch('userType')

    const handleNext = (data) => {
        if (!data.userType) {
            toast.error('Please select a user type')
            return
        }
        dispatch(addData(data))
        const updatedData = {...formData, ...data}
        // setRegistrationData('jobSeekerRegister', data)
        setFormData(updatedData)
        const nextPage = data.userType === 'jobSeeker' ? 'stepThree' : 'employerTwo'
        setSearchParams(nextPage)
        next(nextPage)
    }

    const handlePrevious = () => {
        const previousPage = 'stepOne'
        setSearchParams(previousPage)
        next(previousPage)
    }

    return (
        <div className="min-h-screen flex items-center justify-center bg-gray-50 p-4">
            <Card className="w-full max-w-2xl">
                <form onSubmit={handleSubmit(handleNext)} className="space-y-6">
                    <div className="flex justify-between items-center">
                        <Button 
                            color="light"
                            onClick={handlePrevious}
                            className="text-red-500 hover:text-red-600"
                        >
                            Back
                        </Button>
                        <h2 className="text-2xl font-bold text-gray-800">
                            Choose Your Profile Type
                        </h2>
                    </div>
                    
                    <div className="grid md:grid-cols-2 gap-6">
                        <label className="cursor-pointer group">
                            <input 
                                type='radio' 
                                value='jobSeeker'
                                {...register('userType')}
                                className="hidden"
                            />
                            <div className={`
                                relative border-2 rounded-lg overflow-hidden
                                ${selectedUserType === 'jobSeeker' 
                                    ? 'ring-2 ring-green-500' 
                                    : 'border-gray-300'}
                            `}>
                                <img 
                                    src={JobSeeker} 
                                    alt="job seeker" 
                                    className="w-full h-48 object-cover group-hover:scale-105 transition-transform"
                                />
                                <div className={`
                                    absolute inset-0 bg-black 
                                    ${selectedUserType === 'jobSeeker' 
                                        ? 'opacity-30' 
                                        : 'opacity-50 group-hover:opacity-40'}
                                `}></div>
                                <h3 className='
                                    absolute bottom-1/2 left-1/2 
                                    transform -translate-x-1/2 translate-y-1/2 
                                    text-white text-xl font-semibold 
                                    z-10 group-hover:text-green-200
                                    transition-colors
                                '>
                                    Job Seeker
                                </h3>
                            </div>
                        </label>

                        <label className="cursor-pointer group">
                            <input 
                                type='radio' 
                                value='jobEmployer'
                                {...register('userType')}
                                className="hidden"
                            />
                            <div className={`
                                relative border-2 rounded-lg overflow-hidden
                                ${selectedUserType === 'jobEmployer' 
                                    ? 'ring-2 ring-green-500' 
                                    : 'border-gray-300'}
                            `}>
                                <img 
                                    src={Employer} 
                                    alt="job employer" 
                                    className="w-full h-48 object-cover group-hover:scale-105 transition-transform"
                                />
                                <div className={`
                                    absolute inset-0 bg-black 
                                    ${selectedUserType === 'jobEmployer' 
                                        ? 'opacity-30' 
                                        : 'opacity-50 group-hover:opacity-40'}
                                `}></div>
                                <h3 className='
                                    absolute bottom-1/2 left-1/2 
                                    transform -translate-x-1/2 translate-y-1/2 
                                    text-white text-xl font-semibold 
                                    z-10 group-hover:text-green-200
                                    transition-colors
                                '>
                                    Employer
                                </h3>
                            </div>
                        </label>
                    </div>

                    {errors.userType && (
                        <p className="text-red-500 text-center">
                            {errors.userType.message}
                        </p>
                    )}

                    <Button 
                        type='submit' 
                        className='w-full'
                        color="warning"
                        disabled={!selectedUserType}
                    >
                        Continue
                    </Button>
                </form>
            </Card>
        </div>
    )
}

export default StepTwo