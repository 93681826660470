import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    registerData : {}
}


const registerDataSlice = createSlice({
    name: "registerData",
    initialState,
    reducers: {
        addData: (state, action)=>{
            return {...state.registerData, ...action.payload}
        },

        setData: (state, action)=>{
            state.registerData = action.payload
        },

        resetData: (state)=>{
            state.registerData = initialState
        }
    }
})


export const {addData, setData, resetData} = registerDataSlice.actions

export default registerDataSlice.reducer