import { useEffect, useState } from "react"
import { useAxiosAuth } from "./useAxiosAuth"
import useLogout from "./useLogout"

export const useFetchData = (url)=>{
    const [response, setResponse] = useState(null)
    const [error, setError] = useState('')
    const [loading, setLoading] = useState(false)
    const [meta, setMeta] = useState({})

    const [pagination, setPagination] = useState({
        current_page: 0,
        next_page: null,
        prev_page: null,
        total_count: 0,
        total_page: 0
    })

    const [requestUrl, setRequestUrl] = useState('')
    const logout = useLogout()

    const axiosAuth = useAxiosAuth();
    useEffect(()=>{
        axiosCall(url)
    }, [url])

    async function axiosCall(url) {
        setRequestUrl(url)
        setLoading(true)
        try {
            const response = await axiosAuth.get(url);
            if(response?.data?.status || response?.data?.success){
                setMeta(response.data?.metadata)
                setPagination(response?.data?.pagination)
                setResponse(response?.data?.data)
                setError('')
            }
        } catch (error) {
            console.log({error})
            if(error?.response?.status === 401){
                return logout(true)
            }
            setError(error)
            console.log(error)
        }finally{
            setLoading(false)
        }
    }

    function refetch(url) {
        axiosCall(url || requestUrl)
    }

    return {data: response, loading, error, meta, refetch, pagination}
}