// import React, { useRef } from 'react';
// import { Button, Card } from 'flowbite-react';
// import { FaDownload, FaEye } from 'react-icons/fa';

// const PDFGenerator = ({ children }) => {
//   const contentRef = useRef(null);
//   const [isLoading, setIsLoading] = React.useState(false);

//   const generatePDF = async () => {
//     if (!contentRef.current) return;
//     setIsLoading(true);
    
//     try {
//       // Import libraries dynamically to reduce initial bundle size
//       const html2canvas = (await import('html2canvas')).default;
//       const { jsPDF } = await import('jspdf');
      
//       const canvas = await html2canvas(contentRef.current, {
//         scale: 2,
//         useCORS: true,
//         logging: false
//       });
      
//       const imgData = canvas.toDataURL('image/png');
//       const pdf = new jsPDF({
//         orientation: 'portrait',
//         unit: 'px',
//         format: [canvas.width, canvas.height]
//       });
      
//       pdf.addImage(imgData, 'PNG', 0, 0, canvas.width, canvas.height);
//       pdf.save('document.pdf');
//     } catch (error) {
//       console.error('Error generating PDF:', error);
//     } finally {
//       setIsLoading(false);
//     }
//   };

//   const previewPDF = async () => {
//     if (!contentRef.current) return;
//     setIsLoading(true);
    
//     try {
//       const html2canvas = (await import('html2canvas')).default;
//       const { jsPDF } = await import('jspdf');
      
//       const canvas = await html2canvas(contentRef.current, {
//         scale: 2,
//         useCORS: true,
//         logging: false
//       });
      
//       const imgData = canvas.toDataURL('image/png');
//       const pdf = new jsPDF({
//         orientation: 'portrait',
//         unit: 'px',
//         format: [canvas.width, canvas.height]
//       });
      
//       pdf.addImage(imgData, 'PNG', 0, 0, canvas.width, canvas.height);
      
//       // Create preview in new window
//       const pdfBlob = pdf.output('blob');
//       const pdfUrl = URL.createObjectURL(pdfBlob);
//       window.open(pdfUrl, '_blank');
      
//       // Cleanup
//       URL.revokeObjectURL(pdfUrl);
//     } catch (error) {
//       console.error('Error previewing PDF:', error);
//     } finally {
//       setIsLoading(false);
//     }
//   };

//   return (
//     <div className="w-full max-w-4xl mx-auto">
//       <div className="flex gap-4 mb-4">
//         <Button 
//           onClick={generatePDF}
//           disabled={isLoading}
//           color="primary"
//         >
//           <FaDownload className="mr-2 h-4 w-4" />
//           Download PDF
//         </Button>
//         <Button 
//           onClick={previewPDF}
//           disabled={isLoading}
//           color="gray"
//           outline
//         >
//           <FaEye className="mr-2 h-4 w-4" />
//           Preview PDF
//         </Button>
//       </div>
      
//       <Card ref={contentRef}>
//         {children}
//       </Card>
//     </div>
//   );
// };

// export default PDFGenerator;

// ---------- version 2 ------------
import React, { useRef, forwardRef } from 'react';
import { Button } from 'flowbite-react';
import { FaDownload, FaEye } from 'react-icons/fa';

// Create a forwardRef wrapper for the content
const ContentWrapper = forwardRef(({ children }, ref) => (
  <div ref={ref} className="w-full">
    {children}
  </div>
));

ContentWrapper.displayName = 'ContentWrapper';

const PDFGenerator = ({ children }) => {
  const contentRef = useRef(null);
  const [isLoading, setIsLoading] = React.useState(false);

  const generatePDF = async () => {
    if (!contentRef.current) return;
    setIsLoading(true);
    
    try {
      const html2canvas = (await import('html2canvas')).default;
      const { jsPDF } = await import('jspdf');
      
      const canvas = await html2canvas(contentRef.current, {
        scale: 2,
        useCORS: true,
        logging: false
      });
      
      const imgData = canvas.toDataURL('image/png');
      const pdf = new jsPDF({
        orientation: 'portrait',
        unit: 'px',
        format: [canvas.width, canvas.height]
      });
      
      pdf.addImage(imgData, 'PNG', 0, 0, canvas.width, canvas.height);
      pdf.save('cv-document.pdf');
    } catch (error) {
      console.error('Error generating PDF:', error);
    } finally {
      setIsLoading(false);
    }
  };

  const previewPDF = async () => {
    if (!contentRef.current) return;
    setIsLoading(true);
    
    try {
      const html2canvas = (await import('html2canvas')).default;
      const { jsPDF } = await import('jspdf');
      
      const canvas = await html2canvas(contentRef.current, {
        scale: 2,
        useCORS: true,
        logging: false
      });
      
      const imgData = canvas.toDataURL('image/png');
      const pdf = new jsPDF({
        orientation: 'portrait',
        unit: 'px',
        format: [canvas.width, canvas.height]
      });
      
      pdf.addImage(imgData, 'PNG', 0, 0, canvas.width, canvas.height);
      
      const pdfBlob = pdf.output('blob');
      const pdfUrl = URL.createObjectURL(pdfBlob);
      window.open(pdfUrl, '_blank');
      
      URL.revokeObjectURL(pdfUrl);
    } catch (error) {
      console.error('Error previewing PDF:', error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="w-full">
      <div className="flex gap-4 mb-4">
        <Button 
          onClick={generatePDF}
          disabled={isLoading}
          color="primary"
        >
          <FaDownload className="mr-2 h-4 w-4" />
          Download PDF
        </Button>
        <Button 
          onClick={previewPDF}
          disabled={isLoading}
          color="gray"
          outline
        >
          <FaEye className="mr-2 h-4 w-4" />
          Preview PDF
        </Button>
      </div>
      
      <ContentWrapper ref={contentRef}>
        {children}
      </ContentWrapper>
    </div>
  );
};

export default PDFGenerator;
