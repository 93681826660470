export const jobCategoryPlans = [
    {
      planName: "Free Plan",
      credits: "0.00",
      duration: "10 days",
      benefits: [
        "Post 1 job listing",
        "Basic visibility in job search results",
        // "Access to a limited pool of job seekers",
        "Basic applicant tracking features"
      ]
    },
    {
      planName: "Basic Plan",
      credits: "10000",
      duration: "30 days",
      benefits: [
        "Post 1 job listing",
        "Basic visibility in job search results",
        "Access to a limited pool of job seekers",
        "Basic applicant tracking features"
      ]
    },
    {
      planName: "Standard Plan",
      credits: "20000",
      duration: "60 days",
      benefits: [
        "Post up to 3 job listings",
        "Standard visibility in job search results",
        "Access to a broader pool of job seekers",
        "Enhanced applicant tracking features",
        "Email support"
      ]
    },
    {
      planName: "Premium Plan",
      credits: "30000",
      duration: "90 days",
      benefits: [
        "Post up to 5 job listings",
        "Premium visibility in job search results",
        "Access to the entire job seeker database",
        "Advanced applicant tracking features",
        "Email and phone support",
        "Company logo on job listings"
      ]
    },
    {
      planName: "Professional Plan",
      credits: "40000",
      duration: "6 months",
      benefits: [
        "Post up to 10 job listings",
        "Featured visibility in job search results",
        "Access to the entire job seeker database with advanced filters",
        "Comprehensive applicant tracking features",
        "Priority email and phone support",
        "Company logo and profile on job listings",
        "Analytics and reporting tools"
      ]
    },
    {
      planName: "Business Plan",
      credits: "70000",
      duration: "1 year",
      benefits: [
        "Post up to 20 job listings",
        "Top-tier visibility in job search results",
        "Access to the entire job seeker database with advanced filters and search capabilities",
        "Full-featured applicant tracking system",
        "Dedicated account manager",
        "Company logo and profile on job listings",
        "Detailed analytics and reporting tools",
        "Free job post refreshes to keep listings at the top"
      ]
    },
    {
      planName: "Enterprise Plan",
      credits: "120000",
      duration: "1 year",
      benefits: [
        "Post up to 50 job listings",
        "Premium placement in job search results",
        "Unlimited access to the job seeker database with advanced filters and AI recommendations",
        "Full-featured applicant tracking and team collaboration tools",
        "Dedicated account manager and recruitment consultant",
        "Company branding on job listings and employer page",
        "Advanced analytics, reporting tools, and custom reports",
        "Free job post refreshes and priority job post updates"
      ]
    },
    {
      planName: "Unlimited Plan",
      credits: "200000",
      duration: "1 year",
      benefits: [
        "Post unlimited job listings",
        "Maximum visibility in job search results",
        "Unlimited access to the job seeker database with advanced filters, AI recommendations, and direct messaging",
        "Full-featured applicant tracking, team collaboration, and interview scheduling tools",
        "Dedicated account manager and senior recruitment consultant",
        "Premium company branding on job listings and employer page",
        "Comprehensive analytics, reporting tools, and custom reports",
        "Free job post refreshes and priority job post updates",
        "Quarterly business reviews and strategy sessions"
      ]
    },
    // {
    //   planName: "Custom Plan",
    //   credits: "500000",
    //   duration: "Customizable",
    //   benefits: [
    //     "Tailored job posting solutions based on specific needs",
    //     "Custom visibility options in job search results",
    //     "Access to specific segments of the job seeker database",
    //     "Customized applicant tracking features",
    //     "Dedicated support team",
    //     "Company branding options based on requirements",
    //     "Custom analytics and reporting tools",
    //     "Regular consultations and strategy sessions",
    //     "Other benefits based on negotiated terms"
    //   ]
    // }
  ];
  