// import React from "react";

// function CvEducation({school, course, year}) {
//   return (
//     <li className="flex items-start justify-between my-2">
//       <div className="flex items-start">
//         <h3 className="font-medium capitalize">
//           {school}
//         </h3>
//         <span className="text-gray-700 ml-2 capitalize">
//           {course}
//         </span>
//       </div>
//       <div className="font-medium text-sm">{year}</div>
//     </li>
//   );
// }

// export default CvEducation;

// ---------- version 2 -----------
import React from "react";
import { FaGraduationCap } from 'react-icons/fa';

function CvEducation({school, course, year}) {
  return (
    <li className="flex flex-col space-y-2 list-none">
      <div className="flex items-center justify-between">
        <div className="flex items-center gap-2">
          <FaGraduationCap className="text-gray-500" />
          <h3 className="font-semibold text-lg capitalize">
            {school}
          </h3>
        </div>
        <div className="text-sm bg-gray-100 px-3 py-1 rounded-full font-medium text-gray-600">
          {year}
        </div>
      </div>
      <span className="text-gray-600 pl-6 capitalize">
        {course}
      </span>
      <div className="border-b border-gray-200 pt-2" />
    </li>
  );
}

export default CvEducation