import { useState } from "react";
// import { jobFunctions, jobIndustries, workTypes, jobSkills } from "../../assets/jobData";
import SearchComponent from "../../component/SearchComponent";
import JobPostJobPage from "../../component/JobPostJobPage";
import Header from "../../component/Header";
import { useLocation, useNavigate } from "react-router-dom";
import qs from "query-string";
import { useFetchData } from "../../hooks/useFtechData";
import { ALL_JOB } from "../../resources/resource";
import Loader from "../../component/utilities/Loader";
import { getFilteredJobs } from "./data";
import Card from "./Crad";

function JobPage() {
  const [allJobs, setAllJobs] = useState([]);
  const [filteredJobs, setFilteredJobs] = useState([]);
  const [filterList, setFilterList] = useState([]);
  const [mainSearchForm, setMainSearchForm] = useState({});
  const location = useLocation();
  const navigate = useNavigate();

  const { jobTitle, workType, state } = qs.parse(location.search);

  const { data, loading, errors } = useFetchData(ALL_JOB);

  const response = getFilteredJobs(data, jobTitle, workType, state);

  const applyFilters = (jobs, filters) => {
    let filteredResults = jobs;

    if (filters.jobTitle) {
      filteredResults = filteredResults.filter((job) =>
        job.jobTitle.toLowerCase().includes(filters.jobTitle.toLowerCase())
      );
    }

    if (filters.workType) {
      filteredResults = filteredResults.filter(
        (job) => job.workType.toLowerCase() === filters.workType.toLowerCase()
      );
    }

    if (filters.state) {
      filteredResults = filteredResults.filter(
        (job) => job.state.toLowerCase() === filters.state.toLowerCase()
      );
    }

    setFilteredJobs(filteredResults);
  };

  const handleClearFilter = () => {
    setFilterList([]);
    setFilteredJobs(allJobs);
    navigate("/jobs");
  };

  const addFilterElement = (e) => {
    const selectedElement = e.target.value;
    const title = e.target.name;
    if (title === "workOrder") {
      if (selectedElement === "latest") {
        setFilteredJobs(
          [...filteredJobs].sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt))
      );
      } else if (selectedElement === "oldest") {
        setFilteredJobs(
          [...filteredJobs].sort((a, b) => new Date(a.createdAt) - new Date(b.createdAt))
      );
      }
      return;
    }
    const updatedFilteredJobs = filteredJobs.filter(
      (eachJob) => eachJob[title].toLowerCase() === selectedElement.toLowerCase()
    );
    setFilteredJobs(updatedFilteredJobs);
    setFilterList([...filterList, { title, selectedElement }]);
  };


  const handleChange = (e) => {
    setMainSearchForm({ ...mainSearchForm, [e.target.name]: e.target.value });
  };

  const searchByFilter = (e) => {
    e.preventDefault();
    applyFilters(allJobs, mainSearchForm);
    navigate(`/jobs?${qs.stringify(mainSearchForm)}`);
  };

  if (loading) return <Loader />;

  return (
    <div className="min-h-screen bg-gray-100 pt-16 sm:pt-20">
      <Header darkMode={true} />
      <div className="container mx-auto p-4">
        {/* Search Component */}
        <SearchComponent handleSearch={searchByFilter} handleChange={handleChange} />

        <div className="flex flex-col lg:flex-row gap-8 mt-8 px-10">
          {/* Job List Section */}
          <div className="w-full lg:w-2/3">
            {loading ? (
              <Loader />
            ) : response?.length > 0 ? (
              response?.map((eachJob) => (
                <JobPostJobPage
                  key={eachJob._id}
                  position={eachJob.jobTitle}
                  companyName={eachJob.employerName}
                  location={eachJob.state}
                  salary={eachJob.salary}
                  jobType={eachJob.workType}
                  experience={eachJob.experienceLength}
                  duration={eachJob.vacancyDuration}
                  jobId={eachJob._id}
                  status="Apply now"
                />
              ))
            ) : (
              <p className="text-center text-gray-600">No jobs found matching your criteria.</p>
            )}
          </div>

          {/* Filter Section */}
          <div className="w-full lg:w-1/3">
            <Card className="p-6 mb-8 bg-white shadow-sm rounded-lg">
              <h2 className="text-xl font-semibold text-gray-800 mb-4">Jobs in Nigeria</h2>
              <div className="flex items-center justify-between p-3 bg-gray-50 rounded-md">
                <span className="text-gray-600">Filter Applied</span>
                <button
                  onClick={handleClearFilter}
                  className="text-sm text-green-500 hover:underline"
                >
                  Clear all
                </button>
              </div>
              <span className="block mt-2 font-bold text-green-500">
                {response?.length} jobs found
              </span>
            </Card>

            {/* <Card className="p-6 bg-white shadow-sm rounded-lg">
              <h2 className="text-xl font-semibold text-gray-800 mb-4">Filter Results</h2>
              <div className="flex items-center justify-between p-3 mb-4 bg-gray-50 rounded-md">
                <span className="text-gray-600">Filter Applied</span>
                <button className="px-4 py-1.5 text-sm text-white bg-green-500 rounded-md hover:bg-green-600">
                  Search
                </button>
              </div>

              <div className="space-y-4">
                <select
                  name="jobFunction"
                  onChange={addFilterElement}
                  className="w-full p-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-green-500"
                >
                  <option value="" disabled selected>
                    Job function
                  </option>
                  {jobFunctions.map((func, index) => (
                    <option key={index} value={func}>
                      {func}
                    </option>
                  ))}
                </select>

                <select
                  name="industry"
                  onChange={addFilterElement}
                  className="w-full p-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-green-500"
                >
                  <option value="" disabled selected>
                    Industry
                  </option>
                  {jobIndustries.map((industry, index) => (
                    <option key={index} value={industry}>
                      {industry}
                    </option>
                  ))}
                </select>

                <select
                  name="state"
                  onChange={addFilterElement}
                  className="w-full p-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-green-500"
                >
                  <option value="" disabled selected>
                    Work type
                  </option>
                  {workTypes.map((type, index) => (
                    <option key={index} value={type}>
                      {type}
                    </option>
                  ))}
                </select>

                <select
                  name="workType"
                  onChange={addFilterElement}
                  className="w-full p-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-green-500"
                >
                  <option value="" disabled selected>
                    Job Skills
                  </option>
                  {jobSkills.map((skill, index) => (
                    <option key={index} value={skill}>
                      {skill}
                    </option>
                  ))}
                </select>

                <select
                  name="workOrder"
                  onChange={addFilterElement}
                  className="w-full p-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-green-500"
                >
                  <option value="Order by" disabled>
                    Order by
                  </option>
                  <option value="Latest">Latest</option>
                  <option value="Starred">Starred</option>
                  <option value="Popular">Popular</option>
                </select>

                <button className="w-full py-2 text-white bg-green-500 rounded-md hover:bg-green-600">
                  Reset Filter
                </button>
              </div>
            </Card> */}
          </div>
        </div>
      </div>
    </div>
  );
}

export default JobPage;