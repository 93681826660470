import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import { useState } from "react";
import Background from '../assets/Images/signinBack.jpeg';
import { signinSuccess, signinFailure, signinStart } from "../redux/users/userSlice";
import { useDispatch, useSelector } from "react-redux";
import LoadSpinner from "../component/Modals/LoadSpinner";
import ForgetPassword from "../component/Modals/ForgetPassword";
import { Button, Label, TextInput } from "flowbite-react";
import { useForm } from "react-hook-form";
import { signinSchema } from "../validation/auth";
import { yupResolver } from '@hookform/resolvers/yup';
import Header from "../component/Header";
import FooterComponent from "../component/Footer";
import { useMakeRequest } from "../hooks/useMakeRequest";
import { SIGIN_URL } from "../resources/resource";
import { toast } from "sonner";

function SigninPages() {
    // const baseURL = `${process.env.REACT_APP_API_URL}auth/signin`;
    const forgetURL = `${process.env.REACT_APP_API_URL}forgetPasword/`;
    const navigate = useNavigate();
    const {register, handleSubmit, formState: { errors }, reset} = useForm({
        resolver: yupResolver(signinSchema),
    });
    const [loading, setLoading] = useState(false)
    // const { loading, error } = useSelector(state => state.user);
    const dispatch = useDispatch();

    const [forgetPasswordData, setForgetPasswordData] = useState({});
    const [forgetPassowrd, setForgetPassword] = useState(false);
    const [loadingForget, setLoadingForget] = useState(false);
    const makeRequest = useMakeRequest()

    const handleForgetPasswordChange = (e) => {
        e.preventDefault();
        setForgetPasswordData({...forgetPasswordData, [e.target.name]: e.target.value });
    };

    const handleForgetPassword = async(e) => {
        e.preventDefault();
        setLoadingForget(true);
        try {
            await axios.post(forgetURL, forgetPasswordData);
            setLoadingForget(false);
            setForgetPassword(!forgetPassowrd);
        } catch (error) {
            setLoadingForget(false);
            console.log(error);
        }
    };

    const handleForgetPasswordToggle = () => {
        setForgetPassword(!forgetPassowrd);
    };

    const handleSignin = async (formData) => {
        setLoading(true)
        const onSuccess = (data)=>{
            dispatch(signinSuccess(data))
            toast.success('sign in success')
            navigate('/')
        }
        try {
            makeRequest(
                SIGIN_URL,
                'POST',
                formData,
                onSuccess,
                (error)=>{console.log('error -->', error)},
                ()=>{setLoading(false)},
                {dontNotifyOnSuccess: true}
            )
            
        } catch (error) {
            console.error('Signin error:', error);
            const errorMsg = error.response?.data?.message || error.message || 'An error occurred. Please try again.';
            dispatch(signinFailure(errorMsg));
        }
    };

    return (
        <div className="pt-16 sm:pt-20">
            <Header/>
            <div className="w-screen text-sm font-mon h-screen flex items-center justify-center bg-gray-100 p-10">
                <div 
                    style={{backgroundImage: `url(${Background})`}} 
                    className="relative sm:w-1/2 h-full object-cover sm:flex hidden items-center justify-center"
                >
                    <div className="absolute inset-0 bg-black opacity-50"></div>
                    <div className="relative flex items-center justify-center flex-col mx-auto bg-black/80 w-2/3 h-2/3 text-white font-mon rounded-sm">
                        <h1 className="text-3xl mb-3">Welcome to our platform</h1>
                        <p className="font-inter w-[300px]">Connect with different users and find your perfect match</p>
                    </div>
                </div>
                <div  
                    className="sm:w-1/2 w-full px-14 py-5"
                >
                    <form onSubmit={handleSubmit(handleSignin)}>
                        <h2 className="text-2xl font-bold mb-6 text-center">Sign In</h2>
                        <div className="flex flex-col items-center justify-center py-2 w-full">
                            <select {...register('userType')} className="mx-auto outline-none border-gray-100 border-2 rounded-md px-2 py-1 w-full" name="userType" id="userType">
                                <option disabled value="jobSeeker">Sign in as</option>
                                <option value="jobSeeker">Job Seeker</option>
                                <option value="jobEmployer">Job Employer</option>
                                <option value="admin">Admin</option>
                            </select>
                            {errors?.userType ? <p className="text-red-500 text-sm">{errors?.userType?.message}</p> : null}
                        </div>
                        <div className="mb-4">
                            <Label className="block mb-2 font-medium" htmlFor="email">Email or Username</Label>
                            <TextInput 
                                className="w-full py-2 outline-none focus:ring-2 focus:ring-blue-500" 
                                {...register('email')}
                                name="email" 
                                type="email" 
                                id="email" 
                                placeholder="James Johnson" 
                            />
                            {errors?.email ? <p className="text-red-500 text-sm">{errors.email.message}</p> : null}
                        </div>
                        <div className="mb-4">
                            <Label className="block mb-2 font-medium" htmlFor="password">Password</Label>
                            <TextInput 
                                className="w-full py-2 outline-none focus:ring-2 focus:ring-blue-500" 
                                {...register('password')}
                                name="password" 
                                type="password" 
                                id="password" 
                                placeholder="********" 
                            />
                            {errors?.password && <p className="text-red-500 text-sm">{errors.password.message}</p>}
                        </div>
                        <Button 
                            type="submit"
                            className="w-full bg-green-500 text-white font-bold rounded-lg hover:bg-green-600 transition duration-200"
                        >
                            {loading ? <LoadSpinner /> : "Sign In"}
                        </Button>
                        <div className="mt-4 text-center">
                            <p>Don't have an account? <Link className="text-blue-500 hover:underline" to='/signup?currentStep=stepOne'>Sign up</Link></p>
                        </div>
                        <Button className="text-blue-500 font-semibold text-base underline" onClick={handleForgetPasswordToggle}>Forget password</Button>
                        <Button
                            className="w-full py-2 mt-4 bg-blue-600 text-white font-bold rounded-lg hover:bg-blue-700 transition duration-200"
                            onClick={handleForgetPasswordToggle}
                        >
                            Forgot Password?
                        </Button>
                    </form>
                </div>
            </div>
            <FooterComponent/>
            {forgetPassowrd ? <ForgetPassword loading={loadingForget} onChange={handleForgetPasswordChange} forgetPasswordData={forgetPasswordData} onSubmit={handleForgetPassword} onToggle={handleForgetPasswordToggle} /> : null}
        </div>
    )
}

export default SigninPages;
