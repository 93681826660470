// -------------Remade/Redone-------------
import { Link } from 'react-router-dom';
import Background from '../assets/Images/signupBack.jpeg';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { stepOneSchema } from '../validation/auth';
import { useDispatch } from 'react-redux';
import { setData } from '../redux/register/registerSlice';



function StepOne(props) {
    const { next, setSearchParams, setFormData, formData } = props;
    const {
        register, handleSubmit, formState: { errors },
    } = useForm({
        resolver: yupResolver(stepOneSchema)
    })
    const dispatch = useDispatch()


    const handleNext = async (data) => {
        setFormData(prev=>({...prev, ...data}));
        // setRegistrationData('jobSeekerRegister', data)
        dispatch(setData(data))
        setSearchParams('currentStep', 'stepTwo')
        next('stepTwo');
    };


    return (
        <div className="w-screen text-sm font-mon h-screen flex items-center justify-center p-2 sm:p-10 bg-gray-100">
            <div 
                    style={{backgroundImage: `url(${Background})`}} 
                    className="relative sm:w-1/2 h-full object-cover sm:flex hidden items-center justify-center bg-no-repeat"
                >
                    <div className="absolute inset-0 bg-black opacity-50"></div>
                    <div className="relative flex items-center justify-center flex-col mx-auto bg-black/80 w-2/3 h-2/3 text-white font-mon rounded-sm">
                        <h1 className="text-3xl mb-3">Welcome to our platform</h1>
                        <p className="font-inter w-[300px]">Connect with different users and find your perfect match</p>
                    </div>
                </div>
            <div  
                className="sm:w-1/2 w-full px-5 py-5"
            >
                <form onSubmit={handleSubmit(handleNext)} className='relative z-20 max-w-lg bg-white p-8 rounded-lg shadow-md text-gray-800'>
                    <div className="mb-4">
                        <label className='block mb-2 font-medium' htmlFor="email">Email</label>
                        <input 
                            id="email" 
                            className='w-full px-4 py-2 outline-none rounded-md border' 
                            // required 
                            {...register('email')}
                            name="email" 
                            type="email" 
                            placeholder="example@gmail.com" 
                        />
                        {errors.email && <p className="text-red-500">{errors.email.message}</p>}
                    </div>
                    <div className="mb-4">
                        <label className='block mb-2 font-medium' htmlFor="password">Password</label>
                        <input 
                            id='password' 
                            className='text-gray-700 w-full px-4 py-2 outline-none rounded-md border' 
                            // required 
                            {...register('password')}
                            name="password" 
                            type="password" 
                            placeholder="********" 
                        />
                        {errors.password && <p className="text-red-500">{errors.password.message}</p>}
                    </div>
                    <div className="mb-4">
                        <label className='block mb-2 font-medium' htmlFor="retype-password">Retype Password</label>
                        <input 
                            id='retype-password' 
                            className='text-gray-700 w-full px-4 py-2 border outline-none rounded-md' 
                            // required 
                            {...register('confirmPassword')}
                            type="password" 
                            placeholder="********" 
                        />
                        {errors.confirmPassword && <p className="text-red-500">{errors.confirmPassword.message}</p>}
                    </div>
                    <button 
                        className={`w-full py-2 my-2 bg-green-500 text-slate-50 text-lg rounded-md`} 
                        type='submit'
                    >
                        Sign up
                    </button>
                    <div className="mt-4 text-center">
                        <h3>Already have an account? <Link className="text-blue-600" to='/signin'>Sign in</Link></h3>
                    </div>
                    <button className='w-full py-2 my-2 bg-green-500 text-slate-50 text-lg rounded-md'>
                        Connect using e-verify
                    </button>
                </form>
            </div>
        </div>
    );
}

export default StepOne;
