import React from 'react'
import OtherChoices from '../OtherChoices'
import Energy from '../../assets/Images/energy.jpg'
import Health from '../../assets/Images/health.jpg'
import Technology from '../../assets/Images/technology.jpg'
import Finance from '../../assets/Images/finance.jpg'
import Entertainment from '../../assets/Images/entertainment2.jpg'
import Manufacturing from '../../assets/Images/manufacturing.jpg'



const Choices = () => {
  return (
    <div>
        <h2 className="text-3xl font-bold text-gray-900 mb-8 text-center">Our Other Choices</h2>
  <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-8">
    <OtherChoices
      choiceImage={Technology}
      title="Technology"
      content="Encompasses businesses focused on the development, production, and distribution of technology products and services, including software, hardware, and IT services. This industry drives innovation and includes major segments like computing, telecommunications, and electronics."
    />
    <OtherChoices
      choiceImage={Health}
      title="HealthCare"
      content="Involves organizations and professionals that provide medical services, manufacture medical equipment or drugs, and facilitate the provision of healthcare to patients. It includes hospitals, pharmaceutical companies, and biotechnology firms."
    />
    <OtherChoices
      choiceImage={Finance}
      title="Finance"
      content="Comprises businesses that manage money, including banks, investment firms, insurance companies, and real estate firms. This industry is crucial for economic stability and growth, providing services like lending, investment, insurance, and financial planning."
    />
    <OtherChoices
      choiceImage={Entertainment}
      title="Entertainment"
      content="Encompasses businesses that produce and distribute entertainment content, such as movies, music, television, and video games, as well as news and information services. This industry plays a significant role in shaping culture and public opinion."
    />
    <OtherChoices
      choiceImage={Manufacturing}
      title="Manufacturing"
      content="Involves the production of goods using labor, machinery, and tools. This industry spans a wide range of products, from automobiles and electronics to textiles and food. Manufacturing is a backbone of industrialized economies, driving innovation and employment."
    />
    <OtherChoices
      choiceImage={Energy}
      title="Energy"
      content="Consists of businesses involved in the production and distribution of energy, including fossil fuels (oil, gas, coal), nuclear power, and renewable energy sources (solar, wind, hydro). It is essential for powering homes, businesses, and transportation systems."
    />
  </div>
    </div>
  )
}

export default Choices