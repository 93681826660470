import React, { useState } from 'react';
import { Card, CardHeader, CardTitle, CardContent } from './Card';
import { Dialog, DialogTitle } from './DialogBox';
import { FaBriefcase, FaCalendar, FaPlus } from 'react-icons/fa';
import { useMakeRequest } from '../../hooks/useMakeRequest';
import { WORK_URL } from '../../resources/resource';
import { useSelector } from 'react-redux';
import { Spinner } from 'flowbite-react';
import { toast } from 'sonner';

const WorkExperienceList = ({userExperience, refetch}) => {


  const [isOpen, setIsOpen] = useState(false);
  const [loading, setLoading] = useState(false)
  const [deleteLoading, setDeleteLoading] = useState(false)
  const user = useSelector((state)=>state.user?.currentUser)
  const [formData, setFormData] = useState({
    companyName: "",
    companyAddress: "",
    contactPerson: "",
    contactPhone: "",
    salary: "",
    positionHeld: "",
    startYear: "",
    finishYear: "",
    jobDescription: ""
  });
  const makeRequest = useMakeRequest()

  const handleSubmit = (e) => {
    e.preventDefault();
    try{
      setLoading(true)
    const onSuccess = (data)=>{
      refetch()
    }

    const onFailure = (error)=>{
      console.log(error)
    }

    const onFinal = ()=>{
      setLoading(false)
      setFormData({
        companyName: "",
        companyAddress: "",
        contactPerson: "",
        contactPhone: "",
        salary: "",
        positionHeld: "",
        startYear: "",
        finishYear: "",
        jobDescription: ""
      });
      setIsOpen(false);
    }

    const payload = {...formData, user:user?._id}

    makeRequest(
      WORK_URL + '/createExperience',
      'POST',
      payload,
      onSuccess,
      onFailure,
      onFinal
    )
    }catch(error){
      console.log(error)
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData(prev => ({
      ...prev,
      [name]: value
    }));
  };

  const handleDelete = (experienceId)=>{
    setDeleteLoading(true)
    makeRequest(
      WORK_URL + `/deleteUserWork/${experienceId}`,
      'DELETE',
      {},
      ()=>{refetch()},
      (error)=>{console.log(error)},
      ()=>{setDeleteLoading(false)}
    )
  }

  return (
    <div className="p-6 max-w-4xl mx-auto">
      <div className="flex justify-between items-center mb-6">
        <h2 className="text-2xl font-semibold">Work Experience</h2>
        <button
          onClick={() => setIsOpen(true)}
          className="flex items-center gap-2 px-4 py-2 bg-green-600 text-white rounded-md hover:bg-green-700 transition-colors"
        >
          <FaPlus className="w-4 h-4" />
          Add Experience
        </button>
      </div>

      <div className="space-y-4">
        {userExperience?.map((exp) => (
          <Card key={exp.id}>
            <CardHeader>
              <div className="flex justify-between items-start">
                <div>
                  <CardTitle>{exp.position}</CardTitle>
                  <div className="flex items-center gap-2 text-gray-600 mt-1">
                    <FaBriefcase className="w-4 h-4" />
                    {exp.companyName}
                  </div>
                </div>
                <div className="flex items-center gap-2 text-gray-600">
                  <FaCalendar className="w-4 h-4" />
                  <span>
                    {new Date(exp.startYear).toLocaleDateString('en-US', { month: 'short', year: 'numeric' })} -{' '}
                    {exp.endDate 
                      ? new Date(exp.finishYear).toLocaleDateString('en-US', { month: 'short', year: 'numeric' })
                      : 'Present'}
                  </span>
                </div>
              </div>
            </CardHeader>
            <CardContent>
              <p className="text-gray-600">{exp.jobDescription}</p>
              <button onClick={()=>{handleDelete(exp._id)}} className='px-4 py-2 mt-2 rounded-md bg-red-300 text-white hover:bg-red-600'>Delete</button>
            </CardContent>
          </Card>
        ))}
      </div>

      <Dialog isOpen={isOpen} onClose={() => setIsOpen(false)}>
        <DialogTitle>Add Work Experience</DialogTitle>
        <form onSubmit={handleSubmit} className="space-y-4">
          <div>
            <label className="block text-sm font-medium mb-1">Company Name</label>
            <input
              type="text"
              name="companyName"
              value={formData.companyName}
              onChange={handleInputChange}
              className="w-full p-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
              required
            />
          </div>
          <div>
            <label className="block text-sm font-medium mb-1">Company Address</label>
            <input
              type="text"
              name="companyAddress"
              value={formData.companyAddress}
              onChange={handleInputChange}
              className="w-full p-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
              required
            />
          </div>
          <div>
            <label className="block text-sm font-medium mb-1">Contact Person</label>
            <input
              type="text"
              name="contactPerson"
              value={formData.contactPerson}
              onChange={handleInputChange}
              className="w-full p-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
              required
            />
          </div>
          <div>
            <label className="block text-sm font-medium mb-1">Contact Phone</label>
            <input
              type="number"
              name="contactPhone"
              value={formData.contactPhone}
              onChange={handleInputChange}
              className="w-full p-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
              required
            />
          </div>
          <div>
            <label className="block text-sm font-medium mb-1">Salary</label>
            <input
              type="text"
              name="salary"
              value={formData.salary}
              onChange={handleInputChange}
              className="w-full p-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
              required
            />
          </div>
          <div>
            <label className="block text-sm font-medium mb-1">Position Held</label>
            <input
              type="text"
              name="positionHeld"
              value={formData.positionHeld}
              onChange={handleInputChange}
              className="w-full p-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
              required
            />
          </div>
          <div className="grid grid-cols-2 gap-4">
            <div>
              <label className="block text-sm font-medium mb-1">Start Date</label>
              <input
                type="month"
                name="startYear"
                value={formData.startYear}
                onChange={handleInputChange}
                className="w-full p-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                required
              />
            </div>
            <div>
              <label className="block text-sm font-medium mb-1">End Date</label>
              <input
                type="month"
                name="finishYear"
                value={formData.finishYear}
                onChange={handleInputChange}
                className="w-full p-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
              />
            </div>
          </div>
          <div>
            <label className="block text-sm font-medium mb-1">Description</label>
            <textarea
              name="jobDescription"
              value={formData.jobDescription}
              onChange={handleInputChange}
              className="w-full p-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
              rows="3"
              required
            />
          </div>
          <div className="flex justify-end gap-4 mt-6">
            <button
              type="button"
              onClick={() => setIsOpen(false)}
              className="px-4 py-2 border rounded-md hover:bg-gray-100 transition-colors"
            >
              Cancel
            </button>
            <button
              type="submit"
              className="px-4 py-2 bg-blue-600 text-white rounded-md hover:bg-blue-700 transition-colors"
            >
              {loading ? <Spinner/> : 'Add Experience'}
            </button>
          </div>
        </form>
      </Dialog>
    </div>
  );
};

export default WorkExperienceList;