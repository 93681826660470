import React, { useState } from 'react';
import {
  Card,
  CardHeader,
  CardTitle,
  CardContent,
} from "./Card";
import { Dialog, DialogTitle } from './DialogBox';  // Update import to your custom Dialog
import { MdBorderHorizontal } from 'react-icons/md';
import { FaExternalLinkAlt } from 'react-icons/fa';

const ApplicationTable = ({ userApplication = [] }) => {
  const [selectedApp, setSelectedApp] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleCloseModal = () => {
    setIsModalOpen(false);
    setSelectedApp(null);
  };

  const getStatusColor = (status) => {
    switch (status) {
      case 'Pending':
        return 'bg-yellow-100 text-yellow-800';
      case 'In Progress':
        return 'bg-blue-100 text-blue-800';
      case 'Rejected':
        return 'bg-red-100 text-red-800';
      case 'Interview':
        return 'bg-purple-100 text-purple-800';
      case 'Hired':
        return 'bg-green-100 text-green-800';
      default:
        return 'bg-gray-100 text-gray-800';
    }
  };

  return (
    <Card className="w-full">
      <CardHeader>
        <CardTitle className="text-xl font-semibold">Application Records</CardTitle>
      </CardHeader>
      <CardContent>
        <div className="relative overflow-x-auto rounded-lg border border-gray-200">
          <table className="w-full text-sm text-left">
            <thead className="text-xs uppercase bg-gray-50">
              <tr>
                <th className="px-6 py-3 font-medium text-gray-500">Company</th>
                <th className="px-6 py-3 font-medium text-gray-500">Position</th>
                <th className="px-6 py-3 font-medium text-gray-500">Date Applied</th>
                <th className="px-6 py-3 font-medium text-gray-500">Status</th>
                <th className="px-6 py-3 font-medium text-gray-500 w-[100px]">Actions</th>
              </tr>
            </thead>
            <tbody className="divide-y divide-gray-200">
              {userApplication.map((app, index) => (
                <tr key={index} className="bg-white hover:bg-gray-50 transition-colors">
                  <td className="px-6 py-4">
                    <div>
                      <div className="font-semibold">{app.companyId?.companyName}</div>
                      <div className="text-sm text-gray-500">{app.companyAddress}</div>
                    </div>
                  </td>
                  <td className="px-6 py-4">{app.position}</td>
                  <td className="px-6 py-4">{new Date(app.createdAt).toLocaleDateString()}</td>
                  <td className="px-6 py-4">
                    <span className={`inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium ${getStatusColor(app.status)}`}>
                      {app.status}
                    </span>
                  </td>
                  <td className="px-6 py-4">
                    <button
                      onClick={() => {
                        setSelectedApp(app);
                        setIsModalOpen(true);
                      }}
                      className="p-2 hover:bg-gray-100 rounded-full transition-colors"
                    >
                      <MdBorderHorizontal className="w-4 h-4" />
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>

        <Dialog isOpen={isModalOpen} onClose={handleCloseModal}>
          {selectedApp && (
            <>
              <DialogTitle>Application Details</DialogTitle>
              <div className="space-y-4">
                <div>
                  <h3 className="font-semibold text-lg">{selectedApp.companyId?.companyName}</h3>
                  <p className="text-gray-500">{selectedApp.companyAddress}</p>
                </div>
                
                <div className="grid grid-cols-2 gap-4">
                  <div>
                    <p className="text-sm text-gray-500">Position</p>
                    <p className="font-medium">{selectedApp.position}</p>
                  </div>
                  <div>
                    <p className="text-sm text-gray-500">Status</p>
                    <span className={`inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium ${getStatusColor(selectedApp.status)}`}>
                      {selectedApp.status}
                    </span>
                  </div>
                  <div>
                    <p className="text-sm text-gray-500">Date Applied</p>
                    <p className="font-medium">
                      {new Date(selectedApp.createdAt).toLocaleDateString()}
                    </p>
                  </div>
                </div>

                <button 
                  onClick={() => window.location.href = `/${selectedApp._id}/applicationStatus`}
                  className="w-full mt-4 inline-flex items-center justify-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                >
                  <FaExternalLinkAlt className="w-4 h-4 mr-2" />
                  Track Application
                </button>
              </div>
            </>
          )}
        </Dialog>
      </CardContent>
    </Card>
  );
};

export default ApplicationTable;