import React from 'react'
import { BiDirections } from 'react-icons/bi'
import { Link } from 'react-router-dom'
import { jobFunctions } from '../../pages/Homepage/data'


const SearchByCategory = ({
    data
}) => {

  return (
    <div>
         <h1 className="text-4xl font-extrabold text-center uppercase mb-6 text-gray-900">
    Popular Job Categories
  </h1>
  <p className="text-center text-gray-700 mb-10 max-w-2xl mx-auto">
    By utilizing our paid job listing, your jobs are displayed to more applicants. Joblink.ng is the most visited job platform in Nigeria, ensuring your job postings gain the maximum exposure.
  </p>
  <ul className="flex flex-wrap justify-center gap-8">
  {jobFunctions?.map((eachFunction, index)=>{
    // console.log('each -->', eachFunction?.toLowerCase())
    return (
      <li className="px-5 py-3 border-2 w-[250px]" key={index}>
      <div className="p-2 w-fit border-2 border-slate-300 rounded-full text-green-500 text-xl">
        <BiDirections/>
      </div>
      <Link
        className="text-lg text-gray-800 hover:text-indigo-600 font-medium transition-colors"
        to={`/jobs/?jobTitle=${eachFunction?.title?.toLowerCase()}`}
        >
        {eachFunction?.title}
      </Link>
      <div className="text-sm text-gray-500">vacant {`${data[eachFunction?.title?.toLowerCase()]?.length}`}</div>
    </li>
    )
  })}
</ul>
    </div>
  )
}

export default SearchByCategory