import { useCallback, useEffect, useState } from "react";

const testimonial = [
    {
        name: 'Sarah Johnson',
        title: 'Software Engineer',
        testimonial: 'This platform has truly transformed my career. I was able to land my dream job in less than a month after signing up. The user interface is intuitive, and the job matching algorithm is incredibly accurate. I highly recommend this site to any professional looking for a new opportunity.',
    },
    {
        name: 'Micheal Rodriquez',
        title: 'Product manager',
        testimonial: "The variety of job listings on this site is unmatched. I appreciated the ability to filter roles based on my specific needs and preferences. The application process was seamless, and I received prompt feedback from potential employers. It's a fantastic resource for anyone in the tech industry.",
    },
    {
        name: 'Emily chen',
        title: 'Data Scientist',
        testimonial: 'I was skeptical at first, but this job site exceeded all my expectations. The resources available for preparing for interviews and refining my resume were incredibly helpful. I found a position that aligns perfectly with my skills and career goals. Thank you for making job hunting stress-free!',
    },
    {
        name: 'Wale Onikulapo`',
        title: 'UX/UI Designer',
        testimonial: "As a designer, finding the right role can be challenging, but this platform made it easy. The job alerts feature ensured I never missed out on relevant opportunities, and the community forum provided valuable networking opportunities. I've recommended this site to all my colleagues.",
    },
];

export function TestimonialCarossel() {
    const [currentState, setCurrentState] = useState(0);

    useEffect(() => {
        const timer = setInterval(() => {
            setCurrentState((prev) => (prev + 1) % testimonial.length);
        }, 5000); // Change slide every 5 seconds

        return () => clearInterval(timer); // Cleanup on unmount
    }, []);

    return (
        <div className="max-w-2xl mx-auto text-center">
            <div className="transition-opacity duration-500 ease-in-out">
                <div className="mb-6">
                    <p className="text-gray-600 italic mb-4">
                        "{testimonial[currentState]?.testimonial}"
                    </p>
                    <h3 className="font-semibold text-lg text-gray-800">
                        {testimonial[currentState]?.name}
                    </h3>
                    <span className="text-gray-500">
                        {testimonial[currentState]?.title}
                    </span>
                </div>

                {/* Optional: Add navigation dots */}
                <div className="flex justify-center gap-2 mt-4">
                    {testimonial.map((_, index) => (
                        <button
                            key={index}
                            className={`w-2 h-2 rounded-full ${
                                currentState === index
                                    ? 'bg-blue-500'
                                    : 'bg-gray-300'
                            }`}
                            onClick={() => setCurrentState(index)}
                        />
                    ))}
                </div>
            </div>
        </div>
    );
}