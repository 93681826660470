import React, { useState } from 'react'
import { Link, useParams } from 'react-router-dom'
import Logo from "../../assets/Images/companyName.svg";
import Header from "../../component/Header";
import { BiFile } from 'react-icons/bi';
import { useSelector } from 'react-redux';
import JobCard from '../../component/JobCard';
import { useFetchData } from '../../hooks/useFtechData';
import { ALL_JOB, APPLICATION_API } from '../../resources/resource';
import Loader from '../../component/utilities/Loader';
import PDFPreviewModal from '../../component/pdf/CvPreview';
import { getSimilarJobs } from '../single/data'; 

function ApplicantResponse() {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const applicationId = useParams().applicationId
  const loggedInUser = useSelector((state) => state.user);
  const { data, loading } = useFetchData(APPLICATION_API + `/getAnApplication/${applicationId}`)
  const {data: jobData, loading: allJobLoading} = useFetchData(ALL_JOB)

  console.log('data -->', data)

  const renderStatus = (status) => {
    const statuses = ['Applied', 'Shortlisted', 'Scheduled', 'Interviewed', 'Offer', 'Hired'];
    const currentIndex = statuses.indexOf(data?.status)
    return (
      <div className="flex items-center space-x-4 overflow-x-auto pb-4">
        {statuses.map((s, index) => (
          <div key={index} className="flex items-center">
            <div className="relative flex items-center">
              <div className={`w-24 h-1 ${currentIndex >= index ? 'bg-blue-600' : 'bg-gray-200'}`}></div>
              <div className={`w-3 h-3 rounded-full ${currentIndex >= index ? 'bg-blue-600' : 'bg-gray-200'} 
                transition-all duration-300`}>
                <span className={`absolute -top-8 left-1/2 -translate-x-1/2 text-xs whitespace-nowrap 
                  ${currentIndex >= index ? 'text-blue-600 font-medium' : 'text-gray-400'}`}>
                  {s}
                </span>
              </div>
            </div>
          </div>
        ))}
      </div>
    );
  }

  const openModal = () => setIsModalOpen(true);

  if (loading) return <Loader />

  return (
    <div className="min-h-screen bg-gray-50 sm:px-8 px-4 py-16">
      <Header darkMode={true} />
      <div className="max-w-7xl mx-auto mt-8 grid lg:grid-cols-3 gap-6">
        <div className="lg:col-span-2 bg-white rounded-lg shadow-sm p-6 border border-gray-100">
          {/* Company Header */}
          <div className="flex items-start justify-between border-b border-gray-200 pb-4">
            <div className="flex items-start space-x-4">
              <img 
                className="w-16 h-16 object-contain rounded-md" 
                src={data?.companyId?.avatar || Logo} 
                alt="company logo" 
              />
              <div>
                <h1 className="text-xl font-semibold text-gray-900">
                  {data?.job?.jobTitle}
                </h1>
                <p className="text-gray-600">{data?.job?.employerName}</p>
                <p className="text-gray-500 text-sm">{data?.job?.state}</p>
              </div>
            </div>
            <Link 
              to={`/profile/${loggedInUser?.currentUser?._id}`} 
              className="px-4 py-2 bg-red-500 text-white rounded-md hover:bg-red-600 transition-colors duration-200 text-sm font-medium"
            >
              Back
            </Link>
          </div>

          {/* Job Details */}
          <div className="mt-6 space-y-6">
            <div>
              <h3 className="text-lg font-semibold text-gray-900 mb-3">Application Status</h3>
              {renderStatus()}
            </div>

            <div className="flex flex-wrap gap-2">
              <span className="px-3 py-1 bg-blue-100 text-blue-800 rounded-full text-sm font-medium">
                {data?.job?.workType}
              </span>
              {data?.job?.currency && data?.job?.salary && (
                <span className="px-3 py-1 bg-green-100 text-green-800 rounded-full text-sm font-medium">
                  {data?.job?.salary} {data?.job?.currency.split("-")[0]}
                </span>
              )}
            </div>

            {data?.job?.jobSummary && (
              <div>
                <h3 className="font-semibold text-gray-900 mb-2">Summary</h3>
                <p className="text-gray-600 leading-relaxed">{data?.job?.jobSummary}</p>
              </div>
            )}

            {data?.job?.jobDescription && (
              <div>
                <h3 className="font-semibold text-gray-900 mb-2">Key Responsibilities</h3>
                <ul className="space-y-2 text-gray-600">
                  {data?.job?.jobDescription?.map((desc, index) => (
                    <li key={index} className="flex items-start">
                      <span className="w-1.5 h-1.5 bg-gray-400 rounded-full mt-2 mr-2 flex-shrink-0"></span>
                      {desc}
                    </li>
                  ))}
                </ul>
              </div>
            )}

            {data?.requireCoverLetter && (
              <div>
                <h3 className="font-semibold text-gray-900 mb-2">Cover Letter</h3>
                <p className="text-gray-600 bg-gray-50 p-4 rounded-md">{data?.coverLetter}</p>
              </div>
            )}

            <div>
              <h3 className="font-semibold text-gray-900 mb-2">Availability</h3>
              <p className="text-gray-600">{data?.availability}</p>
            </div>

            {data?.additionalQuestionResponse?.length > 0 && (
              <div className="space-y-4">
                {data.additionalQuestionResponse.map((q, index) => (
                  <div key={index} className="bg-gray-50 p-4 rounded-md">
                    <p className="text-gray-700 font-medium">{q.question}</p>
                    <p className="text-gray-600 mt-1">Answer: {q.response}</p>
                  </div>
                ))}
              </div>
            )}

            <div className="space-y-4">
              <div className="flex items-center space-x-2 text-gray-700">
                <BiFile className="w-5 h-5" />
                <span className="font-medium">Resume/CV</span>
              </div>
              {data?.resume && (
                <div className="bg-green-50 p-3 rounded-md flex items-center justify-between">
                  <p className="text-green-800 font-medium w-fit overflow-auto">{data?.resume.split(';')[0]}</p>
                  <button 
                    onClick={openModal}
                    className="px-3 py-1 bg-blue-600 text-white rounded-md hover:bg-blue-700 transition-colors duration-200 text-sm"
                  >
                    Preview
                  </button>
                </div>
              )}
            </div>

            {data?.status === 'Offer' && (
              <Link 
                to={`/Offer/${data?._id}`} 
                className="inline-block px-6 py-2 bg-blue-600 text-white rounded-md hover:bg-blue-700 transition-colors duration-200"
              >
                View Offer
              </Link>
            )}
            {data?.status === 'Scheduled' ? (
              <div className='flex items-center justify-start gap-4'>
                <button
                 className="inline-block px-6 py-2 bg-gray-600 text-white rounded-md hover:bg-gray-700 transition-colors duration-200"
                 >View Details</button>
                <Link 
                  to={`/interview/start/${data?._id}`} 
                  className="inline-block px-6 py-2 bg-blue-600 text-white rounded-md hover:bg-blue-700 transition-colors duration-200"
                  >
                  Join Interview
                </Link>
              </div>
            ) : null}
          </div>
        </div>

        {/* Sidebar */}
        <div className="lg:block hidden space-y-4">
          {getSimilarJobs(jobData, data?.job)?.map((eachJob, index) => (
            <JobCard key={index} data={eachJob} />
          ))}
        </div>
      </div>

      <PDFPreviewModal 
        isOpen={isModalOpen}
        onClose={()=>{setIsModalOpen(false)}}
        base64Data={data?.resume || ''}
      />
    </div>
  )
}

export default ApplicantResponse