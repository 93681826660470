import React from 'react'

const JobListingsTable = ({ jobs, onRowClick }) => (
    <div className="mt-8 bg-white shadow-sm rounded-lg overflow-hidden">
      <div className="px-6 py-4 border-b border-gray-200">
        <h2 className="text-lg font-semibold text-gray-900">Job Listings</h2>
      </div>
      <div className="overflow-x-auto">
        <table className="min-w-full divide-y divide-gray-200">
          <thead className="bg-gray-50">
            <tr>
              {["S/N", "Position", "Location", "Posted Date", "End Date"].map((header) => (
                <th
                  key={header}
                  className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                >
                  {header}
                </th>
              ))}
            </tr>
          </thead>
          <tbody className="bg-white divide-y divide-gray-200">
            {jobs?.map((job, index) => (
              <tr
                key={job._id}
                onClick={() => onRowClick(job._id)}
                className="hover:bg-gray-50 cursor-pointer transition-colors"
              >
                <td className="px-6 py-4 text-sm text-gray-500">{index + 1}</td>
                <td className="px-6 py-4 text-sm text-gray-900 font-medium">{job?.jobTitle}</td>
                <td className="px-6 py-4 text-sm text-gray-500">{`${job?.state} ${job?.localGovernment}`}</td>
                <td className="px-6 py-4 text-sm text-gray-500">{new Date(job?.createdAt).toLocaleDateString()}</td>
                <td className="px-6 py-4 text-sm text-gray-500">{new Date(job?.vacancyDuration).toLocaleDateString()}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );

export default JobListingsTable