// import { Button, Modal, TextInput } from "flowbite-react";
// import React, { useState, forwardRef } from "react";
// import '../../global/global.css'
// import axios from "axios";
// import LoadSpinner from "./LoadSpinner";
// import { toast } from "react-toastify";
// import { useNavigate } from "react-router-dom";
// import { useDispatch } from "react-redux";
// import { resetData } from "../../redux/register/registerSlice";
// const baseURL = `${process.env.REACT_APP_API_URL}auth/verifyOTP`;
// const resendOTP = `${process.env.REACT_APP_API_URL}auth/resendOTP`;



// // Make sure to name the component function
//   const VerifyEmail = forwardRef((props, ref) => {
//   const {userEmail, userType} = props
//   const [loading, setLoading] = useState(false)
//   const [openModal, setOpenModal] = useState(false);
//   const [otpValue, setOTPValue] = useState({})
//   const dispatch = useDispatch()
//   const navigate = useNavigate()


//   const handleVerify = async()=>{
//     setLoading(true)
//     try {
//       const OTP = `${otpValue['first']}${otpValue['second']}${otpValue['third']}${otpValue['fourth']}${otpValue['fifth']}${otpValue['sixth']}`
//       const response = await axios.post(baseURL, {otpCode: OTP, email: userEmail, userType: userType})
//       if(response.request.status === 200){
//         toast.success('Email verified')
//         dispatch(resetData())
//         navigate('/signin')
//         setLoading(false)
//       }else{
//         toast.error('Error, try again')
//       }
//     } catch (error) {
//       console.log(error)
//       toast.error(error.response.data.message)
//       setLoading(false)
//     }
//   }


//   const handleOTP = (e)=>{
//     setOTPValue({...otpValue, [e.target.name] : e.target.value})
//   }


//   const handleResend = async()=>{
//     alert('start')
//     try {
//       await axios.post(resendOTP, {email: userEmail})
//       toast.success('token sent')
//       alert('done')
//     } catch (error) {
//       toast.success('error! try again')
//       console.log(error)
//     }
//   }



//   return (
//     <>
//       <Button className="hidden" ref={ref} onClick={() => setOpenModal(true)}>
//         Toggle modal
//       </Button>
//       <Modal show={openModal} size="md" popup onClose={() => setOpenModal(false)}>
//         <Modal.Header />
//         <Modal.Body>
//           <div className="space-y-6">
//             <h3 className="text-2xl capitalize font-medium text-gray-900 text-center dark:text-white">
//               Verify you email
//             </h3>
//             <div>
//               <p>A six digit OTP code have been sent to your Email</p>
//             </div>
//             <div className="flex items-center justify-center gap-5">
//               <TextInput name='first' onChange={(e)=>{handleOTP(e)}} className="w-[80px]" max={1} type="number" />
//               <TextInput name='second' onChange={(e)=>{handleOTP(e)}} className="w-[80px]" max={1} type="number" />
//               <TextInput name='third' onChange={(e)=>{handleOTP(e)}} className="w-[80px]" max={1} type="number" />
//               <TextInput name='fourth' onChange={(e)=>{handleOTP(e)}} className="w-[80px]" max={1} type="number" />
//               <TextInput name='fifth' onChange={(e)=>{handleOTP(e)}} className="w-[80px]" max={1} type="number" />
//               <TextInput name='sixth' onChange={(e)=>{handleOTP(e)}} className="w-[80px]" max={1} type="number" />
//             </div>
//             <div className="flex justify-between">
//               <Button onClick={handleResend} className="text-red-500 mx-auto text-xs text-center">Resend token</Button>
//             </div>
//             <div className="w-full">
//               <Button onClick={handleVerify} className="bg-green-500 mx-auto px-10 text-center">{loading ? <LoadSpinner/> : 'Verify'}</Button>
//             </div>
//           </div>
//         </Modal.Body>
//       </Modal>
//     </>
//   );
// });


// // Export the component
// export default VerifyEmail;

// ------ version 2---------
import { Button, Modal, TextInput } from "flowbite-react";
import React, { useState, forwardRef, useRef } from "react";
import '../../global/global.css'
import axios from "axios";
import LoadSpinner from "./LoadSpinner";
import { toast } from "sonner";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { resetData } from "../../redux/register/registerSlice";

const baseURL = `${process.env.REACT_APP_API_URL}auth/verifyOTP`;
const resendOTP = `${process.env.REACT_APP_API_URL}auth/resendOTP`;

// Use forwardRef as before
const VerifyEmail = forwardRef((props, ref) => {
  const { userEmail, userType } = props;
  const [loading, setLoading] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [otpValue, setOTPValue] = useState({});

  // Create refs for each OTP input field
  const inputRefs = {
    first: useRef(null),
    second: useRef(null),
    third: useRef(null),
    fourth: useRef(null),
    fifth: useRef(null),
    sixth: useRef(null),
  };

  const dispatch = useDispatch();
  const navigate = useNavigate();

  // Define the order of the fields for easier navigation
  const order = ["first", "second", "third", "fourth", "fifth", "sixth"];

  const handleVerify = async () => {
    setLoading(true);
    try {
      const OTP = `${otpValue.first || ""}${otpValue.second || ""}${otpValue.third || ""}${otpValue.fourth || ""}${otpValue.fifth || ""}${otpValue.sixth || ""}`;
      const response = await axios.post(baseURL, { otpCode: OTP, email: userEmail, userType: userType });
      if (response.request.status === 200) {
        toast.success('Email verified');
        dispatch(resetData());
        navigate('/signin');
      } else {
        toast.error('Error, try again');
      }
    } catch (error) {
      console.log(error);
      toast.error(error.response.data.message);
    } finally {
      setLoading(false);
    }
  };

  // Update OTP state and move focus to the next input automatically
  const handleOTP = (e) => {
    const { name, value } = e.target;
    if (value.length > 1) return; // Ensure only one digit is entered

    setOTPValue((prev) => ({ ...prev, [name]: value }));

    // If a digit was entered, move focus to the next input
    if (value && order.indexOf(name) < order.length - 1) {
      const nextField = order[order.indexOf(name) + 1];
      inputRefs[nextField].current.focus();
    }
  };

  // On backspace in an empty field, move focus to the previous field
  const handleKeyDown = (e) => {
    const { name, value } = e.target;
    if (e.key === 'Backspace' && !value) {
      const currentIndex = order.indexOf(name);
      if (currentIndex > 0) {
        const prevField = order[currentIndex - 1];
        inputRefs[prevField].current.focus();
      }
    }
  };

  const handleResend = async () => {
    try {
      await axios.post(resendOTP, { email: userEmail });
      toast.success('Token sent');
    } catch (error) {
      toast.error('Error! Try again');
      console.log(error);
    }
  };

  return (
    <>
      <Button className="hidden" ref={ref} onClick={() => setOpenModal(true)}>
        Toggle modal
      </Button>
      <Modal show={openModal} size="md" popup onClose={() => setOpenModal(false)}>
        <Modal.Header />
        <Modal.Body>
          <div className="space-y-6">
            <h3 className="text-2xl capitalize font-medium text-gray-900 text-center dark:text-white">
              Verify your email
            </h3>
            <div>
              <p>A six digit OTP code has been sent to your Email</p>
            </div>
            <div className="flex items-center justify-center gap-5">
              <TextInput
                name="first"
                onChange={handleOTP}
                onKeyDown={handleKeyDown}
                ref={inputRefs.first}
                className="w-[80px]"
                maxLength={1}
                type="number"
              />
              <TextInput
                name="second"
                onChange={handleOTP}
                onKeyDown={handleKeyDown}
                ref={inputRefs.second}
                className="w-[80px]"
                maxLength={1}
                type="number"
              />
              <TextInput
                name="third"
                onChange={handleOTP}
                onKeyDown={handleKeyDown}
                ref={inputRefs.third}
                className="w-[80px]"
                maxLength={1}
                type="number"
              />
              <TextInput
                name="fourth"
                onChange={handleOTP}
                onKeyDown={handleKeyDown}
                ref={inputRefs.fourth}
                className="w-[80px]"
                maxLength={1}
                type="number"
              />
              <TextInput
                name="fifth"
                onChange={handleOTP}
                onKeyDown={handleKeyDown}
                ref={inputRefs.fifth}
                className="w-[80px]"
                maxLength={1}
                type="number"
              />
              <TextInput
                name="sixth"
                onChange={handleOTP}
                onKeyDown={handleKeyDown}
                ref={inputRefs.sixth}
                className="w-[80px]"
                maxLength={1}
                type="number"
              />
            </div>
            <div className="flex justify-between">
              <Button onClick={handleResend} className="text-red-500 mx-auto text-xs text-center">
                Resend token
              </Button>
            </div>
            <div className="w-full">
              <Button onClick={handleVerify} className="bg-green-500 mx-auto px-10 text-center">
                {loading ? <LoadSpinner /> : 'Verify'}
              </Button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
});

export default VerifyEmail;
