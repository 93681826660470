import React, { useState } from 'react'
import { useMakeRequest } from '../../hooks/useMakeRequest';
import { CONTACT_PERSON_URL } from '../../resources/resource';
import { useSelector } from 'react-redux';

const ContactPersonForm = ({ data, refetch, userId }) => {
    const [contactPerson, setContactPerson] = useState(data)
    const makeRequest = useMakeRequest()
    const [loading, setLoading] = useState(false)
    // const user = useSelector(state=>state.user?.currentUser)
    console.log('data -- new', data)
    const handleChange = (e)=>{
      setContactPerson({...contactPerson, [e.target.name]: e.target.value})
    }


    const onSubmit = (e)=>{
      e.preventDefault()
      setLoading(true)
      const payload = {...contactPerson, company: userId}
      const onSuccess = (data)=>{
        console.log(data)
        refetch()
      }

      if(data === undefined){
        makeRequest(
          CONTACT_PERSON_URL + '/createContact',
          'POST',
          payload,
          onSuccess,
          (error)=>{console.log(error)},
          ()=>{setLoading(false)}
        )
        
      }else{
        makeRequest(
          CONTACT_PERSON_URL + `/updateContact/${data?._id}`,
          'PUT',
          {...data, ...payload},
          onSuccess,
          (error)=>{console.log(error)},
          ()=>{setLoading(false)}
        )
      }
    }

    return (
      <div className="bg-white rounded-lg shadow-sm p-6">
        <div className="flex items-center justify-between mb-6">
          <h2 className="text-lg font-semibold text-gray-900">Company Contact Person</h2>
        </div>
        
        <form onSubmit={onSubmit} className="space-y-6">
          <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
            <div className='space-y-2'>
              <label className='block text-sm font-medium text-gray-700' htmlFor='firstName'>First Name</label>
              <input name='firstName' onChange={handleChange}  className='w-full px-3 py-2 border border-gray-200 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent' id='firstName' type="text" placeholder={contactPerson?.firstName}/>
            </div>
            <div>
              <label className='block text-sm font-medium text-gray-700' htmlFor='lastName'>Last Name</label>
              <input name='lastName' onChange={handleChange} className='w-full px-3 py-2 border border-gray-200 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent' id='lastName' type="text" placeholder={contactPerson?.lastName}/>
            </div>
            <div>
              <label className='block text-sm font-medium text-gray-700' htmlFor='email'>Email</label>
              <input onChange={handleChange} name='email' className='w-full px-3 py-2 border border-gray-200 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent' id='email' type="email" placeholder={contactPerson?.email}/>
            </div>
            <div>
              <label className='block text-sm font-medium text-gray-700' htmlFor='position'>Position</label>
              <input onChange={handleChange} name='position' className='w-full px-3 py-2 border border-gray-200 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent' id='position' type="text" placeholder={contactPerson?.position}/>
            </div>
            <div>
              <label className='block text-sm font-medium text-gray-700' htmlFor='phone'>Phone Number</label>
              <input onChange={handleChange} name='phoneNumber' className='w-full px-3 py-2 border border-gray-200 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent' id='phone' type="number" placeholder={contactPerson?.phoneNumber}/>
            </div>
            <div>
              <label className='block text-sm font-medium text-gray-700' htmlFor='gender'>Gender</label>
              <select onChange={handleChange} name='gender' className='w-full px-3 py-2 border border-gray-200 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent' id="gender">
              {[
                  { value: '', label: 'Select Gender' },
                  { value: 'male', label: 'Male' },
                  { value: 'female', label: 'Female' }
                ].map((eachOption)=>{
                  return(
                    <option value={eachOption?.value}>
                      {eachOption?.label}
                    </option>
                  )
                })}
              </select>
            </div>
          </div>
          <button
            type="submit"
            disabled={loading}
            className="inline-flex items-center px-6 py-3 bg-blue-600 text-white font-medium rounded-lg hover:bg-blue-700 transition-colors duration-200 disabled:bg-blue-300"
          >
            {loading ? (
              <>
                <div className="w-4 h-4 border-2 border-white border-t-transparent rounded-full animate-spin mr-2" />
                Submitting...
              </>
            ) : 'Save Changes'}
          </button>
        </form>
      </div>
    );
  };

export default ContactPersonForm