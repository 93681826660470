export const ALL_JOB = 'job/allJob'
export const ALL_STAT = 'stat/all'
export const SIGNUP_API = 'auth'
export const EDIRECT_API = 'https://api.edirect.ng/api/register'
export const SIGIN_URL = 'auth/signin'
export const JOB_API = 'job/getAjob'
export const JOB_URL = 'job'
export const APPLICATION_API = 'application'
export const INTERVIEW_API = 'interview'


// ------ profile section --------
export const EDUCATION_URL = 'education'
export const WORK_URL = 'experience'
export const CERTIFICATE_URL = 'certificate'
export const GUARANTOR_URL = 'guarantor'
export const JOBSEEKER_URL = 'jobSeeker'

// ----- profile section employer -------
export const CONTACT_PERSON_URL = 'contactPerson'
export const EMPLOYER_URL = 'jobrecruiter'
export const EXISTENCE_PROOF_URL = 'proof'
export const ADDRESS_PROOF_URL = 'addressProof'
export const HIRED = 'hired'