import { useEffect, useRef, useState } from 'react';
import { Button, TextInput, Label, Select } from 'flowbite-react';
import { HiArrowLeft, HiOfficeBuilding, HiPhone, HiLocationMarker, HiGlobeAlt, HiUsers } from 'react-icons/hi';
import Background from '../assets/Images/hiring.jpg';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { individualEmployeeSchema } from '../validation/auth';
import { lgaData, nigeriaStates } from '../assets/localGov';
import axios from 'axios';
import LoadSpinner from './Modals/LoadSpinner';
import VerifyEmail from './Modals/VerifyEmail';
// import { getRegistrationData, setRegistrationData } from '../Utils/localStorage';
import { useSelector } from 'react-redux';
import { toast } from 'sonner';
import { useMakeRequest } from '../hooks/useMakeRequest';
import { SIGNUP_API } from '../resources/resource';

const IndividualEmployee = ({ next, setSearchParams, formData }) => {
  const [loading, setLoading] = useState(false);
  const [lgaList, setLgaList] = useState([]);
  const emailRef = useRef(null);
  // const baseUrl = `${process.env.REACT_APP_API_URL}auth/signup`;
  const registrationData = useSelector(state=>state.registerData)
  const makeRequest = useMakeRequest()


  const { register, handleSubmit, formState: { errors }, watch } = useForm({
    resolver: yupResolver(individualEmployeeSchema)
  });


  const selectedState = watch('state');

  useEffect(() => {
    if (selectedState) setLgaList(lgaData[selectedState]);
  }, [selectedState]);

  const handlePrevious = (e) => {
    e.preventDefault();
    setSearchParams('employerTwo');
    next('employerTwo');
  };

  const onSubmit = async (data) => {
    setLoading(true)
    const payload = {...registrationData, ...data}
     makeRequest(
      SIGNUP_API + '/signup',
      'POST',
      payload,
      (data)=>{emailRef?.current?.click()},
      (error)=>{console.log(error)},
      ()=>{setLoading(false)}
     )
  };

  return (
    <div className="w-screen min-h-screen flex items-center justify-between">
      <div className="w-full min-h-screen flex items-start justify-evenly">
        <div 
          style={{ backgroundImage: `url(${Background})` }} 
          className="relative w-[40%] min-h-screen hidden lg:flex items-center justify-center bg-cover bg-center bg-no-repeat"
        >
          <div className="absolute inset-0 bg-black/50 backdrop-blur-sm"></div>
          <div className="relative p-10 flex items-center justify-center flex-col mx-auto bg-black/80 w-[80%] h-2/3 text-white rounded-lg shadow-xl">
            <h1 className="text-4xl font-bold mb-6">Welcome Back</h1>
            <p className="text-lg text-center font-light max-w-md">
              Connect with different users and find your perfect match
            </p>
          </div>
        </div>

        <div className="w-full lg:w-[60%] px-4 py-8 lg:px-12">
          <div className="max-w-2xl mx-auto">
            <div className="relative mb-8">
              <Button
                color="failure"
                size="sm"
                onClick={handlePrevious}
                className="absolute left-0 top-0"
              >
                <HiArrowLeft className="mr-2 h-4 w-4" />
                Back
              </Button>
              <h2 className="text-center text-2xl font-bold text-gray-900">
                Company Information
              </h2>
            </div>

            <form onSubmit={handleSubmit(onSubmit)} className="space-y-6">
              <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                <div>
                  <div className="mb-6">
                    <Label htmlFor="companyName" value="Company Name" />
                    <TextInput
                      // icon={HiOfficeBuilding}
                      {...register('companyName')}
                      placeholder="Enter company name"
                    />
                    {errors.companyName && (
                      <p className="mt-1 text-sm text-red-600">{errors.companyName.message}</p>
                    )}
                  </div>

                  <div className="mb-6">
                    <Label htmlFor="address" value="Address" />
                    <TextInput
                      // icon={HiLocationMarker}
                      {...register('address')}
                      placeholder="Enter address"
                    />
                    {errors.address && (
                      <p className="mt-1 text-sm text-red-600">{errors.address.message}</p>
                    )}
                  </div>

                  <div className="mb-6">
                    <Label htmlFor="state" value="State" />
                    <Select {...register('state')}>
                      {nigeriaStates.map((state) => (
                        <option key={state} value={state}>{state}</option>
                      ))}
                    </Select>
                    {errors.state && (
                      <p className="mt-1 text-sm text-red-600">{errors.state.message}</p>
                    )}
                  </div>

                  <div className="mb-6">
                    <Label htmlFor="localGovernment" value="local Government" />
                    <Select {...register('localGovernment')}>
                      {lgaData[selectedState]?.map((lga) => (
                        <option key={lga} value={lga}>{lga}</option>
                      ))}
                    </Select>
                    {errors.localGovernment && (
                      <p className="mt-1 text-sm text-red-600">{errors.localGovernment.message}</p>
                    )}
                  </div>
                </div>

                <div>
                  <div className="mb-6">
                    <Label htmlFor="country" value="Country" />
                    <TextInput
                      // icon={HiGlobeAlt}
                      {...register('country')}
                      placeholder="Enter country"
                    />
                    {errors.country && (
                      <p className="mt-1 text-sm text-red-600">{errors.country.message}</p>
                    )}
                  </div>

                  <div className="mb-6">
                    <Label htmlFor="phoneNumber" value="Phone Number" />
                    <TextInput
                      // icon={HiPhone}
                      {...register('phoneNumber')}
                      placeholder="Enter phone number"
                    />
                    {errors.phoneNumber && (
                      <p className="mt-1 text-sm text-red-600">{errors.phoneNumber.message}</p>
                    )}
                  </div>

                  <div className="mb-6">
                    <Label htmlFor="size" value="Company Size" />
                    <TextInput
                      // icon={HiUsers}
                      {...register('size')}
                      placeholder="Enter company size"
                    />
                    {errors.size && (
                      <p className="mt-1 text-sm text-red-600">{errors.size.message}</p>
                    )}
                  </div>
                </div>
              </div>

              <div className="flex flex-col items-center gap-4 mt-8">
                <Button
                  type="submit"
                  className="w-full max-w-md bg-green-500"
                  disabled={loading}
                >
                  {loading ? <LoadSpinner/> : "Continue"}
                </Button>
                <Button
                  className="w-full max-w-md bg-green-500"
                  outline
                >
                  Connect using e-verify
                </Button>
              </div>
            </form>
          </div>
        </div>
      </div>
      <VerifyEmail 
        userEmail={registrationData.email} 
        userType={registrationData.userType} 
        ref={emailRef} />
    </div>
  );
};

export default IndividualEmployee;