export const getFilteredJobs = (data, title, workType, state) => {
    let result = data;
  
    // Filter by title (if provided)
    if (title) {
      result = result?.filter((eachJob) =>
        eachJob?.jobTitle?.toLowerCase() === title.toLowerCase()
      );
    }
  
    // Filter by workType (if provided)
    if (workType) {
      result = result?.filter((eachJob) =>
        eachJob?.workType?.toLowerCase() === workType.toLowerCase()
      );
    }
  
    // Filter by state (if provided)
    if (state) {
      result = result?.filter((eachJob) =>
        eachJob?.state?.toLowerCase() === state.toLowerCase()
      );
    }
  
    return result;
  };