import React, { useState } from 'react'
import { FaHouse, FaPlus } from 'react-icons/fa6'
import { MdAdd } from 'react-icons/md'
import { Card, CardContent, CardHeader, CardTitle } from './Card'
import { FaCertificate } from 'react-icons/fa'
import { Spinner } from 'flowbite-react'
import { Dialog, DialogTitle } from './DialogBox'
import { useSelector } from 'react-redux'
import { useMakeRequest } from '../../hooks/useMakeRequest'
import { CERTIFICATE_URL } from '../../resources/resource'

const Certificate = ({userCertificate, refetch}) => {
  const [isOpen, setIsOpen] = useState(false)
  const user = useSelector(state=>state?.user?.currentUser)
  const [deleteLoading, setDeleteLoading] = useState(false)
  const [formData, setFormData] = useState({
    name: "",
    issuer: "",
    issueDate: "",
    serialNumber: ""
  })

  const makeRequest = useMakeRequest()

  const handleDelete = (certificateId)=>{
    try {
      makeRequest(
        CERTIFICATE_URL + `/deleteUserCertificate/${certificateId}`,
        'DELETE',
        {},
        ()=>{refetch()},
        (error)=>{console.log(error)},
        console.log
      )
    } catch (error) {
      
    }
  }

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData(prev => ({
      ...prev,
      [name]: value
    }));
  };

  const handleSubmit = (e)=>{
    e.preventDefault()
    const payload = {...formData, user: user?._id}
    try {
      makeRequest(
        CERTIFICATE_URL + `/createUserCertificate`,
        'POST',
        payload,
        ()=>{refetch()},
        (error)=>{console.log(error)},
        ()=>{setIsOpen(false)}
      )
    } catch (error) {
      console.log(error)
    }
  }

  return (
    <div className='p-6 max-w-4xl mx-auto'>
      <div className="flex justify-between items-center mb-6">
        <h2 className="text-2xl font-semibold">Certificate</h2>
        <button
          onClick={() => setIsOpen(true)}
          className="flex items-center gap-2 px-4 py-2 bg-green-600 text-white rounded-md hover:bg-green-700 transition-colors"
        >
          <FaPlus className="w-4 h-4" />
          Add Certificate
        </button>
      </div>

      <div className="space-y-4">
        {userCertificate?.map((certificate) => (
          <Card key={certificate?._id}>
            <CardHeader>
              <div className="flex justify-between items-start">
                <div>
                  <CardTitle>{certificate?.certificateName}</CardTitle>
                </div>
              </div>
            </CardHeader>
            <CardContent>
              <div className="space-y-2 text-gray-600">
                <div className="flex items-center gap-2">
                  <FaCertificate className="w-4 h-4" />
                  {certificate?.serialNumber}
                </div>
                <div className="flex items-center justify-between">
                  <div className='flex items-center gap-2'>
                    <FaHouse
                     className="w-4 h-4" />
                    {certificate?.certificateIssuer}
                   </div>
                  <button onClick={()=>{handleDelete(certificate?._id)}} className='px-4 py-2 mt-2 rounded-md bg-red-300 text-white hover:bg-red-600'>
                    {deleteLoading ? <Spinner/> : 'Delete'}
                  </button>
                </div>
              </div>
            </CardContent>
          </Card>
        ))}
      </div>

      <Dialog isOpen={isOpen} onClose={() => setIsOpen(false)}>
        <DialogTitle>Add Certificate</DialogTitle>
        <form onSubmit={handleSubmit} className="space-y-4">
          <div>
            <label className="block text-sm font-medium mb-1">Certificate Name</label>
            <input
              type="text"
              name="certificateName"
              value={formData.certificateName}
              onChange={handleInputChange}
              className="w-full p-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
              required
            />
          </div>
          <div>
            <label className="block text-sm font-medium mb-1">Certificate Issuer</label>
            <input
              type="text"
              name="certificateIssuer"
              value={formData.certificateIssuer}
              onChange={handleInputChange}
              className="w-full p-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
              required
            />
          </div>
          <div>
            <label className="block text-sm font-medium mb-1">Date issued</label>
            <input
              type="date"
              name="issueDate"
              value={formData.issueDate}
              onChange={handleInputChange}
              className="w-full p-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
              required
            />
          </div>
          <div>
            <label className="block text-sm font-medium mb-1">Serial Number(optional)</label>
            <input
              type="text"
              name="serialNumber"
              value={formData.serialNumber}
              onChange={handleInputChange}
              className="w-full p-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
              required
            />
          </div>
          <div className="flex justify-end gap-4 mt-6">
            <button
              type="button"
              onClick={() => setIsOpen(false)}
              className="px-4 py-2 border rounded-md hover:bg-gray-100 transition-colors"
            >
              Cancel
            </button>
            <button
              type="submit"
              className="px-4 py-2 bg-green-600 text-white rounded-md hover:bg-green-700 transition-colors"
            >
              Add Certificate
            </button>
          </div>
        </form>
      </Dialog>
    </div>
  )
}

export default Certificate