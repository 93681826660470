// ----------Component collection---------------------
import Header from "../../component/Header";
import HomevideoComponent from "../../component/HomevideoComponent";
// import JobPostHomePage from "../component/JobPostHomePage";
// import ProductComponent from "../component/ProductComponent";
import StatisticsComponent from "../../component/StatisticsComponent";
import FooterComponent from "../../component/Footer";

// ------------------React icons-----------------
import {
  FaGreaterThan,
  FaLessThan,
} from "react-icons/fa";
import { MdFactory, MdNotifications } from "react-icons/md";
import { Link, useNavigate } from "react-router-dom";
import { BsBriefcase, BsDatabase, BsDatabaseCheck, BsPeople } from "react-icons/bs";


// ------------Video collection----------------
import Video1 from "../../assets/videos/lie.mp4";
import Video2 from "../../assets/videos/life.mp4";
import Video3 from "../../assets/videos/pressure.mp4";
import Video4 from "../../assets/videos/truth.mp4";

// ------------images collection---------------
import Background from "../../assets/Images/homepage.png";
import Manufacturing from '../../assets/Images/manufacturing.jpg'


// -----------React hooks collection--------------------
import { useRef, useState } from "react";
import { useSelector, useDispatch } from "react-redux";

// ------------Dependencies collection-------------------
// import axios, { all } from "axios";
import qs from 'query-string'

// -------------Redux reducers---------------------------
// import { fetchinfJobFailure, fetchingJobStart, fetchingJobSuccess } from "../../redux/jobList/jobListSlice";

// -------------Data collection----------------------------
import CounterCard from "../../component/CounterCard";
import { PiBriefcaseBold, PiFactoryDuotone } from "react-icons/pi";
import { workTypes } from "../../assets/jobData";
import { toast } from "sonner";
import ApplicationStepContainer from "../ApplicationStepContainer";
import AboutContainer from "../AboutContainer";
import { TestimonialCarossel } from "../../component/Carossel/Testimonial.carossel";

import SearchBox from "../../component/home/SearchBox";
import SearchByCategory from "../../component/home/SearchByCategory";
import SearchByLocation from "../../component/home/SearchByLocation";
import Choices from "../../component/home/Choices";
import ProductSection from "../../component/home/ProductSection";
import { useFetchData } from "../../hooks/useFtechData";
import { ALL_JOB, ALL_STAT } from "../../resources/resource";
import { getJobByFunction, getJobsByLocations, getTopJobs } from "./data";



function Homepage() {
  const loggedInUser = useSelector((state) => state.user);
  const allNotifications = useSelector(state=>state.notification.notificationList)
  const [formData, setFormData] = useState({})
  const navigate = useNavigate()

  // ---------Pagination data----------
  

  const {data: jobData, loading} = useFetchData(ALL_JOB)
  const {data: statData, loading: statLoading} = useFetchData(ALL_STAT)


  // -----------Handle scroll-------------
  const handleFind = (e) => {
    e.preventDefault();
    
  
    const { jobTitle, workType, state } = formData;
  
    if (!jobTitle && !workType && !state) {
      return toast.error('Kindly fill the search fields');
    }
  
    const queryParams = {
      ...(jobTitle && { jobTitle }),
      ...(state && { state }),
      ...(workType && { workType })
    };

    const queryString = qs.stringify(queryParams);
    
  
    navigate(`/jobs?${queryString}`);
  };


  // ----------Handle changes----------------
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(prevData => ({
      ...prevData,
      [name]: value
    }));
  };


  return (
    <div className="">
      {/* ----------------Hero Section---------------- */} 
      <section
      style={{ background: `url(${Background}) no-repeat center center/cover` }}
      className="text-sm pt-16 sm:pt-20"
    >
      <div className="mb-10">
        <Header lightMode={true} />
      </div>
      
      {loggedInUser.currentUser && loggedInUser.currentUser.userType === 'jobSeeker' && (
        <div className="absolute top-32 right-10 flex gap-4">
          <button className="bg-green-500 px-6 py-2 rounded-full text-white hover:bg-green-600 transition-colors">
            <Link to={`/benefit`}>Apply for allowance</Link>
          </button>
          <button className="bg-green-500 px-6 py-2 rounded-full text-white hover:bg-green-600 transition-colors">
            <Link to={`/${loggedInUser.currentUser._id}/createCV`}>Create a CV</Link>
          </button>
        </div>
      )}
      
      {/* search section */}
      <SearchBox
        handleChange={handleChange}
        handleFind={handleFind}
        workTypes={workTypes}
      />
    </section>

{/* ------------Job section------------- */}
  <section className="bg-white py-12 sm:py-16 px-5 sm:px-20">
  <p className="text-center text-gray-700 mb-8">
    Job allowance offers you the sum of ₦5000 - ₦10000 monthly to cover your transportation allowance to potential employers for interview and will be deducted once you get a job.
  </p>
  
  <div className="flex flex-wrap justify-center gap-16 font-semibold text-gray-800">
      <CounterCard title='ejob users' value={statData?.jobSeeker?.length
} Icon={<BsPeople className="w-5 h-5"/>}/>
      <CounterCard title='Companies' value={statData?.companies?.length} Icon={<PiFactoryDuotone className="w-5 h-5"/>}/>
      <CounterCard title='Applications' value={statData?.applications
?.length} Icon={<BsDatabaseCheck className="w-5 h-5"/>}/>
      <CounterCard title='Jobs' value={statData?.jobs?.length} Icon={<PiBriefcaseBold className="w-5 h-5"/>}/>
  </div>
</section>


{/* -------------------Job by job category------------------------- */}
<section className="my-20 w-full px-5 sm:px-20 py-16 bg-gray-50">
 <SearchByCategory data={getJobByFunction(jobData)}/>
</section>


{/* --------------------Job by location----------------------- */}

<section className="my-20 bg-green-50 py-16 px-5 sm:px-20">
  <SearchByLocation data={getJobsByLocations(jobData)}/>
</section>

{/* ---------------Advert section---------------- */}
<section className="sm:px-20 px-5">
    <p className="w-full border-2 border-slate-500 h-60">Adds here</p>
</section>

{/* --------------- */}
<ApplicationStepContainer/>

{/* -------------Video section--------------- */}
<section className="my-20 sm:px-20 px-5">
  <div className="flex items-center justify-between w-full max-w-5xl mx-auto">
    <h2 className="text-3xl font-bold text-gray-900">Short Videos</h2>
    <Link
      to="/videos"
      className="text-lg text-blue-600 hover:text-blue-800 transition-colors duration-300"
    >
      See All
    </Link>
  </div>
  <div className="sm:grid mx-auto flex items-center justify-center flex-col sm:grid-cols-1 lg:grid-cols-3 sm:gap-6  ">
    <HomevideoComponent source={Video1} likes="203" views="5,345" />
    <HomevideoComponent source={Video2} likes="203" views="5,345" />
    <HomevideoComponent source={Video3} likes="203" views="5,345" />
    <HomevideoComponent source={Video4} likes="203" views="5,345" />
  </div>
</section>

{/* ----------------Choices Section------------------- */}
<section className="my-20 sm:px-20 px-5 border-t-4 py-4 border-b-4 border-green-500">
  <Choices/>
</section>

{/* ------------Job search section------------- */}


{/* ------------Advert Section--------------------- */}
<section className="w-full h-64 border-2 py-10 sm:px-20 px-5">
  <p>This is an adverts</p>
</section>

{/* ----------------About section------------- */}
  <AboutContainer/>

{/* --------------Product Section----------------- */}
<section className="my-20 sm:px-20 px-5">
  <ProductSection/>
</section>
        
  {/* -------------------Testimonial Page-------------------- */}
  <section className="bg-slate-300 my-20 sm:px-20 py-10">
  <h3 className="mx-auto mb-8 text-xl font-semibold border-b-2 border-green-500 w-fit">Happy Employers</h3>
   <TestimonialCarossel/>
</section>


{/* ------------------Statistic Section-------------------- */}
        <section
          style={{ backgroundImage: `url(${Manufacturing})` }}
          className="min-h-80 my-20 bg-black backdrop-filter flex flex-wrap items-center justify-center bg-cover sm:px-20 px-5"
        >
          <div className="sm:w-full overflow-x-auto flex flex-wrap items-center justify-center h-full gap-16">
            <StatisticsComponent
              Logo={<MdFactory />}
              value={statData?.companies?.length}
              statisticTitle="Companies"
              duration='2000'
            />
            <StatisticsComponent
              Logo={<BsDatabase />}
              value={statData?.applications?.length}
              statisticTitle="Applications"
              duration='2000'
            />
            <StatisticsComponent
              Logo={<BsBriefcase />}
              value={statData?.jobs?.length}
              statisticTitle="Job posted"
              duration='2000'
            />
            <StatisticsComponent
              Logo={<BsPeople />}
              value={statData?.jobSeeker?.length}
              statisticTitle="Members"
              duration='2000'
            />
          </div>
        </section>

    {/* Number of visitors */}
    

    {/* ----------------Notification section----------------- */}
        <section className="flex items-center gap-4 justify-center my-20 sm:px-20 px-5">
          <div className=" flex gap-2 items-center relative">
            <MdNotifications className="text-green-500 w-6 h-6" />
            {loggedInUser.currentUser && <Link to={`/${loggedInUser.currentUser._id}/notification`}>Your Job Notification</Link>}
            <span className="absolute -top-10 left-0 px-2 py-1 rounded-full bg-green-500 text-slate-50">
              {allNotifications ? allNotifications.length : "0"}
            </span>
          </div>
          <div className="border-2 border-slate-400 rounded-lg">
            <form>
              <input
                className="outline-none px-2"
                type="text"
                placeholder="Your email"
              />
              <button className="bg-green-500 h-full px-4 py-2 text-slate-50">
                Subscribe
              </button>
            </form>
          </div>
        </section>
      
      <FooterComponent />
    </div>
  );
}

export default Homepage;
