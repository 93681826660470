// import React from 'react'
// import { BsEye } from 'react-icons/bs'
// import { PiUserSound, PiUserSwitch } from 'react-icons/pi'
// import { TbUserSearch, TbUsersPlus, TbUserStar } from 'react-icons/tb'

// const Statistics = ({
//     visitorData,
//     setPasswordMatch
// }) => {
//   return (
//     <div className='flex items-center justify-evenly w-full'>
//         <div className='p-5 rounded-sm flex items-center justify-center flex-col'>
//             <TbUsersPlus className='w-10 h-10'/>
//             <div className='text-center' >
//               <span>{visitorData.activeUsers && visitorData?.allUsers}</span>
//               <p className='text-lg'>Total users</p>
//             </div>
//           </div>
//           <div className='p-5 rounded-sm flex items-center justify-center flex-col'>
//             <PiUserSwitch className='w-10 h-10'/>
//             <div className='text-center'>
//               <span className='text-lg'>{visitorData && visitorData?.returningUsers}</span>
//               <p>Returning users</p>
//             </div>
//           </div>
//           <div className='p-5 rounded-sm flex items-center justify-center flex-col'>
//             <TbUserSearch className='w-10 h-10'/>
//             <div className='text-center'>
//               <span className='text-lg'>{visitorData && visitorData?.newUsers}</span>
//               <p>New users</p>
//             </div>
//           </div>
//           <div className='p-5 rounded-sm flex items-center justify-center flex-col'>
//             <TbUserStar className='w-10 h-10'/>
//             <div className='text-center'>
//               <span className='text-lg'>{visitorData && visitorData?.returningUsers}</span>
//               <p>Daily users</p>
//             </div>
//           </div>
//           <div className='flex flex-col items-center justify-center'>
//             <PiUserSound className='w-10 h-10'/>
//             <div className='text-center'>
//               <span className='text-lg'>{visitorData && visitorData?.activeUsers}</span>
//               <p>Active users</p>
//             </div>
//           </div>
//           <div className='flex flex-col items-center justify-center'>
//             <BsEye className='cursor-pointer' onClick={()=>{setPasswordMatch(false)}}/>
//             <p>Hide</p>
//           </div>
//     </div>
//   )
// }

// export default Statistics

// ----- version 2 -------
import React from 'react';

function Statistics({ visitorData, setPasswordMatch }) {
  return (
    <div className="mt-8 p-6 bg-gray-800 rounded-lg shadow-md max-w-4xl mx-auto">
      <h2 className="text-xl font-semibold mb-4 text-white">Visitor Statistics</h2>
      <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
        <div className="p-4 bg-gray-700 rounded-lg">
          <h3 className="text-lg font-medium text-white">Total Visitors</h3>
          <p className="text-2xl font-bold text-blue-400">{visitorData.totalVisitors}</p>
        </div>
        <div className="p-4 bg-gray-700 rounded-lg">
          <h3 className="text-lg font-medium text-white">Unique Visitors</h3>
          <p className="text-2xl font-bold text-blue-400">{visitorData.uniqueVisitors}</p>
        </div>
      </div>
      <button
        onClick={() => setPasswordMatch(false)}
        className="mt-6 px-4 py-2 bg-red-600 text-white rounded-md hover:bg-red-700 transition-colors"
      >
        Close Statistics
      </button>
    </div>
  );
}

export default Statistics;