import Logo from '../assets/Images/hotel-logo.png';
import { Copyright, FooterLinkSection, SocialLinks } from './Footer/data';
import useUserStat from '../hooks/useUserStat';
import { useState } from 'react';
import { STAT_PASSWORD } from '../config/config';
import StatsForm from './stats/StatsForm';
import Statistics from './stats/Statistics';

function FooterComponent() {
  const companyLinks = ["About us", "Blog", "Our app"];
  const resourceLinks = ["Support", "Privacy", "Terms", "F.A.Q's"];
  const visitorData = useUserStat();
  const [formPassword, setFormPassword] = useState('');
  const [passwordMatch, setPasswordMatch] = useState(false);
  const [error, setError] = useState(false);

  const handleCheck = (e) => {
    e.preventDefault();
    if (formPassword === STAT_PASSWORD) {
      setPasswordMatch(true);
      return;
    }
    setError(true);
  };

  return (
    <footer className="bg-gray-800 text-white py-12 px-6 sm:px-8 lg:px-16">
      <div className="container mx-auto grid grid-cols-1 md:grid-cols-12 gap-8">
        {/* Logo and Description Section */}
        <div className="md:col-span-3 flex flex-col items-start space-y-4">
          <img 
            src={Logo} 
            alt="eJobs Logo" 
            className="w-32 transition-transform hover:scale-105 duration-200" 
          />
          <p className="text-base text-gray-300 leading-relaxed">
            A community for job seekers and employers.
          </p>
        </div>

        {/* Navigation and Social Links Section */}
        <div className="md:col-span-6 flex flex-col items-start space-y-6">
          <SocialLinks />
          <div className="grid grid-cols-2 gap-8 w-full max-w-lg">
            <FooterLinkSection 
              title="Company" 
              links={companyLinks}
              className="space-y-3"
            />
            <FooterLinkSection 
              title="Resources" 
              links={resourceLinks}
              className="space-y-3"
            />
          </div>
        </div>

        {/* Newsletter Section */}
        <div className="md:col-span-3 flex flex-col items-start space-y-4">
          <h2 className="text-lg font-medium text-gray-100">Stay Updated</h2>
          <div className="w-full max-w-sm space-y-2">
            <input
              type="email"
              placeholder="Enter your email"
              className="w-full px-4 py-2 bg-gray-700 text-white rounded-lg border border-gray-600 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent transition-all duration-150"
            />
            <button className="w-full px-4 py-2 bg-blue-600 text-white rounded-lg hover:bg-blue-700 focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 focus:ring-offset-gray-800 transition-all duration-150">
              Subscribe
            </button>
          </div>
        </div>

        {/* Copyright Section */}
        <div className="md:col-span-12 pt-8 border-t border-gray-700">
          <Copyright />
        </div>
      </div>

      {/* Stats Section */}
      <div className="mt-8 px-6">
        {passwordMatch ? (
          <Statistics 
            visitorData={visitorData}
            setPasswordMatch={setPasswordMatch}
          />
        ) : (
          <StatsForm 
            error={error}
            setFormPassword={setFormPassword}
            handleCheck={handleCheck}
          />
        )}
      </div>
    </footer>
  );
}

export default FooterComponent;