// import Individual from '../assets/Images/organizationnew.jpg'
// import Organization from '../assets/Images/companies.jpg'
// import { useState } from 'react'
// import { useForm } from 'react-hook-form'
// import { yupResolver } from '@hookform/resolvers/yup'
// import { employeeType } from '../validation/auth'
// import { Button } from 'flowbite-react'

// function EmployerStepTwo({next, formData, setFormData, setSearchParams, prev}) {

//    const {
//     register, handleSubmit, formState:{errors}, watch
//    } = useForm({
//     resolver: yupResolver(employeeType)
//    })


//    const onSubmit = (data)=>{
//     const update = {...formData, ...data}
//     const nextPage = data?.employerType
//     setSearchParams(nextPage)
//     setFormData(update)
//     next(nextPage)
//    }


//    const selectedType = watch('employerType')


//     const handlePrevious = ()=>{
//         const previousPage = 'stepTwo'
//         prev(previousPage)
//     }

//   return (
//     <div className="w-screen h-screen flex items-center justify-center">
//         <div className="sm:w-1/2 w-4/5 h-fit relative">
//                 <h3 className='cursor-pointer absolute top-0 right-0 py-0.5 border-[1px] border-red-600 hover:bg-white hover:text-red-500 px-4 bg-red-500 text-slate-50' onClick={handlePrevious}>back</h3>
//             <div className="">
//                 <h3 className='font-md mb-2'>Choose what best describes you</h3>
//             </div>
//             <form onSubmit={handleSubmit(onSubmit)} className="">
//                 <div className='sm:h-60 h-fit flex sm:flex-row flex-col items-center justify-center gap-8'>
//                     <label className="relative w-full cursor-pointer">
//                         <input 
//                             type='radio' 
//                             value='individual'
//                             {...register('employerType')}
//                             className="hidden"
//                         />
//                         <div className={`relative w-full border-2 ${selectedType === 'individual' ? 'ring-2 ring-green-500' : ''}`}>
//                             <img src={Individual} alt="job seeker" className="w-full object-cover" />
//                             <div className={`absolute inset-0 bg-black opacity-50 ${selectedType === 'individual' ? 'opacity-30' : 'opacity-50'}`}></div>
//                             <h3 className='absolute bottom-1/2 left-1/2 transform -translate-x-1/2 translate-y-1/2 text-white text-xl font-semibold z-10'>Individual</h3>
//                         </div>
//                     </label>
//                     <label className="relative w-full cursor-pointer">
//                         <input 
//                             type='radio' 
//                             value='organization'
//                             {...register('employerType')}
//                             className="hidden"
//                         />
//                         <div className={`relative w-full border-2 ${selectedType === 'organization' ? 'ring-2 ring-green-500' : ''}`}>
//                             <img src={Organization} alt="job seeker" className="w-full object-cover" />
//                             <div className={`absolute inset-0 bg-black opacity-50 ${selectedType === 'organization' ? 'opacity-30' : 'opacity-50'}`}></div>
//                             <h3 className='absolute bottom-1/2 left-1/2 transform -translate-x-1/2 translate-y-1/2 text-white text-xl font-semibold z-10'>Organization</h3>
//                         </div>
//                     </label>
//                 </div>
//                 <Button 
//                 type='submit' 
//                 className='w-full bg-green-500 my-4 text-slate-50 py-1 font-medium'>
//                     Next
//                 </Button>
//             </form>
//         </div>
//     </div>
//   )
// }

// export default EmployerStepTwo


// ------- version 2 --------

import Individual from '../assets/Images/organizationnew.jpg'
import Organization from '../assets/Images/companies.jpg'
import { Button, Card } from 'flowbite-react'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { employeeType } from '../validation/auth'
import { toast } from 'react-toastify'
import { setRegistrationData } from '../Utils/localStorage'
import { useDispatch, useSelector } from 'react-redux'
import { addData } from '../redux/register/registerSlice'

function EmployerStepTwo({next, formData, setFormData, setSearchParams, prev}) {
   const {
    register, 
    handleSubmit, 
    formState: { errors }, 
    watch
   } = useForm({
    resolver: yupResolver(employeeType)
   })

   const registrationData = useSelector(state=>state.registerData)
    console.log('employer step', registrationData)

   const dispatch = useDispatch()

   const onSubmit = (data) => {
    if (!data.employerType) {
        toast.error('Please select an employer type')
        return
    }
    console.log('data',data)
    // dispatch(addData(data))
    const update = {...formData, ...data}
    dispatch(addData(update))
    // setRegistrationData('jobSeekerRegister', data)
    const nextPage = data.employerType
    setSearchParams(nextPage)
    setFormData(update)
    next(nextPage)
   }

   const selectedType = watch('employerType')

   const handlePrevious = () => {
    const previousPage = 'stepTwo'
    prev(previousPage)
   }

  return (
    <div className="min-h-screen flex items-center justify-center bg-gray-50 p-4">
        <Card className="w-full max-w-2xl">
            <form onSubmit={handleSubmit(onSubmit)} className="space-y-6">
                <div className="flex justify-between items-center">
                    <Button 
                        color="light"
                        onClick={handlePrevious}
                        className="text-red-500 hover:text-red-600"
                    >
                        Back
                    </Button>
                    <h2 className="text-2xl font-bold text-gray-800">
                        Choose Your Employer Profile Type
                    </h2>
                </div>
                
                <div className="grid md:grid-cols-2 gap-6">
                    <label className="cursor-pointer group">
                        <input 
                            type='radio' 
                            value='individual'
                            {...register('employerType')}
                            className="hidden"
                        />
                        <div className={`
                            relative border-2 rounded-lg overflow-hidden
                            ${selectedType === 'individual' 
                                ? 'ring-2 ring-green-500' 
                                : 'border-gray-300'}
                        `}>
                            <img 
                                src={Individual} 
                                alt="Individual" 
                                className="w-full h-48 object-cover group-hover:scale-105 transition-transform"
                            />
                            <div className={`
                                absolute inset-0 bg-black 
                                ${selectedType === 'individual' 
                                    ? 'opacity-30' 
                                    : 'opacity-50 group-hover:opacity-40'}
                            `}></div>
                            <h3 className='
                                absolute bottom-1/2 left-1/2 
                                transform -translate-x-1/2 translate-y-1/2 
                                text-white text-xl font-semibold 
                                z-10 group-hover:text-green-200
                                transition-colors
                            '>
                                Individual
                            </h3>
                        </div>
                    </label>

                    <label className="cursor-pointer group">
                        <input 
                            type='radio' 
                            value='organization'
                            {...register('employerType')}
                            className="hidden"
                        />
                        <div className={`
                            relative border-2 rounded-lg overflow-hidden
                            ${selectedType === 'organization' 
                                ? 'ring-2 ring-green-500' 
                                : 'border-gray-300'}
                        `}>
                            <img 
                                src={Organization} 
                                alt="Organization" 
                                className="w-full h-48 object-cover group-hover:scale-105 transition-transform"
                            />
                            <div className={`
                                absolute inset-0 bg-black 
                                ${selectedType === 'organization' 
                                    ? 'opacity-30' 
                                    : 'opacity-50 group-hover:opacity-40'}
                            `}></div>
                            <h3 className='
                                absolute bottom-1/2 left-1/2 
                                transform -translate-x-1/2 translate-y-1/2 
                                text-white text-xl font-semibold 
                                z-10 group-hover:text-green-200
                                transition-colors
                            '>
                                Organization
                            </h3>
                        </div>
                    </label>
                </div>

                {errors.employerType && (
                    <p className="text-red-500 text-center">
                        {errors.employerType.message}
                    </p>
                )}

                <Button 
                    type='submit' 
                    className='w-full'
                    color="warning"
                    disabled={!selectedType}
                >
                    Continue
                </Button>
            </form>
        </Card>
    </div>
  )
}

export default EmployerStepTwo