import React, { useEffect, useState } from 'react';
import Header from '../component/Header';
import MessageInfo from '../component/MessageInfo';
import { useSelector } from 'react-redux';
import axios from 'axios';
import Skeleton from '../component/Skeleton';
import { FaBell } from 'react-icons/fa';

const Notification = () => {
  const allNotifications = useSelector((state) => state.notification.notificationList);
  const [notificationDetails, setNotificationDetails] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const currentUser = useSelector((state) => state.user.currentUser);
  const userType = currentUser.userType;

  useEffect(() => {
    const fetchAllNotification = async () => {
      setIsLoading(true);
      try {
        const notificationData = await Promise.all(
          allNotifications.map(async (eachNotification) => {
            const response = await axios.post(
              `${process.env.REACT_APP_API_URL}notification/getANotification`,
              { notificationId: eachNotification }
            );
            return response.data;
          })
        );
        setNotificationDetails(notificationData);
      } catch (error) {
        console.error('Error fetching notifications:', error);
      } finally {
        setIsLoading(false);
      }
    };
    fetchAllNotification();
  }, [allNotifications]);

  return (
    <div className="min-h-screen bg-gray-50 pt-16 sm:pt-20">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-8">
        <Header />
        
        <div className="mt-8">
          <div className="flex items-center gap-3 mb-6">
            <FaBell className="w-6 h-6 text-gray-400" />
            <h1 className="text-2xl font-semibold text-gray-900">Notifications</h1>
          </div>

          {isLoading ? (
            <Skeleton />
          ) : (
            <div className="space-y-4">
              {notificationDetails.map((eachDetails, index) => (
                <MessageInfo 
                  key={index} 
                  data={eachDetails} 
                  userType={userType} 
                />
              ))}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Notification;