// import { useState } from 'react';
// import { PaystackButton } from 'react-paystack';

// const usePayment = (email, amount, onSuccess, onClose) => {
//   const [loading, setLoading] = useState(false);
//   const [error, setError] = useState(null);
//   const publicKey = process.env.REACT_APP_PAYSTACK_PUBLIC_KEY

//   const handlePaystackSuccessAction = (reference) => {
//     setLoading(false);
//     const verifyPayment = async () => {
//       try {
//         const response = await fetch('/api/verify-payment', {
//           method: 'POST',
//           headers: {
//             'Content-Type': 'application/json',
//           },
//           body: JSON.stringify({ reference: reference.reference }),
//         });

//         const result = await response.json();
//         if (result.status === 'success') {
//           onSuccess(result);
//         } else {
//           setError('Payment verification failed');
//         }
//       } catch (err) {
//         setError('Error verifying payment');
//         console.log('error ---> ',err)
//         console.error(err);
//       }
//     };

//     verifyPayment();
//   };

//   const handlePaystackCloseAction = () => {
//     setLoading(false);
//     onClose();
//   };

//   const initiatePayment = () => {
//     setLoading(true);
//     setError(null);
//   };

//   const PaymentComponent = () => (
//     <PaystackButton
//       publicKey={publicKey}
//       email={email}
//       amount={amount * 100} // Paystack requires amount in kobo
//       text="Pay Now"
//       // onSuccess={handlePaystackSuccessAction}
//       onSuccess={(data)=>{console.log('paystack response data ----> ',data)}}
//       onClose={handlePaystackCloseAction}
//     />
//   );

//   return {
//     PaymentComponent,
//     initiatePayment,
//     loading,
//     error
//   };
// };

// export default usePayment;


// ------------ version 2 ------------
import { useState } from 'react';
import { BiCheck } from 'react-icons/bi';
import { PaystackButton } from 'react-paystack';

const usePayment = (email, apiKey, amount, onSuccess, onClose) => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [paymentSuccess, setPaymentSuccess] = useState(false)

  const handlePaystackSuccessAction = (reference) => {
    setLoading(false);
    onSuccess(reference)
    setPaymentSuccess(true)
    // verifyPayment();
  };

  const handlePaystackCloseAction = () => {
    setLoading(false);
    onClose();
  };

  const initiatePayment = () => {
    setLoading(true);
    setError(null);
  };

  const PaymentComponent = () => (
    <PaystackButton
      email={email}
      amount={amount * 100} 
      publicKey={apiKey}
      text={paymentSuccess ? <BiCheck/> : 'PayNow'}
      onSuccess={handlePaystackSuccessAction}
      onClose={handlePaystackCloseAction}
    />
  );

  return {
    PaymentComponent,
    initiatePayment,
    loading,
    error,
  };
};

export default usePayment;
