import React from 'react';

function StatsForm({ error, setFormPassword, handleCheck }) {
  return (
    <div className="mt-8 p-6 bg-gray-800 rounded-lg shadow-md max-w-md mx-auto">
      <h2 className="text-xl font-semibold mb-4 text-white">View Statistics</h2>
      <form onSubmit={handleCheck} className="space-y-4">
        <input
          type="password"
          placeholder="Enter password"
          onChange={(e) => setFormPassword(e.target.value)}
          className="w-full px-4 py-2 bg-gray-700 text-white rounded-md focus:outline-none focus:ring-2 focus:ring-blue-400"
        />
        {error && <p className="text-red-500 text-sm">Incorrect password. Please try again.</p>}
        <button
          type="submit"
          className="w-full px-4 py-2 bg-blue-600 text-white rounded-md hover:bg-blue-700 transition-colors"
        >
          Submit
        </button>
      </form>
    </div>
  );
}

export default StatsForm;