// import React, { useState } from 'react'
// import { BiCheck } from 'react-icons/bi'
// import Naira from '../assets/Images/naira.png'
// import { formatAmount } from '../Utils/formatAmount'
// import usePayment from '../hooks/usePayment'
// import { PAYSTACK_EMAIL } from '../Utils/constant'

// const JobCategory = ({plan_name, plan_price, plan_benefits, categorySelection, setCategorySelection, setPaymentReference, setJobType, close}) => {
//     const newAmount = formatAmount(plan_price)
//     const [paymentDone, setPaymentDone] = useState(false)
//     const email = PAYSTACK_EMAIL

//     const { PaymentComponent, initiatePayment } = usePayment(
//         email, 
//         process.env.REACT_APP_PAYSTACK_PUBLIC_KEY,
//         plan_price, 
//         (paymentDetails) => {
//             console.log('Payment successful', paymentDetails);
//             setPaymentDone(true)
//             setCategorySelection(plan_name)
//             setJobType(plan_name)
//             setPaymentReference(paymentDetails)
//         },
//         () => {
//             // Handle payment closure
//             close()
//         }
//     );

//     const handleCheck = (selected_paln)=>{
//         if(selected_paln) setCategorySelection(selected_paln)
//         close()
//     }

//   return (
//     <div className='relative font-mon flex flex-col items-center justify-start gap-3 w-[300px] min-h-[600px] h-fit border-2 border-gray-300 p-4 rounded-md'>
//         <h2 className='text-2xl text-green-600 font-bold'>{plan_name}</h2>
//         <div className='flex items-center justify-center gap-1 py-2'>
//            <img className='w-5 h-5' src={Naira} alt='naira'/>
//             <h1 className='text-[2rem] text-gray-600 font-bold'>{newAmount}</h1>
//             <span>/month</span>
//         </div>
//         <hr/>
//         <div className='text-start mb-12'>
//         {
//             plan_benefits.map((each_benefit)=>{
//                 return (<div className='flex items-start justify-start'>
//                     <BiCheck className='text-green-500 mr-2 w-5 h-5' />
//                     <p className='text-gray-800' key={each_benefit}>{each_benefit}</p>
//                 </div>)
//             })
//         }
//         </div>
//         {plan_name !== 'Free Plan' ? <button onClick={initiatePayment} className={`absolute border-2 border-green-500 hover:bg-white hover:text-green-500 bottom-1 left-[25%] text-lg  ${paymentDone ? 'bg-green-500' : 'bg-green-500'} text-white font-semibold rounded-full px-10 py-2`}>
//             <PaymentComponent/>
//         </button> :
//         <button onClick={()=>{handleCheck(plan_name)}} className='absolute border-2 border-green-500 hover:bg-white hover:text-green-500 bottom-1 left-[25%] text-lg  bg-green-500 text-white font-semibold rounded-full px-10 py-2'>
//             {
//                 categorySelection === 'Free Plan' ? 
//                 <BiCheck className='font-semibold'/> : <p>Continue</p>
//             }
//         </button>
//         }
//     </div>
//   )
// }

// export default JobCategory


// ------- version 2 -------
import React, { useState } from 'react';
import { BiCheck } from 'react-icons/bi';
import Naira from '../assets/Images/naira.png';
import { formatAmount } from '../Utils/formatAmount';
import usePayment from '../hooks/usePayment';
import { PAYSTACK_EMAIL } from '../Utils/constant';

const JobCategory = ({
  plan_name,
  plan_price,
  plan_benefits,
  categorySelection,
  setCategorySelection,
  setPaymentReference,
  setJobType,
  close,
}) => {
  const newAmount = formatAmount(plan_price);
  const [paymentDone, setPaymentDone] = useState(false);
  const email = PAYSTACK_EMAIL;

  const { PaymentComponent, initiatePayment } = usePayment(
    email,
    process.env.REACT_APP_PAYSTACK_PUBLIC_KEY,
    plan_price,
    (paymentDetails) => {
      console.log('Payment successful', paymentDetails);
      setPaymentDone(true);
      setCategorySelection(plan_name);
      setJobType(plan_name);
      setPaymentReference(paymentDetails);
    },
    () => close()
  );

  const handleCheck = (selected_plan) => {
    if (selected_plan) setCategorySelection(selected_plan);
    close();
  };

  return (
    <div className="relative font-mon flex flex-col items-center justify-start gap-4 w-[300px] min-h-[900px] h-fit border border-gray-200 shadow-md p-6 rounded-lg bg-white">
      <h2 className="text-2xl text-green-600 font-bold">{plan_name}</h2>
      <div className="flex items-center justify-center gap-1 py-2">
        <img className="w-5 h-5" src={Naira} alt="Naira symbol" />
        <h1 className="text-[2rem] text-gray-600 font-bold">{newAmount}</h1>
        <span>/month</span>
      </div>
      <hr className="w-full border-gray-200" />
      <div className="text-start mb-12 w-full">
        {plan_benefits.map((benefit) => (
          <div key={benefit} className="flex items-start justify-start">
            <BiCheck className="text-green-500 mr-2 w-5 h-5" />
            <p className="text-gray-800">{benefit}</p>
          </div>
        ))}
      </div>
      {plan_name !== 'Free Plan' ? (
        <button
          onClick={initiatePayment}
          disabled={paymentDone}
          className={`absolute bottom-4 left-1/2 transform -translate-x-1/2 text-lg font-semibold px-6 py-2 rounded-md border-2 border-green-500 ${paymentDone ? 'bg-green-500 text-white' : 'bg-green-500 text-white'} hover:bg-white hover:text-green-500 focus:outline-none focus:ring-2 focus:ring-green-500`}
          aria-label={`Initiate payment for ${plan_name}`}
        >
          {paymentDone ? (
            <div className="flex items-center gap-2">
              <BiCheck className="w-5 h-5" /> <span>Paid</span>
            </div>
          ) : (
            <PaymentComponent />
          )}
        </button>
      ) : (
        <button
          onClick={() => handleCheck(plan_name)}
          className="absolute bottom-4 left-1/2 transform -translate-x-1/2 text-lg font-semibold px-6 py-2 rounded-md border-2 border-green-500 bg-green-500 text-white hover:bg-white hover:text-green-500 focus:outline-none focus:ring-2 focus:ring-green-500"
          aria-label={`Select ${plan_name}`}
        >
          {categorySelection === 'Free Plan' ? 'Selected' : 'Select'}
        </button>
      )}
    </div>
  );
};

export default JobCategory;