import React from "react";
import CvInfo from "./CvInfo";
import CvEducation from "./CvEducation";
import CvWorkExperience from "./CvWorkExperience";
import CvCertificate from "./CvCertificate";
import CvGuarantor from "./CvGuarantor";
import { useSelector } from "react-redux";
import PDFGenerator from "./pdf/PDFGenerator";
import { FaCheckCircle, FaCalendar } from 'react-icons/fa';

function CvTemplate3({education, experience, certificate, guarantor}) {
  const loggedInUser = useSelector(state => state.user);
  const currentDate = new Date().toLocaleDateString('en-GB');
  
  return (
    <PDFGenerator>
      <div className="min-h-[297mm] w-[210mm] mx-auto bg-gray-50 relative">
        {/* Header Bar */}
        <div className="bg-gradient-to-r from-purple-600 to-indigo-600 text-white p-6">
          <div className="flex items-center justify-between">
            <div className="flex items-center gap-4">
              <h1 className="text-2xl font-bold">e-Job CV</h1>
              <button className="px-4 py-2 bg-white/20 hover:bg-white/30 rounded-lg backdrop-blur-sm flex items-center gap-2 transition-colors">
                <FaCheckCircle className="text-green-300" />
                e-verify log
              </button>
            </div>
            <div className="text-right">
              <p className="text-white/80">Date Generated</p>
              <div className="flex items-center gap-2">
                <FaCalendar className="text-white/60" />
                <span>{currentDate}</span>
              </div>
            </div>
          </div>
        </div>

        <div className="flex">
          {/* Sidebar */}
          <div className="w-1/3 bg-gradient-to-b from-purple-700 to-indigo-800 text-white min-h-[calc(297mm-4rem)] p-8">
            <div className="sticky top-8">
              <div className="mb-8">
                <h2 className="text-2xl font-bold mb-4">
                  {`${loggedInUser.currentUser.firstName} ${loggedInUser.currentUser.lastName}`}
                </h2>
                <p className="text-purple-200 text-sm">Professional Profile</p>
              </div>

              <div className="space-y-6">
                <div className="border-l-2 border-purple-400 pl-4">
                  <CvInfo 
                    third={true} 
                    title="Date of Birth" 
                    data={loggedInUser.currentUser.dateOfBirth}
                    className="text-purple-100" 
                  />
                </div>
                <div className="border-l-2 border-purple-400 pl-4">
                  <CvInfo 
                    third={true} 
                    title="Email" 
                    data={loggedInUser.currentUser.email}
                    className="text-purple-100"
                  />
                </div>
                <div className="border-l-2 border-purple-400 pl-4">
                  <CvInfo 
                    third={true} 
                    title="Address" 
                    data={loggedInUser.currentUser.address}
                    className="text-purple-100"
                  />
                </div>
              </div>
            </div>
          </div>

          {/* Main Content */}
          <div className="flex-1 p-8 bg-white shadow-xl">
            {/* Education Section */}
            <section className="mb-10">
              <h3 className="text-xl font-bold text-gray-800 mb-6 border-b-2 border-purple-200 pb-2">
                Education Background
              </h3>
              <div className="space-y-4">
                {education?.map((eachEducation, index) => (
                  <CvEducation
                    key={index}
                    school={eachEducation?.school}
                    course={eachEducation?.course}
                    year={`${eachEducation?.startYear} - ${eachEducation?.finishYear}`}
                  />
                ))}
              </div>
            </section>

            {/* Work Experience Section */}
            <section className="mb-10">
              <h3 className="text-xl font-bold text-gray-800 mb-6 border-b-2 border-purple-200 pb-2">
                Work Experience
              </h3>
              <div className="space-y-6">
                {experience?.map((eachExperience, index) => (
                  <CvWorkExperience
                    key={index}
                    title={eachExperience?.positionHeld}
                    companyName={eachExperience?.companyName}
                    year={`${eachExperience?.startYear} - ${eachExperience?.finishYear}`}
                    jobDescription={eachExperience?.jobDescription}
                  />
                ))}
              </div>
            </section>

            {/* Certificates Section */}
            <section className="mb-10">
              <h3 className="text-xl font-bold text-gray-800 mb-6 border-b-2 border-purple-200 pb-2">
                Certificates
              </h3>
              <div className="grid grid-cols-2 gap-4">
                {certificate?.map((eachCertificate, index) => (
                  <CvCertificate
                    key={index}
                    title={eachCertificate?.certificateName}
                    year={eachCertificate?.issueDate}
                    serialNumber={eachCertificate?.serialNumber}
                  />
                ))}
              </div>
            </section>

            {/* Guarantor Section */}
            <section className="mb-10">
              <h3 className="text-xl font-bold text-gray-800 mb-6 border-b-2 border-purple-200 pb-2">
                Guarantor
              </h3>
              <div className="grid grid-cols-2 gap-4">
                {guarantor?.map((eachGuarantor, index) => (
                  <CvGuarantor
                    key={index}
                    guarantorName={eachGuarantor?.name}
                    phoneNumber={eachGuarantor?.phone}
                  />
                ))}
              </div>
            </section>
          </div>
        </div>
      </div>
    </PDFGenerator>
  );
}

export default CvTemplate3;
