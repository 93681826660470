import React, { useState } from 'react';
import { Card, CardHeader, CardTitle, CardContent } from './Card';
import { Dialog, DialogTitle } from './DialogBox'
import { FaCalendar, FaGraduationCap, FaPlus } from 'react-icons/fa';
import { useMakeRequest } from '../../hooks/useMakeRequest';
import { EDUCATION_URL } from '../../resources/resource';
import { useSelector } from 'react-redux';
import { Spinner } from 'flowbite-react';


const EducationList = ({refetch, userEducation}) => {
  const loggedInUser = useSelector((state)=>state.user.currentUser)
  const [isOpen, setIsOpen] = useState(false);
  const [loading, setLoading] = useState(false)
  const [loadingDelete, setLoadingDelete] = useState(false)
  const [formData, setFormData] = useState({
    school: "",
    degree: "",
    field: "",
    startYear: "",
    finishYear: "",
    gpa: "",
    achievements: ""
  });
  const makeRequest = useMakeRequest()

  const handleSubmit = (e) => {
    e.preventDefault();
    const onSuccess = ()=>{
        refetch()
        setLoading(false)
      }
      
    const onFailure = (error)=>{
      console.log(error)
    }

    const onFinal = ()=>{
      setFormData({
        school: "",
        course: "",
        country: "",
        state: "",
        startYear: "",
        finishYear: "",
        gpa: "",
        certificate: "",
      });
      setIsOpen(false)
    }

    const payload = {...formData, user: loggedInUser?._id}
    makeRequest(
      EDUCATION_URL + '/createUserEducation',
      'POST',
      payload,
      onSuccess,
      onFailure,
      onFinal
    )
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData(prev => ({
      ...prev,
      [name]: value
    }));
  };

  const handleDelete = (educationId)=>{
    setLoadingDelete(true)
    const onSuccess = (data)=>{
      refetch()
    }

    const onFailure = (error)=>{
      console.log(error)
    }

    const onFinal = ()=>{
      setLoadingDelete(false)
    }

    makeRequest(
      EDUCATION_URL + `/deleteUserEducation/${educationId}`,
      'DELETE',
      {},
      onSuccess,
      onFailure,
      onFinal
    )
  }

  return (
    <div className="p-6 max-w-4xl mx-auto">
      <div className="flex justify-between items-center mb-6">
        <h2 className="text-2xl font-semibold">Education</h2>
        <button
          onClick={() => setIsOpen(true)}
          className="flex items-center gap-2 px-4 py-2 bg-green-600 text-white rounded-md hover:bg-green-700 transition-colors"
        >
          <FaPlus className="w-4 h-4" />
          Add Education
        </button>
      </div>

      <div className="space-y-4">
        {userEducation?.map((edu) => (
          <Card key={edu.id}>
            <CardHeader>
              <div className="flex justify-between items-start">
                <div>
                  <CardTitle>{edu.course}</CardTitle>
                  <div className="flex items-center gap-2 text-gray-600 mt-1">
                    <FaGraduationCap className="w-4 h-4" />
                    {edu.school} • {edu.country}
                  </div>
                </div>
                <div className="flex items-center gap-2 text-gray-600">
                  <FaCalendar className="w-4 h-4" />
                  <span>
                    {new Date(edu.startYear).toLocaleDateString('en-US', { month: 'short', year: 'numeric' })} -{' '}
                    {edu.endYear 
                      ? new Date(edu.endYear).toLocaleDateString('en-US', { month: 'short', year: 'numeric' })
                      : 'Present'}
                  </span>
                </div>
              </div>
            </CardHeader>
            <CardContent className='flex items-end justify-between'>
              <div className="text-gray-600">
                {edu.gpa && <p className="mb-2">GPA: {edu.gpa}</p>}
                {edu.certificate && <p>{edu.certificate}</p>}
              </div>
              <button onClick={()=>{handleDelete(edu?._id)}} className='px-4 py-[2.5px] rounded-md bg-red-400 hover:bg-red-500 text-white'>
                {
                  loadingDelete ? <Spinner/> : 'Delete'
                }
              </button>
            </CardContent>
          </Card>
        ))}
      </div>

      <Dialog isOpen={isOpen} onClose={() => setIsOpen(false)}>
        <DialogTitle>Add Education</DialogTitle>
        <form onSubmit={handleSubmit} className="space-y-4">
          <div>
            <label className="block text-sm font-medium mb-1">School/University</label>
            <input
              type="text"
              name="school"
              value={formData.school}
              onChange={handleInputChange}
              className="w-full p-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
              required
            />
          </div>
          <div>
            <label className="block text-sm font-medium mb-1">Course</label>
            <input
              type="text"
              name="course"
              value={formData.course}
              onChange={handleInputChange}
              className="w-full p-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
              required
            />
          </div>
          <div>
            <label className="block text-sm font-medium mb-1">country</label>
            <input
              type="text"
              name="country"
              value={formData.country}
              onChange={handleInputChange}
              className="w-full p-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
              required
            />
          </div>
          <div>
            <label className="block text-sm font-medium mb-1">State</label>
            <input
              type="text"
              name="state"
              value={formData.state}
              onChange={handleInputChange}
              className="w-full p-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
              required
            />
          </div>
          <div className="grid grid-cols-2 gap-4">
            <div>
              <label className="block text-sm font-medium mb-1">Start Date</label>
              <input
                type="date"
                name="startYear"
                value={formData.startYear}
                onChange={handleInputChange}
                className="w-full p-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                required
              />
            </div>
            <div>
              <label className="block text-sm font-medium mb-1">End Date</label>
              <input
                type="date"
                name="finishYear"
                value={formData.finishYear}
                onChange={handleInputChange}
                className="w-full p-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
              />
            </div>
          </div>
          <div>
            <label className="block text-sm font-medium mb-1">GPA</label>
            <input
              type="text"
              name="gpa"
              value={formData.gpa}
              onChange={handleInputChange}
              className="w-full p-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
            />
          </div>
          <div>
            <label className="block text-sm font-medium mb-1">Certificates</label>
            <textarea
              name="certificate"
              value={formData.certificate}
              onChange={handleInputChange}
              className="w-full p-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
              rows="3"
            />
          </div>
          <div className="flex justify-end gap-4 mt-6">
            <button
              type="button"
              onClick={() => setIsOpen(false)}
              className="px-4 py-2 border rounded-md hover:bg-gray-100 transition-colors"
            >
              Cancel
            </button>
            <button
              type="submit"
              className="px-4 py-2 bg-blue-600 text-white hover:bg-blue-700 transition-colors"
            >
              {loading ? <Spinner/> : 'Add Education'}
            </button>
          </div>
        </form>
      </Dialog>
    </div>
  );
};

export default EducationList;