import { useEffect } from "react";
import { axiosAuth } from "../axios/axios";

// Separate refresh token function for cleaner code
async function refreshAccessToken() {
    const refreshToken = localStorage.getItem('_dl_refresh_token');
    
    try {
        const response = await axiosAuth.post('/auth/refresh', {
            refresh_token: refreshToken
        });
        
        const { access_token, refresh_token } = response.data;
        
        localStorage.setItem('_dl_token', access_token);
        localStorage.setItem('_dl_refresh_token', refresh_token);
        
        return access_token;
    } catch (error) {
        localStorage.removeItem('_dl_token');
        localStorage.removeItem('_dl_refresh_token');
        throw error;
    }
}

export function useAxiosAuth() {
    useEffect(() => {
        const requestIntercept = axiosAuth.interceptors.request.use(
            (config) => {
                const token = localStorage.getItem('_dl_token');
                
                if (!config?.headers?.['Authorization'] && token) {
                    config.headers['Authorization'] = `Bearer ${token}`;
                }
                return config;
            },
            (error) => Promise.reject(error)
        );

        const responseIntercept = axiosAuth.interceptors.response.use(
            (response) => response,
            async (error) => {
                const originalRequest = error.config;
                
                // Check if error is 401 and we haven't tried refreshing yet
                if (error.response?.status === 401 && !originalRequest?._retry) {
                    originalRequest._retry = true;
                    
                    try {
                        const newAccessToken = await refreshAccessToken();
                        axiosAuth.defaults.headers.common['Authorization'] = `Bearer ${newAccessToken}`;
                        return axiosAuth(originalRequest);
                    } catch (refreshError) {
                        // Redirect to login if refresh fails
                        window.location.href = '/login';
                        return Promise.reject(refreshError);
                    }
                }
                
                return Promise.reject(error);
            }
        );

        return () => {
            axiosAuth.interceptors.request.eject(requestIntercept);
            axiosAuth.interceptors.response.eject(responseIntercept);
        };
    }, []);

    return axiosAuth;
}