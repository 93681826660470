// import { Link } from "react-router-dom";
// import Header from "../component/Header";
// import FooterComponent from "../component/Footer";
// import { useSelector } from "react-redux";
// import { useNavigate } from "react-router-dom";
// import { toast } from "react-toastify";


// function Eja() {
//   const loggedInUser = useSelector((state) => state.user);
//   const navigate = useNavigate()


//   const handleBenefit = ()=>{
//     if(loggedInUser.currentUser.userType === 'jobSeeker'){
//       navigate('/benefit')
//     }else if(loggedInUser.currentUser.userType === 'jobEmployer'){
//       toast.info('No eligible')
//       return
//     }else{
//       navigate('/signin')
//     }
//   }
//   return (
//     <div className="w-full pt-16 sm:pt-20">
//       <div className="text-sm">
//         <Header darkMode={true} />
//         <div className="text-sm px-10">
//           <div className="mx-18 w-full flex items-end justify-end">
//             <button className="px-4 py-1 bg-green-500 text-slate-50">
//               {
//                 loggedInUser.currentUser ?
//                 <button onClick={handleBenefit}>Opt in for a benefit</button> : 
//                 <Link to="/signin">Log in for a benefit</Link>}
//             </button>
//           </div>
//           <h2 className="text-slate-500 font-semibold py-4">
//             NFRASTRUCTURAL IMPLEMENTATION OF MEMBERSHIP BENEFITS THROUGH THE
//             PAYMENT OF 15% -20% OFF SALARY TO COVER HEALTH, UNEMPLOYMENT,
//             HOUSING, PENSION AND MANY MORE BENEFITS
//           </h2>
//           <p>
//             fIn an era where the well-being of employees is gaining increasing
//             importance, businesses are seeking innovative ways to provide
//             comprehensive benefits packages. At Ejobs, we recognize the
//             significance of employee welfare and are proud to introduce a
//             revolutionary approach to membership benefits.{" "}
//           </p>
//           <p>
//             We understand that employees are the backbone of any organization,
//             and their health, financial security, and overall well-being are
//             paramount. Therefore, we are excited to announce our new
//             infrastructural implementation of membership benefits, which
//             includes an option for employees to contribute 15% to 20% of their
//             salary to cover a wide range of essential benefits.
//           </p>
//           <h3 className="mt-4 mb-1 text-xl font-semibold">Comprehensive Coverage:</h3>
//           <p>
//             Our membership benefits program is designed to cover various aspects
//             of an employee&apos;s life, ensuring their peace of mind and
//             security:
//           </p>
//           <ol>
//             <li>
//               Health Benefits: Comprehensive health coverage including medical,
//               dental, and vision care.
//             </li>
//             <li>
//               Unemployment Benefits: Protection against unexpected job loss,
//               ensuring financial stability during challenging times.
//             </li>
//             <li>
//               Housing Benefits: Assistance with housing expenses, helping
//               employees secure safe and comfortable living arrangements.
//             </li>
//             <li>
//               Pension Benefits: Building a secure financial future through a
//               robust pension plan.
//             </li>
//             <li>
//               Education Benefits: Assistance with educational expenses,
//               empowering employees to pursue personal and professional
//               development.{" "}
//             </li>
//             <li>
//               Wellness Benefits: Programs and initiatives aimed at promoting
//               physical, mental, and emotional well-being.
//             </li>
//             <li>
//               *Retirement Benefits: Comprehensive retirement plans to ensure
//               financial security post-employment.
//             </li>
//           </ol>
//           <h3 className="mt-4 mb-1 text-xl font-semibold">Key Features of Our Program: </h3>
//           <ol>
//             <li>
//               Flexible Contribution Options: Employees can choose to contribute
//               between 15% to 20% of their salary towards their preferred
//               benefits, providing them with flexibility based on their
//               individual needs and preferences.
//             </li>
//             <li>
//               Customizable Benefits Packages: Our program allows employees to
//               tailor their benefits package according to their unique
//               requirements, ensuring that they receive the coverage they need
//               most.
//             </li>
//             <li>
//               Seamless Integration: Our infrastructural implementation ensures a
//               seamless and hassle-free experience for both employees and
//               employers. We handle all aspects of benefit administration, from
//               enrollment to ongoing management, allowing employers to focus on
//               their core business activities.
//             </li>
//             <li>
//               Expert Guidance: Our team of benefits experts is dedicated to
//               providing personalized guidance and support to employees, ensuring
//               that they fully understand their benefits and how to make the most
//               of them.
//             </li>
//           </ol>
//           <h3 className="mt-4 mb-1 text-xl font-semibold">Benefits for Employers: </h3>
//           <ol>
//             <li>
//               Attract and Retain Top Talent: Offering comprehensive benefits
//               demonstrates a commitment to employee well-being, making your
//               company more attractive to top talent and helping to retain
//               existing employees.
//             </li>
//             <li>
//               Enhanced Productivity and Morale: Employees who feel valued and
//               supported are more motivated, engaged, and productive, leading to
//               better business outcomes.
//             </li>
//             <li>
//               Reduced Turnover and Absenteeism: By providing essential benefits
//               such as health coverage and financial security, you can reduce
//               turnover rates and absenteeism, saving time and money on
//               recruiting and training new employees.
//             </li>
//           </ol>
//           <h3 className="mt-4 mb-1 text-xl font-semibold">Conclusion: </h3>
//           <p>
//             At [Company Name], we believe that our employees are our most
//             valuable asset, and their well-being is our top priority. With our
//             new infrastructural implementation of membership benefits, we are
//             redefining the employee benefits landscape, providing comprehensive
//             coverage and peace of mind to our valued team members.
//           </p>
//           <p>
//             Join us in prioritizing employee welfare and building a brighter,
//             more secure future for all.{" "}
//           </p>
//           <p>[Company Name] - Empowering Employees, Enriching Lives.</p>
//         </div>
//       </div>
//       <FooterComponent/>
//     </div>
//   );
// }

// export default Eja;

// -------- version 2 ---------
import { Link } from "react-router-dom";
import Header from "../component/Header";
import FooterComponent from "../component/Footer";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "sonner";

function Eja() {
  const loggedInUser = useSelector((state) => state.user);
  const navigate = useNavigate();

  const handleBenefit = () => {
    if (loggedInUser?.currentUser?.userType === "jobSeeker") {
      navigate("/benefit");
    } else if (loggedInUser?.currentUser?.userType === "jobEmployer") {
      toast.info("Not eligible");
      return;
    } else {
      navigate("/signin");
    }
  };

  return (
    <div className="min-h-screen bg-gray-50">
      <Header darkMode={true} />
      
      {/* Hero Section */}
      <div className="pt-16 sm:pt-20">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          {/* CTA Button */}
          <div className="flex justify-end mb-8">
            <button
              onClick={loggedInUser.currentUser ? handleBenefit : () => navigate("/signin")}
              className="px-6 py-2 bg-gradient-to-r from-green-500 to-green-600 text-white rounded-lg shadow-md hover:shadow-lg transform hover:-translate-y-0.5 transition-all duration-200 text-sm font-medium"
            >
              {loggedInUser.currentUser ? "Opt in for benefits" : "Log in for benefits"}
            </button>
          </div>

          {/* Main Content */}
          <div className="bg-white rounded-xl shadow-sm p-8 mb-8">
            {/* Title Section */}
            <h1 className="text-2xl sm:text-3xl font-bold text-gray-900 mb-6">
              Employee Benefits Program
            </h1>
            <h2 className="text-lg text-gray-600 font-semibold mb-8 leading-relaxed">
              INFRASTRUCTURAL IMPLEMENTATION OF MEMBERSHIP BENEFITS THROUGH THE PAYMENT 
              OF 15% - 20% OFF SALARY TO COVER HEALTH, UNEMPLOYMENT, HOUSING, PENSION 
              AND MANY MORE BENEFITS
            </h2>

            {/* Introduction */}
            <div className="space-y-4 text-gray-600 mb-8">
              <p>
                In an era where the well-being of employees is gaining increasing importance,
                businesses are seeking innovative ways to provide comprehensive benefits packages.
                At Ejobs, we recognize the significance of employee welfare and are proud to
                introduce a revolutionary approach to membership benefits.
              </p>
              <p>
                We understand that employees are the backbone of any organization, and their
                health, financial security, and overall well-being are paramount. Therefore,
                we are excited to announce our new infrastructural implementation of membership
                benefits, which includes an option for employees to contribute 15% to 20% of
                their salary to cover a wide range of essential benefits.
              </p>
            </div>

            {/* Benefits Sections */}
            <div className="space-y-8">
              {/* Comprehensive Coverage */}
              <section>
                <h3 className="text-xl font-semibold text-gray-900 mb-4">
                  Comprehensive Coverage
                </h3>
                <p className="text-gray-600 mb-4">
                  Our membership benefits program is designed to cover various aspects of an
                  employee's life, ensuring their peace of mind and security:
                </p>
                <div className="grid sm:grid-cols-2 gap-4">
                  {[
                    {
                      title: "Health Benefits",
                      description: "Comprehensive health coverage including medical, dental, and vision care."
                    },
                    {
                      title: "Unemployment Benefits",
                      description: "Protection against unexpected job loss, ensuring financial stability."
                    },
                    {
                      title: "Housing Benefits",
                      description: "Assistance with housing expenses for comfortable living arrangements."
                    },
                    {
                      title: "Pension Benefits",
                      description: "Building a secure financial future through a robust pension plan."
                    },
                    {
                      title: "Education Benefits",
                      description: "Assistance with educational expenses for personal and professional development."
                    },
                    {
                      title: "Wellness Benefits",
                      description: "Programs promoting physical, mental, and emotional well-being."
                    }
                  ].map((benefit, index) => (
                    <div key={index} className="bg-gray-50 p-4 rounded-lg">
                      <h4 className="font-medium text-gray-900 mb-2">{benefit.title}</h4>
                      <p className="text-sm text-gray-600">{benefit.description}</p>
                    </div>
                  ))}
                </div>
              </section>

              {/* Key Features */}
              <section>
                <h3 className="text-xl font-semibold text-gray-900 mb-4">
                  Key Features of Our Program
                </h3>
                <div className="space-y-4">
                  {[
                    {
                      title: "Flexible Contribution Options",
                      description: "Choose to contribute between 15% to 20% of salary towards preferred benefits."
                    },
                    {
                      title: "Customizable Benefits Packages",
                      description: "Tailor benefits package according to unique requirements and needs."
                    },
                    {
                      title: "Seamless Integration",
                      description: "Hassle-free experience with complete benefit administration management."
                    },
                    {
                      title: "Expert Guidance",
                      description: "Dedicated support team providing personalized guidance on benefits."
                    }
                  ].map((feature, index) => (
                    <div key={index} className="flex gap-4">
                      <div className="flex-shrink-0 w-8 h-8 bg-green-100 rounded-full flex items-center justify-center">
                        <span className="text-green-600 font-medium">{index + 1}</span>
                      </div>
                      <div>
                        <h4 className="font-medium text-gray-900 mb-1">{feature.title}</h4>
                        <p className="text-gray-600">{feature.description}</p>
                      </div>
                    </div>
                  ))}
                </div>
              </section>

              {/* Employer Benefits */}
              <section>
                <h3 className="text-xl font-semibold text-gray-900 mb-4">
                  Benefits for Employers
                </h3>
                <div className="bg-gradient-to-r from-green-50 to-green-100 p-6 rounded-xl">
                  <div className="grid sm:grid-cols-3 gap-6">
                    <div>
                      <h4 className="font-medium text-gray-900 mb-2">Attract & Retain Talent</h4>
                      <p className="text-sm text-gray-600">
                        Demonstrate commitment to employee well-being and attract top talent.
                      </p>
                    </div>
                    <div>
                      <h4 className="font-medium text-gray-900 mb-2">Enhanced Productivity</h4>
                      <p className="text-sm text-gray-600">
                        Boost motivation and engagement through comprehensive support.
                      </p>
                    </div>
                    <div>
                      <h4 className="font-medium text-gray-900 mb-2">Reduced Turnover</h4>
                      <p className="text-sm text-gray-600">
                        Lower recruitment costs through better employee retention.
                      </p>
                    </div>
                  </div>
                </div>
              </section>

              {/* Conclusion */}
              <section className="border-t pt-8">
                <h3 className="text-xl font-semibold text-gray-900 mb-4">
                  Join Us in Prioritizing Employee Welfare
                </h3>
                <div className="text-gray-600 space-y-4">
                  <p>
                    At Ejobs, we believe that our employees are our most valuable asset, and
                    their well-being is our top priority. With our new infrastructural
                    implementation of membership benefits, we are redefining the employee
                    benefits landscape.
                  </p>
                  <p className="font-medium text-gray-900">
                    Ejobs - Empowering Employees, Enriching Lives.
                  </p>
                </div>
              </section>
            </div>
          </div>
        </div>
      </div>
      <FooterComponent />
    </div>
  );
}

export default Eja;