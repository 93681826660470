import { Label, TextInput } from "flowbite-react";

function ProfileData(props) {
  const {data, placeholder, tagName, handleChange} = props

  return (
    <div className="mb-4 rounded-sm relative w-full p-1">
      <Label htmlFor={data} className="absolute -top-4 bg-white">{tagName}</Label>
      <TextInput onChange={handleChange} id={data} name={data} className="w-full border-none outline-none" type="text" placeholder={placeholder} />
    </div>
  );
}

export default ProfileData;