// import React from 'react'

// function CvWorkExperience({title, year, companyName, jobDescription}) {
//   return (
//     <div className='mb-6'>
//         <div className='flex items-start justify-between'>
//             <h3 className='font-semibold'>{title}</h3>
//             <h3 className='font-semibold'>{year}</h3>
//         </div>
//         <h3 className='font-semibold mb-2'>{companyName}</h3>
//         <div className='ml-6'>
//             <h3 className='font-semibold'>Job Description</h3>
//             <p>{jobDescription}</p>
//         </div>
//     </div>
//   )
// }

// export default CvWorkExperience

// --------- version 2-------------
import React from 'react';
import { FaBriefcase } from 'react-icons/fa';

function CvWorkExperience({title, year, companyName, jobDescription}) {
  return (
    <div className='space-y-3'>
      <div className='flex items-start justify-between'>
        <div className="flex-1">
          <div className="flex items-center gap-2">
            <FaBriefcase className="text-gray-500" />
            <h3 className='font-semibold text-lg text-gray-800'>{title}</h3>
          </div>
          <h4 className='text-gray-600 mt-1'>{companyName}</h4>
        </div>
        <span className='text-sm bg-gray-100 px-3 py-1 rounded-full font-medium text-gray-600 whitespace-nowrap'>
          {year}
        </span>
      </div>
      <div className='pl-6 pt-2'>
        <h4 className='text-sm font-medium text-gray-700 mb-1'>Job Description</h4>
        <p className='text-gray-600 text-sm leading-relaxed'>{jobDescription}</p>
      </div>
      <div className="border-b border-gray-200 pt-2" />
    </div>
  );
}

export default CvWorkExperience;