import React, { useState, useCallback } from 'react';
import { CgMore } from 'react-icons/cg';
import { useNavigate } from 'react-router-dom';
import MessageModal from '../Modals/Mesage';

const GeneralTable = ({ currentList, handleNavigation, data }) => {
  const [viewMoreStates, setViewMoreStates] = useState({});
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [currentApplicant, setCurrentApplicant] = useState('');
  const navigate = useNavigate();

  const handleViewToggle = useCallback((index) => {
    setViewMoreStates(prev => ({
      ...prev,
      [index]: !prev[index]
    }));
  }, []);

  const handleModal = useCallback(() => {
    setModalIsOpen(prev => !prev);
  }, []);

  const selectApplicant = useCallback((applicantName) => {
    setCurrentApplicant(applicantName);
    handleModal();
  }, [handleModal]);

  const handleView = useCallback((jobId) => {
    navigate(`/${jobId}/applicantResponse`);
  }, [navigate]);

  return (
    <div className="mt-6 w-full">
      <div className="overflow-x-auto pb-20">
        <table className="min-w-full divide-y divide-gray-200">
          <thead className="bg-gray-50">
            <tr>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                S/N
              </th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                Name
              </th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                Description
              </th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                Date Applied
              </th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                Time Applied
              </th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                Review
              </th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                Remark
              </th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                Actions
              </th>
            </tr>
          </thead>
          <tbody className="bg-white divide-y divide-gray-200">
            {currentList.map((eachData, index) => (
              <tr 
                key={index} 
                className="hover:bg-gray-50 transition-colors duration-200"
              >
                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-700">
                  {index + 1}
                </td>
                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900 font-medium">
                  {`${eachData.applicant[0].firstName} ${eachData.applicant[0].lastName}`}
                </td>
                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-700">
                  {eachData.job.jobTitle}
                </td>
                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-700">
                  {new Date(eachData.createdAt).toLocaleDateString()}
                </td>
                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-700">
                  {new Date(eachData.createdAt).toLocaleTimeString([], { 
                    hour: '2-digit', 
                    minute: '2-digit' 
                  })}
                </td>
                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-700">
                  {eachData.availability}
                </td>
                <td className="px-6 py-4 whitespace-nowrap">
                  <span className={`px-2 py-1 inline-flex text-xs leading-5 font-semibold rounded-full ${
                    eachData.status === 'Rejected' ? 'bg-red-100 text-red-800' :
                    eachData.status === 'Shortlisted' ? 'bg-yellow-100 text-yellow-800' :
                    eachData.status === 'Hired' ? 'bg-green-100 text-green-800' :
                    'bg-blue-100 text-blue-800'
                  }`}>
                    {eachData.status}
                  </span>
                </td>
                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-700 relative z-20">
                  <div className="relative z-20 inline-block text-left">
                    <button
                      onClick={() => handleViewToggle(index)}
                      className="text-blue-600 hover:text-blue-800 transition-colors duration-200 focus:outline-none"
                      title="More actions"
                    >
                      <CgMore className="w-5 h-5" />
                    </button>
                    {viewMoreStates[index] && (
                      <div className="absolute right-0 mt-2 w-32 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 z-40">
                        <div className="py-1">
                          <button
                            onClick={() => selectApplicant(`${eachData.applicant[0].firstName} ${eachData.applicant[0].lastName}`)}
                            className="block w-full text-left px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900"
                          >
                            Message
                          </button>
                          <button
                            onClick={() => handleView(eachData.job._id)}
                            className="block w-full text-left px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900"
                          >
                            View
                          </button>
                        </div>
                      </div>
                    )}
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      {modalIsOpen && (
        <MessageModal 
          toggle={handleModal} 
          ApplicantName={currentApplicant} 
        />
      )}
    </div>
  );
};

export default React.memo(GeneralTable);