import React, { useRef, useState } from 'react'
import { FaUpload } from 'react-icons/fa';
import ProofCard from './ProofCard';
import { Dialog, DialogTitle } from '../Profile/DialogBox';
import { BsX } from 'react-icons/bs';
import { uploadToCloudinary } from '../../Utils/cloudinaryUpload';
import { Spinner } from 'flowbite-react';
import { useMakeRequest } from '../../hooks/useMakeRequest';
import { EXISTENCE_PROOF_URL } from '../../resources/resource';
import { useSelector } from 'react-redux';

const ProofSection = ({ title, proofs, refetch}) => {
  const [loading, setLoading] = useState(false)
  const [deleteLoading, setDeleteLoading] = useState(false)
  const user = useSelector(state=>state.user.currentUser)
  const imageRef = useRef(null)
  const [isOpen, setIsOpen] = useState(false)
  const [formData, setFormData] = useState({})
  const [fileData, setFileData] = useState(null)
  const [imagePreview, setImagePreview] = useState('')
  const makeRequest = useMakeRequest()
  
  const handleSubmit = async(e)=>{
    e.preventDefault()
    setLoading(true)
    // upload to cloudinary
    
    const imageURL = await uploadToCloudinary(fileData)
    const payload = {...formData, image: imageURL, companyId: user._id}
    // console.log('pay -->', payload)
    makeRequest(
      EXISTENCE_PROOF_URL + `/createProof`,
      'POST',
      payload,
      (data)=>{
        refetch()
        setIsOpen(false)
      },
      (error)=>{console.log(error)},
      ()=>{setLoading(false)}
    )
  }

  const onDelete = (proofId) =>{
    setDeleteLoading(true)
    makeRequest(
      EXISTENCE_PROOF_URL + `/deleteProof/${proofId}`,
      'DELETE',
      {},
      ()=>{
        refetch();
        setIsOpen(false)
      },
      (error)=>{console.log(error)},
      ()=>{setDeleteLoading(false)}
    )
  }

  const handleInputChange = (e)=>{
    e.preventDefault()
    const value = e.target.value
    const name = e.target.name
    setFormData({...formData, [name]: value})
  }

  const handleAddFile = (e)=>{
    e.preventDefault()
    const file = e.target.files[0]
    setFileData(file)
    const imageURL = URL.createObjectURL(file)
    setImagePreview(imageURL)
  }

  return (
    <div className="bg-white rounded-lg shadow-sm p-6">
    <div className="flex items-center justify-between mb-6">
      <h2 className="text-xl font-semibold text-gray-900">{title}</h2>
      <button
        onClick={()=>{setIsOpen(true)}}
        className="inline-flex items-center px-4 py-2 bg-gray-100 text-gray-700 rounded-lg hover:bg-gray-200 transition-colors duration-200"
      >
        <FaUpload className="w-4 h-4 mr-2" />
        Add New
      </button>
    </div>
    <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
      {proofs?.map((proof) => (
        <ProofCard
          key={proof?._id}
          title={proof?.title}
          name={proof?.name}
          description={proof?.description}
          imageSrc={proof?.image}
          onDelete={() => onDelete(proof._id)}
        />
      ))}
    </div>
    <Dialog isOpen={isOpen} onClose={()=> setIsOpen(false)}>
      <DialogTitle>Add Proof</DialogTitle>
      {imagePreview ? <div className='w-full h-[200px]'>
        <img className='w-full h-full object-cover' src={imagePreview} alt='Proof'/>
      </div> : null}
      {/* <BsX className='w-10 h-10 absolute top-3 right-3' onClick={setIsOpen(false)}/> */}
      <form onSubmit={handleSubmit} className='space-y-4'>
          <div>
            <label className="block text-sm font-medium mb-1">Proof Name</label>
            <input
              type="text"
              name="title"
              value={formData.title}
              placeholder='Cafeteria'
              onChange={handleInputChange}
              className="w-full p-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
              required
            />
          </div>
          <div>
            <label className="block text-sm font-medium mb-1">Proof Descrition</label>
            <textarea
              type="text"
              name="description"
              value={formData.description}
              placeholder='Whee our staff eat'
              onChange={handleInputChange}
              className="w-full p-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
              required
            />
          </div>
          <div>
            <label className="block text-sm font-medium mb-1">Proof Image</label>
            <input
              type="file"
              name="image"
              value={formData.certificateName}
              onChange={handleAddFile}
              className="w-full p-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
              required
            />
          </div>
          <div className='w-full flex items-center justify-center'>
            <button type='submit' className='mt-3 px-4 py-2 bg-green-400 hover:bg-green-500 text-white mx-auto rounded-md'>
              {loading ?
                <Spinner/> :
                "Add Proof"
              }
            </button>
          </div>
      </form>
    </Dialog>
  </div>
  )
}

export default ProofSection