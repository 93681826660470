import React from "react";
import { FaCertificate } from 'react-icons/fa';

function CvCertificate({ title, year, serialNumber, 
  issueDate }) {
  return (
    <div className="p-4 rounded-lg bg-gray-50 border border-gray-100">
      <div className="flex items-center justify-between">
        <div className="flex items-center gap-2">
          <FaCertificate className="text-gray-500" />
          <div>
            <h3 className="font-semibold text-gray-800">{title}</h3>
          </div>
        </div>
        <span className="text-sm bg-gray-100 px-3 py-1 rounded-full font-medium text-gray-600">
          {year}
        </span>
      </div>
      <p className="text-sm text-gray-500 mt-3">sn - {serialNumber}</p>
    </div>
  );
}

export default CvCertificate;