// import axios from 'axios';
// import React, { useState } from 'react';
// import { CgMore } from 'react-icons/cg';
// import { useDispatch } from 'react-redux';
// import { addNotification } from '../../redux/notification/notificationSlice';
// import { toast } from 'react-toastify';

// function ScheduleTable({ currentList, handleNavigation, data, title, handleInterview }) {
//   const [viewMoreStates, setViewMoreStates] = useState(Array(data.length).fill(false));
//   const updateApplication =  `${process.env.REACT_APP_API_URL}application/updateApplication`;
//   const dispatch = useDispatch()

//   const handleView = (index) => {
//     setViewMoreStates((prevStates) => {
//       const newStates = [...prevStates];
//       newStates[index] = !newStates[index];
//       return newStates;
//     });
//   };

//   const handleUpdate = async (applicationId)=>{
//     try{
//       const updateCurrentApplication = await axios.put(updateApplication, {applicationId:applicationId, status: 'Interviewed'})
//       dispatch(addNotification(updateCurrentApplication.data))
//       toast.success('Successfully updated')
//     }catch(error){
//       toast.error('Error try again')
//     }
//   }

//   return (
//     <div className='mt-6 text-sm'>
//       <table className='mx-auto'>
//         <thead>
//           <tr className='px-2 py-2 border-b-2 border-green-300'>
//             <th className='px-4 py-4'>S/N</th>
//             <th className="px-4 py-4 text-xs font-medium text-gray-500 uppercase tracking-wider">Name</th>
//             <th className="px-4 py-4 text-xs font-medium text-gray-500 uppercase tracking-wider">Description</th>
//             <th className="px-4 py-4 text-xs font-medium text-gray-500 uppercase tracking-wider">Date of interview</th>
//             <th className="px-4 py-4 text-xs font-medium text-gray-500 uppercase tracking-wider">Time of interview</th>
//             <th className="px-4 py-4 text-xs font-medium text-gray-500 uppercase tracking-wider">Time remaining</th>
//             <th className="px-4 py-4 text-xs font-medium text-gray-500 uppercase tracking-wider">Remark</th>
//             <th className="px-4 py-4 text-xs font-medium text-gray-500 uppercase tracking-wider">Action</th>
//           </tr>
//         </thead>
//         <tbody>
//           {data.map((eachData, index) => {
//             const timeRemaining = Date.now();
//             const timeOfInterview = eachData.interviews[index].interviewTime;
//             const dateOfInterview = eachData.interviews[index].interviewDate;
//             const interviewDay = new Date(`${dateOfInterview}T${timeOfInterview}:00`);
//             const timeLeftInMilliseconds = interviewDay - timeRemaining;
//             const timeLeftInHours = (timeLeftInMilliseconds / (3600000 * 24)).toFixed(2);

//             return (
//               <tr key={index} className="hover:bg-green-100 hover:text-slate-50">
//                 <td className="px-6 py-3 text-left text-xs font-medium text-gray-800 uppercase tracking-wider">{index + 1}</td>
//                 <td className="px-6 py-3 text-left text-xs font-medium text-gray-800 uppercase tracking-wider">{`${eachData.applicant[index].firstName} ${eachData.applicant[index].lastName}`}</td>
//                 <td className="px-6 py-3 text-left text-xs font-medium text-gray-800 uppercase tracking-wider">{eachData.job.jobTitle}</td>
//                 <td className="px-6 py-3 text-left text-xs font-medium text-gray-800 uppercase tracking-wider">{eachData.interviews[index].interviewDate}</td>
//                 <td className="px-6 py-3 text-left text-xs font-medium text-gray-800 uppercase tracking-wider">{eachData.interviews[index].interviewTime}</td>
//                 <td className="px-6 py-3 text-left text-xs font-medium text-gray-800 uppercase tracking-wider">{`${timeLeftInHours}, hrs`}</td>
//                 <td className={`px-6 py-3 text-left text-xs font-medium text-green-700 uppercase tracking-wider`}>{eachData.status}</td>
//                 <td className={`px-6 cursor-pointer py-3 text-left text-md font-xs uppercase tracking-wider`}>
//                   <button className='text-gray-100 hover:text-green-100 hover:bg-green-500 mr-3 bg-gray-400 px-2 py-1' onClick={(e) => { handleInterview(e) }}>Start</button>
//                   <button onClick={()=>{handleUpdate(eachData._id)}} className='text-gray-100 hover:text-green-100 hover:bg-green-500 bg-gray-400 px-2 py-1'>Interview Done</button>
//                 </td>
//               </tr>
//             );
//           })}
//         </tbody>
//       </table>
//     </div>
//   );
// }

// export default ScheduleTable;

// ------- version 2 ---------
import axios from 'axios';
import React, { useState } from 'react';
import { CgMore } from 'react-icons/cg';
import { useDispatch } from 'react-redux';
import { addNotification } from '../../redux/notification/notificationSlice';
import { toast } from 'react-toastify';

function ScheduleTable({ currentList, handleNavigation, data, title, handleInterview }) {
  const [viewMoreStates, setViewMoreStates] = useState(Array(data.length).fill(false));
  const updateApplication = `${process.env.REACT_APP_API_URL}application/updateApplication`;
  const dispatch = useDispatch();

  const handleView = (index) => {
    setViewMoreStates((prevStates) => {
      const newStates = [...prevStates];
      newStates[index] = !newStates[index];
      return newStates;
    });
  };

  const handleUpdate = async (applicationId) => {
    try {
      const updateCurrentApplication = await axios.put(updateApplication, {
        applicationId: applicationId,
        status: 'Interviewed',
      });
      dispatch(addNotification(updateCurrentApplication.data));
      toast.success('Successfully updated');
    } catch (error) {
      toast.error('Error, try again');
    }
  };

  return (
    <div className="mt-6 text-sm">
      <table className="w-full mx-auto border-collapse">
        <thead>
          <tr className="border-b-2 border-green-300">
            <th className="px-6 py-4 text-left text-xs font-semibold text-gray-600 uppercase tracking-wide">S/N</th>
            <th className="px-6 py-4 text-left text-xs font-semibold text-gray-600 uppercase tracking-wide">Name</th>
            <th className="px-6 py-4 text-left text-xs font-semibold text-gray-600 uppercase tracking-wide">Description</th>
            <th className="px-6 py-4 text-left text-xs font-semibold text-gray-600 uppercase tracking-wide">Date of Interview</th>
            <th className="px-6 py-4 text-left text-xs font-semibold text-gray-600 uppercase tracking-wide">Time of Interview</th>
            <th className="px-6 py-4 text-left text-xs font-semibold text-gray-600 uppercase tracking-wide">Time Remaining</th>
            <th className="px-6 py-4 text-left text-xs font-semibold text-gray-600 uppercase tracking-wide">Remark</th>
            <th className="px-6 py-4 text-left text-xs font-semibold text-gray-600 uppercase tracking-wide">Action</th>
          </tr>
        </thead>
        <tbody>
          {data.map((eachData, index) => {
            const timeRemaining = Date.now();
            const timeOfInterview = eachData.interviews[0]?.interviewTime || 'N/A'; // Default to first interview or fallback
            const dateOfInterview = eachData.interviews[0]?.interviewDate || 'N/A';
            const interviewDay = new Date(`${dateOfInterview}T${timeOfInterview}:00`);
            const timeLeftInMilliseconds = interviewDay - timeRemaining;
            const timeLeftInHours = timeLeftInMilliseconds > 0 ? (timeLeftInMilliseconds / (3600000 * 24)).toFixed(2) : 'Passed';

            return (
              <tr
                key={eachData._id || index} // Use unique ID if available
                className="border-b border-gray-200 hover:bg-green-50 transition-colors duration-200"
              >
                <td className="px-6 py-3 text-gray-800 text-xs font-medium uppercase">{index + 1}</td>
                <td className="px-6 py-3 text-gray-800 text-xs font-medium uppercase">
                  {`${eachData.applicant[0]?.firstName || ''} ${eachData.applicant[0]?.lastName || ''}`}
                </td>
                <td className="px-6 py-3 text-gray-800 text-xs font-medium uppercase">{eachData.job?.jobTitle || 'N/A'}</td>
                <td className="px-6 py-3 text-gray-800 text-xs font-medium uppercase">{dateOfInterview}</td>
                <td className="px-6 py-3 text-gray-800 text-xs font-medium uppercase">{timeOfInterview}</td>
                <td className="px-6 py-3 text-gray-800 text-xs font-medium uppercase">
                  {timeLeftInHours !== 'Passed' ? `${timeLeftInHours} hrs` : 'Passed'}
                </td>
                <td className="px-6 py-3 text-green-700 text-xs font-medium uppercase">{eachData.status || 'N/A'}</td>
                <td className="px-6 py-3 flex space-x-2">
                  <button
                    className="bg-gray-500 text-white px-3 py-1 rounded-md hover:bg-green-600 transition-colors duration-150 text-xs font-medium uppercase"
                    onClick={(e) => handleInterview(e)}
                  >
                    Start
                  </button>
                  <button
                    className="bg-gray-500 text-white px-3 py-1 rounded-md hover:bg-green-600 transition-colors duration-150 text-xs font-medium uppercase"
                    onClick={() => handleUpdate(eachData._id)}
                  >
                    Interview Done
                  </button>
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
}

export default ScheduleTable;