import { BiSearch } from "react-icons/bi";
import { BsSafe } from "react-icons/bs";
import { GrAlert, GrConnect, GrUserExpert } from "react-icons/gr";
import { TbGrowth } from "react-icons/tb";

export const aboutData = [
    {
        title:"Search Millions of Jobs",
        content:"We simplify the job search process by bringing together over 2M unique, live jobs and job applicants in one easy-to-use platform.",
        logo: BiSearch
    },
    {
        title:"Connect with Top Employers",
        content:"Get noticed by top companies with our streamlined application process, designed to match your skills with the right opportunities",
        logo: GrConnect 
    },
    {
        title:"Career Growth Support",
        content:"Take advantage of our resources and tools to enhance your career development and achieve your professional goals.",
        logo: TbGrowth
    },
    {
        title:"Personalized Job Alerts",
        content:"Stay ahead of the competition with personalized job alerts that bring the latest opportunities straight to your inbox.",
        logo: GrAlert
    },
    {
        title:"Expert Advice and Guidance",
        content:"Benefit from expert career advice and guidance to navigate the job market and secure your dream job.",
        logo: GrUserExpert
    },
    {
        title:"Safe and Secure",
        content:"We prioritize your security, ensuring a safe and confidential job search experience on our platform.",
        logo: BsSafe
    },
]