import { Label, TextInput } from "flowbite-react"
import { useState } from "react"

function JobChoice({label, placeholder, setUserChoice, name, register}) {

  const handleInterest = (e)=>{
    setUserChoice(e.target.value)
  }
  return (
    <div className="flex flex-col items-start justify-start">
        <Label htmlFor="choice">{label}</Label>
        <TextInput id="choice" name={name} {...register(`${name}`)} onChange={handleInterest} className="px-2 py-[2px]" type="text" placeholder={placeholder}/>
    </div>
  )
}

export default JobChoice