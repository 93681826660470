import { useState } from "react";
import { FaCamera, FaPlus } from "react-icons/fa";
import { GrUpdate } from "react-icons/gr";
import { Link } from "react-router-dom";
import { useMakeRequest } from "../../hooks/useMakeRequest";
import { toast } from "sonner"; 
import { EMPLOYER_URL } from "../../resources/resource";
import { uploadToCloudinary } from "../../Utils/cloudinaryUpload";
import DefaultProfile from '../../assets/Images/companyDefault.png'


const ProfileCard = ({ user, refetch }) => {
  const [profilePreview, setProfilePreview] = useState(null);
  const [loading, setLoading] = useState(false);
  const [file, setFile] = useState(null);
  const makeRequest = useMakeRequest();

  const onAvatarChange = (e) => {
    const selectedFile = e.target.files[0];
    if (!selectedFile) return;
    
    // Validate file type
    if (!selectedFile.type.startsWith('image/')) {
      toast.error('Please select an image file');
      return;
    }
    
    setFile(selectedFile);
    const imageUrl = URL.createObjectURL(selectedFile);
    setProfilePreview(imageUrl);
  };

  const handleUpdateAvatar = async () => {
    try {
      if (!file) {
        toast.error('Please select an image first');
        return;
      }

      setLoading(true);
      const uploadResult = await uploadToCloudinary(file);
      console.log('result -->',uploadResult)
      if (!uploadResult) {
        throw new Error('Failed to upload image');
      }

      const payload = {
        avatar: uploadResult
      };

      await makeRequest(
        `${EMPLOYER_URL}/updateJobEmployer/${user?._id}`,
        'PUT',
        payload,
        (data) => {
          console.log('Update successful:', data);
          refetch();
          // Clean up the object URL to prevent memory leaks
          if (profilePreview) {
            URL.revokeObjectURL(profilePreview);
          }
          setProfilePreview(null);
          setFile(null);
        },
        (error) => {
          console.error('Update failed:', error);
          toast.error('Failed to update profile picture');
        },
        () => {
          setLoading(false);
        },
        { dontNotifyOnSuccess: false }
      );
    } catch (error) {
      console.error('Avatar update error:', error);
      toast.error('Failed to update profile picture');
      setLoading(false);
    }
  };

  return (
    <div className="bg-white rounded-lg shadow-sm overflow-hidden">
      <div className="bg-blue-50 h-32 relative">
        <div className="absolute -bottom-12 left-8 flex items-end space-x-4">
          <div className="relative">
            <img
              src={profilePreview || user?.avatar || DefaultProfile}
              alt="Profile"
              className="w-24 h-24 rounded-xl object-cover border-4 border-white shadow-sm"
            />
            <label
              htmlFor="avatar-upload"
              className="absolute bottom-1 right-1 p-1.5 bg-white rounded-full shadow-md cursor-pointer hover:bg-gray-50 transition-all duration-200"
            >
              <FaCamera className="w-4 h-4 text-gray-600" />
              <input
                id="avatar-upload"
                type="file"
                className="hidden"
                accept="image/*"
                onChange={onAvatarChange}
              />
            </label>
          </div>
          <div className="mb-4">
            <h1 className="text-xl font-semibold text-gray-900">
              {user?.companyName}
            </h1>
            <p className="text-sm text-gray-500">{user?.email}</p>
          </div>
        </div>
        <div className="absolute top-4 right-4">
          <Link
            to={`/${user?._id}/postJob`}
            className="inline-flex items-center px-4 py-2 bg-blue-600 text-white text-sm font-medium rounded-lg hover:bg-blue-700 transition-colors duration-200"
          >
            <FaPlus className="w-4 h-4 mr-2" />
            Post New Job
          </Link>
        </div>
      </div>
      {profilePreview && (
        <button
          onClick={handleUpdateAvatar}
          disabled={loading}
          className="absolute flex items-center gap-2 right-10 bg-green-500 text-white px-4 py-[5px] rounded-sm disabled:bg-green-300"
        >
          Update
          <GrUpdate className={loading ? 'animate-spin' : ''} />
        </button>
      )}
      <div className="h-16" />
    </div>
  );
};

export default ProfileCard;