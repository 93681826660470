import { useParams, useNavigate } from "react-router-dom";
import Logo from "../assets/Images/companyName.svg";
import { useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { BiFile } from "react-icons/bi";
import { convertToBase64 } from "../Utils/fileConverter";
import { useFetchData } from "../hooks/useFtechData";
import { APPLICATION_API, JOB_API } from "../resources/resource";
import { useMakeRequest } from "../hooks/useMakeRequest";
import Header from "../component/Header";
import { getSimilarJobs } from "./single/data";

function ApplyJob() {
  const jobParams = useParams()?.jobId;
  const currentUser = useSelector((state) => state.user);
  const [formDataInfo, setFormDataInfo] = useState({});
  const [errorMessage, setErrorMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const [interviewData, setInterviewData] = useState([]);
  const [resumeFile, setResumeFile] = useState(null)
  const makeRequest = useMakeRequest();
  const navigate = useNavigate();

  const { data: jobDetails, loading: loadingJob } = useFetchData(
    JOB_API + `/${jobParams}`
  );

  useEffect(() => {
    if (jobDetails?._id && currentUser?.currentUser?._id) {
      setFormDataInfo({
        ...formDataInfo,
        job: jobDetails?._id,
        applicant: currentUser?.currentUser?._id,
        companyId: jobDetails?.employer?._id,
        companyAddress: `${jobDetails?.localGovernment} ${jobDetails?.state}`,
        position: jobDetails?.jobTitle,
        applicantName: `${currentUser?.currentUser?.firstName} ${currentUser?.currentUser?.lastName}`,
        availability: "Available to start immediately",
        ApplicantPhone: currentUser?.currentUser?.phoneNumber,
      });
    }
  }, [jobDetails, currentUser]);



  const handleSubmit = async (e) => {
    e.preventDefault();
    const updatedFormDataInfo = {
      ...formDataInfo,
      additionalQuestionResponse: interviewData,
    };
    setFormDataInfo(updatedFormDataInfo);
    setLoading(true);

    // console.log('data -->', updatedFormDataInfo)

    makeRequest(
      APPLICATION_API + "/createApplication",
      "POST",
      updatedFormDataInfo,
      (data) => {
        console.log(data);
      },
      (error) => {
        console.log(error);
        setErrorMessage("Failed to submit application. Please try again.");
      },
      () => {
        setLoading(false);
        navigate("/jobs");
      }
    );
  };

  const handleChange = async (e) => {
    e.preventDefault()
    if (e.target.type === "file") {
      const file = e.target.files[0];
      const resumeFile = await convertToBase64(file);
      setResumeFile(file)
      setFormDataInfo({ ...formDataInfo, [e.target.name]: resumeFile });
    } else {
      setFormDataInfo({ ...formDataInfo, [e.target.name]: e.target.value });
    }
  };

  const handleAdditionalQuestion = (e, index) => {
    const newInterviewData = [...interviewData];
    newInterviewData[index] = {
      question: jobDetails?.additionalInterviewQuestion[index],
      response: e.target.value,
    };
    setInterviewData(newInterviewData);
  };

  if (loadingJob) {
    return (
      <div className="h-screen flex items-center justify-center">
        <div className="animate-spin rounded-full h-12 w-12 border-4 border-blue-500 border-t-transparent"></div>
      </div>
    );
  }

  return (
    <div className="max-w-7xl mx-auto px-4 py-8 pt-16 sm:pt-20">
      <div className="grid grid-cols-1 lg:grid-cols-3 gap-8">
        {/* Main Form Section */}
        <Header/>
        <div className="lg:col-span-2 border-2 rounded-md">
          <div className="bg-white rounded-lg shadow-lg transition-shadow hover:shadow-xl">
            {/* Header */}
            <div className="p-6 border-b">
              <div className="flex items-start gap-4">
                <img
                  src={jobDetails?.employer?.logo || Logo}
                  alt="Company logo"
                  className="w-16 h-16 object-cover"
                />
                <div>
                  <h1 className="text-2xl font-semibold text-gray-900">
                    {jobDetails?.jobTitle}
                  </h1>
                  <p className="text-gray-600">
                    {jobDetails?.employerName} • {jobDetails?.state}
                  </p>
                </div>
              </div>

              <div className="flex gap-2 mt-4">
                <span className="inline-flex items-center px-3 py-1 rounded-full text-sm font-medium bg-gray-100 text-gray-800">
                  {jobDetails?.workType}
                </span>
                {jobDetails?.salary && (
                  <span className="inline-flex items-center px-3 py-1 rounded-full text-sm font-medium bg-gray-100 text-gray-800">
                    {jobDetails?.salary} {jobDetails?.currency?.split("-")[0]}
                  </span>
                )}
              </div>
            </div>

            {/* Form Content */}
            <form onSubmit={handleSubmit} className="p-6 space-y-6">
              {jobDetails?.jobDescription && (
                <div>
                  <h2 className="text-xl font-semibold text-gray-900 mb-4">
                    Role Requirements
                  </h2>
                  <ul className="space-y-2">
                    {jobDetails?.jobDescription.map((desc, index) => (
                      <li key={index} className="flex items-start">
                        <span className="h-1.5 w-1.5 rounded-full bg-blue-500 mt-2 mr-2"></span>
                        <span className="text-gray-700">{desc}</span>
                      </li>
                    ))}
                  </ul>
                </div>
              )}

              {jobDetails?.requireCoverLetter && (
                <div>
                  <label className="block text-sm font-medium text-gray-700 mb-2">
                    Why should we hire you?
                  </label>
                  <textarea
                    name="coverLetter"
                    onChange={handleChange}
                    className="w-full min-h-[200px] rounded-lg border border-gray-300 shadow-sm focus:ring-2 focus:ring-blue-500 focus:border-blue-500 p-3 transition-colors"
                    placeholder="Tell us why you're the perfect fit for this role..."
                  />
                </div>
              )}

              <div>
                <label className="block text-sm font-medium text-gray-700 mb-2">
                  Availability
                </label>
                <select
                  name="availability"
                  onChange={handleChange}
                  className="w-full rounded-lg border border-gray-300 shadow-sm focus:ring-2 focus:ring-blue-500 focus:border-blue-500 p-2.5 transition-colors"
                >
                  <option value="immediate">Available to start immediately</option>
                  <option value="1week">Available to start within 1 week</option>
                  <option value="2weeks">Available to start within 2 weeks</option>
                  <option value="1month">Available to start within 1 month</option>
                </select>
              </div>

              <div>
                <label className="block text-sm font-medium text-gray-700 mb-2">
                  Available from
                </label>
                <input
                  type="date"
                  name="availabilityDate"
                  onChange={handleChange}
                  className="w-full rounded-lg border border-gray-300 shadow-sm focus:ring-2 focus:ring-blue-500 focus:border-blue-500 p-2.5 transition-colors"
                />
              </div>

              {jobDetails?.additionalInterviewQuestion && (
                <div className="space-y-4">
                  <h2 className="text-xl font-semibold text-gray-900">
                    Additional Information
                  </h2>
                  {jobDetails.additionalInterviewQuestion.map((question, index) => (
                    <div key={index}>
                      <label className="block text-sm font-medium text-gray-700 mb-2">
                        {question}
                      </label>
                      <input
                        type="text"
                        onChange={(e) => handleAdditionalQuestion(e, index)}
                        className="w-full rounded-lg border border-gray-300 shadow-sm focus:ring-2 focus:ring-blue-500 focus:border-blue-500 p-2.5 transition-colors"
                        placeholder="Your answer..."
                      />
                    </div>
                  ))}
                </div>
              )}

              {/* Resume Upload */}
              <div className="mt-6">
                <label className="block text-sm font-medium text-gray-700 mb-2">
                  Resume
                </label>
                <div className="mt-1 flex justify-center px-6 pt-5 pb-6 border-2 border-gray-300 border-dashed rounded-lg hover:border-blue-500 transition-colors">
                  <div className="space-y-1 text-center">
                    <BiFile className="mx-auto h-12 w-12 text-gray-400" />
                    <div className="flex text-sm text-gray-600">
                      {resumeFile ?
                        <p>{resumeFile?.name || 'Success'}</p>
                      : <label
                        htmlFor="resume-upload"
                        className="relative cursor-pointer rounded-md font-medium text-blue-600 hover:text-blue-500 focus-within:outline-none focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-blue-500"
                      >
                        <span>Upload a file</span>
                        <input
                          id="resume-upload"
                          name="resume"
                          type="file"
                          className="sr-only"
                          onChange={handleChange}
                          accept=".pdf,.doc,.docx"
                        />
                      </label>}
                      {resumeFile ? null :<p className="pl-1">or drag and drop</p>}
                    </div>
                    <p className="text-xs text-gray-500">
                      PDF, DOC, DOCX up to 10MB
                    </p>
                  </div>
                </div>
              </div>

              {errorMessage && (
                <div className="p-4 bg-red-50 border border-red-200 rounded-lg">
                  <p className="text-sm text-red-600">{errorMessage}</p>
                </div>
              )}

              <div className="flex justify-end gap-4 pt-6">
                <button
                  type="button"
                  onClick={() => navigate("/jobs")}
                  className="px-4 py-2 text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded-lg hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 transition-colors"
                >
                  Cancel
                </button>
                <button
                  type="submit"
                  disabled={loading}
                  className="px-4 py-2 text-sm font-medium text-white bg-green-600 rounded-lg hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500 transition-colors disabled:opacity-50 disabled:cursor-not-allowed"
                >
                  {loading ? (
                    <span className="flex items-center">
                      <svg className="animate-spin -ml-1 mr-2 h-4 w-4 text-white" fill="none" viewBox="0 0 24 24">
                        <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                        <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                      </svg>
                      Submitting...
                    </span>
                  ) : (
                    "Submit Application"
                  )}
                </button>
              </div>
            </form>
          </div>
        </div>

        {/* Similar Jobs Section */}
        <div className="lg:col-span-1 sm:block hidden">
          <div className="bg-white rounded-lg shadow-lg p-6">
            <h2 className="text-xl font-semibold text-gray-900 mb-4">
              Similar Jobs
            </h2>
            <div>
              {/* {getSimilarJobs()} */}
              <p>No job matches this criteria</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ApplyJob;