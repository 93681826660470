// import React from 'react'

// function CvGuarantor({guarantorName, guarantorCompany, phoneNumber}) {
//   return (
//     <div className='mb-2'>
//         <h3 className='font-semibold'>{guarantorName} - {guarantorCompany}</h3>
//         <span className='font-medium'>{phoneNumber}</span>
//     </div>
//   )
// }

// export default CvGuarantor

// --------- version 2 ---------
import React from 'react';
import { FaUserShield } from 'react-icons/fa';

function CvGuarantor({guarantorName, guarantorCompany, phoneNumber}) {
  return (
    <div className='p-4 rounded-lg bg-gray-50 border border-gray-100'>
      <div className="flex items-center gap-2 mb-2">
        <FaUserShield className="text-gray-500" />
        <h3 className='font-semibold text-gray-800'>{guarantorName}</h3>
      </div>
      {guarantorCompany && (
        <div className='text-gray-600 text-sm pl-6 mb-1'>
          {guarantorCompany}
        </div>
      )}
      <div className='text-gray-600 text-sm pl-6 flex items-center gap-2'>
        <span className="font-medium">Contact:</span>
        {phoneNumber}
      </div>
    </div>
  );
}

export default CvGuarantor;