import * as Yup from 'yup'

export const signinSchema = Yup.object().shape({
    userType: Yup.string().required('UserType is required'),
    email: Yup.string().email('Invalid email').required('Email is required'),
    password: Yup.string()
        .required('kindly enter your password')
})


export const stepOneSchema = Yup.object().shape({
    email: Yup.string().email().required('Email is required'),
    password: Yup.string()
    .required('Kindly enter a password')
    .min(6, 'Must be at least 6 characters')
    .matches(/[!@#$%^&*(),.?":{}|<>]/, 'Must contain at least one special character')
    .matches(/\d/, 'Must contain at least one number'),
    confirmPassword: Yup.string()
    .required('Kindly enter a password')
    .oneOf([Yup.ref('password'), null],'Kindly provide the same password')
})

export const stepTwoSchema = Yup.object().shape({
    userType: Yup.string().required('UserType is required'),
})

export const stepThreeSchema = Yup.object().shape({
    firstName: Yup.string().required('First Name is required'),
    lastName: Yup.string().required('Last Name is required'), // Correct error message
    phoneNumber: Yup.string().required('Phone number is required'), // Treat as string
    address: Yup.string().required('Address is required'),
    state: Yup.string().required('State is required'),
    country: Yup.string().required('Country is required'),
    localGovernment: Yup.string().required('Local Government is required'),
    gender: Yup.string().required('Gender is required'), // Correct case
});



export const stepFourSchema = Yup.object().shape({
    firstChoice: Yup.string().required('First choice is required'),
    secondChoice: Yup.string().required('Second choice is required'),
    thirdChoice: Yup.string().required('Third choice is required'),
    interestedJob: Yup.array()
    .of(Yup.string()) 
    .min(1, 'At least one item must be selected')
    .max(5, 'Maximum 5 items can be selected')
    .required('Please select at least one item')
})


export const employeeType = Yup.object().shape({
    employerType: Yup.string().required('employerType is required'),
})


export const individualEmployeeSchema = Yup.object().shape({
    companyName: Yup.string().required('Company is required'),
    address: Yup.string().required('Address is required'),
    state: Yup.string().required('State is required'),
    localGovernment: Yup.string().required('LGA is required'),
    country: Yup.string().required('Country is required'),
    size: Yup.string().required('Size is required'),
})


export const organizationEmployeeSchema = Yup.object().shape({
    companyName: Yup.string().required('Company is required'),
    address: Yup.string().required('Address is required'),
    state: Yup.string().required('State is required'),
    localGovernment: Yup.string().required('LGA is required'),
    country: Yup.string().required('Country is required'),
    phoneNumber: Yup.string().required('Phone number is required'),
    size: Yup.string().required('Size is required'),
})

// create job schema

export const jobPostSchema = Yup.object().shape({
  jobTitle: Yup
    .string()
    .required('Job title is required')
    .min(3, 'Job title must be at least 3 characters')
    .max(100, 'Job title must not exceed 100 characters'),
  
  jobFunction: Yup
    .string()
    .required('Job function is required')
    .notOneOf(['Select'], 'Please select a job function'),
  
  industry: Yup
    .string()
    .required('Industry is required')
    .notOneOf(['Select'], 'Please select an industry'),
  
  workType: Yup
    .string()
    .required('Work type is required')
    .notOneOf(['Select'], 'Please select a work type'),

  // Experience and salary
  experienceLength: Yup
    .string()
    .required('Experience length is required')
    .notOneOf(['Select'], 'Please select experience length'),
  
  currency: Yup
    .string()
    .required('Currency is required')
    .notOneOf(['Select'], 'Please select a currency'),
  
  salary: Yup
    .string()
    .required('Salary range is required')
    .matches(/^[\d\s-]+$/, 'Salary must contain only numbers, spaces, and hyphens'),

  // Qualifications and location
  minimumQualification: Yup
    .string()
    .required('Minimum qualification is required')
    .notOneOf(['Select'], 'Please select minimum qualification'),
  
  state: Yup
    .string()
    .required('State is required')
    .notOneOf(['Select'], 'Please select a state'),
  
  localGovernment: Yup
    .string()
    .required('Local government is required')
    .notOneOf(['Select'], 'Please select a local government'),

  // Job details
  jobDescription: Yup
  .array()
  .of(
    Yup.string()
      .min(10, "Each description must be at least 10 characters")
      .required()
  )
  .min(1, "At least one job description is required")
  .max(10, "Maximum of 10 descriptions allowed")
  .required("Job description is required"),

  jobSummary: Yup
    .string()
    .required('Job summary is required')
    .min(50, 'Job summary must be at least 50 characters')
    .max(500, 'Job summary must not exceed 500 characters'),

  // Duration and openings
  numberOfOpenings: Yup
    .number()
    .typeError('Number of openings must be a number')
    .required('Number of openings is required')
    .positive('Number of openings must be positive')
    .integer('Number of openings must be a whole number'),

  vacancyDuration: Yup
    .date()
    .required('Vacancy duration is required')
    .min(new Date(), 'Vacancy duration must be a future date'),

  // Skills and tags
  jobSkills: Yup
    .array()
    .of(Yup.string())
    .min(1, 'At least one skill is required')
    .required('Skills are required'),

  jobTag: Yup
    .array()
    .of(Yup.string())
    .min(1, 'At least one tag is required')
    .required('Tags are required'),

  // Interview questions
  additionalInterviewQuestion: Yup
    .array()
    .of(Yup.string())
    .min(1, 'At least one interview question is required')
    .required('Interview questions are required'),

  // Requirements
  requireCoverLetter: Yup
    .boolean()
    .required('Please specify if cover letter is required'),

  requiredocumentUpload: Yup
    .boolean()
    .required('Please specify if additional documents are required'),

  // Payment and employer info
  jobType: Yup
    .string()
    .required('Job type is required'),

  employer: Yup
    .string()
    .required('Employer ID is required'),

  employerName: Yup
    .string()
    .required('Employer name is required'),

  paymentReference: Yup
    .mixed()
    .when('jobType', {
      is: (value) => value !== 'Free Plan',
      then: Yup.mixed().required('Payment reference is required for paid plans')
    })
});

// Custom error messages for form validation
export const validationMessages = {
  required: 'This field is required',
  select: 'Please select an option',
  invalidEmail: 'Please enter a valid email address',
  minLength: (field, length) => `${field} must be at least ${length} characters`,
  maxLength: (field, length) => `${field} must not exceed ${length} characters`,
  future: 'Date must be in the future',
  positive: 'Value must be positive',
  number: 'Please enter a valid number',
  array: 'Please add at least one item'
};