export const conversation = [
    {
        from: '668dab20e222e7c501d27bab',
        message:'Hello',
    },
    {
        from: 2,
        message:'Hello',
    },
    {
        from: '668dab20e222e7c501d27bab',
        message:'Are you ready for the interview',
    },
    {
        from: 2,
        message:'can we reschedule for the next 30mins',
    },
    {
        from: '668dab20e222e7c501d27bab',
        message:'Are you ready for the interview',
    },
    {
        from: 2,
        message:'can we reschedule for the next 30mins',
    },
    {
        from: '668dab20e222e7c501d27bab',
        message:'Are you ready for the interview',
    },
    {
        from: 2,
        message:'can we reschedule for the next 30mins',
    },
    {
        from: '668dab20e222e7c501d27bab',
        message:'Are you ready for the interview',
    },
    {
        from: 2,
        message:'can we reschedule for the next 30mins',
    }
]