import React, { useState } from "react";
import { GrLocationPin } from "react-icons/gr";

function BlacklistComponent({ companyName, companyAddress, complaint }) {
  const [showComplaint, setShowComplaint] = useState(false);

  const handleShowComplaint = () => {
    setShowComplaint(!showComplaint);
  };

  return (
    <div className={`p-4 mb-4 transition-all rounded-lg ${showComplaint ? 'bg-green-100' : 'bg-white border border-gray-200'}`}>
      <div onClick={handleShowComplaint} className="cursor-pointer">
        <h3 className="text-lg font-semibold text-gray-800">{companyName}</h3>
        <div className="flex items-center gap-2 mt-2">
          <GrLocationPin className="text-green-500" />
          <p className="text-sm text-gray-600">{companyAddress}</p>
        </div>
      </div>
      {showComplaint && (
        <div className="mt-4 p-4 bg-white rounded-lg shadow-md">
          <ul className="text-gray-700">
            {complaint.map((item, index) => (
              <li key={index} className="list-none my-2">{item}</li>
            ))}
          </ul>
        </div>
      )}
    </div>
  );
}

export default BlacklistComponent;